import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import CustomButton from './Button';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import FilledButton from './FilledButton';
import { ReactComponent as PlusIcon } from "../../Icons/PlusIcon.svg";

export default function FadeMenu(props) {
  const { t } = useTranslation();

  const { onInviteUserClick, onAddUserClick } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event.currentTarget)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewUserClick = () => {
    handleClose();
    onAddUserClick();
  };
  const handleInviteUserClick = () => {
    handleClose();
    onInviteUserClick();
  };

  return (
    <div>
      <FilledButton isValid={true} onClick={handleClick}>
        <PlusIcon/>{t('users.addUser')}
      </FilledButton>
      <Menu
        style={{ width: '100%' }}
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleNewUserClick}>
          <AddIcon /> &nbsp; {t('users.newUser')}
        </MenuItem>
        <MenuItem onClick={handleInviteUserClick}>
          <MailOutlineIcon /> &nbsp; {t('users.inviteUser')}
        </MenuItem>
      </Menu>
    </div>
  );
}
