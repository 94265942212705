import React, { useState } from "react";
import {
  TextField,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import "./RegisterForm.css";
import {
  useRegisterMutation,
  useAddUserMutation,
} from "../../features/auth/authApiSlice";
import { useSnackbar } from "../SneckBarProvider";
import CustomButton from "../Button/Button";


const RegisterForm = (props) => {
  const { onAddUser } = props;

  const userRole = localStorage.getItem("roles");

  const roles = ["Admin", "Operater", "Driver", "Passenger"];
  const [registerUser] = useRegisterMutation();
  const [addUser] = useAddUserMutation();

 
  const showSnackbar = useSnackbar();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    phoneNumber: "",
    role: roles[0],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData);
  };

  const handleRegister = async () => {
    try {
      const response = await registerUser(formData).unwrap();
      showSnackbar(response?.message, "success");
      onAddUser();
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };
  const handleAddUser = async () => {
    try {
      const response = await addUser(formData).unwrap();
      showSnackbar(response?.message, "success");
      onAddUser();
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };

  const onRegister = async () => {
    if (userRole === "Admin") {
      await handleAddUser();
    } else {
      await handleRegister();
    }
  };

  return (
    <div className="register-form-main-container">
      <div className="register-form-data-container">
        <TextField
          className="register-form-data-field"
          variant="standard"
          color="secondary"
          required
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />

        <TextField
          className="register-form-data-field"
          variant="standard"
          color="secondary"
          required
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />

        <TextField
          className="register-form-data-field"
          variant="standard"
          color="secondary"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          value={formData.username}
          onChange={handleChange}
        />
        <TextField
          className="register-form-data-field"
          variant="standard"
          color="secondary"
          required
          fullWidth
          name="email"
          label="Email"
          type="email"
          id="email"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          className="register-form-data-field"
          variant="standard"
          color="secondary"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          value={formData.password}
          onChange={handleChange}
        />

        <TextField
          className="register-form-data-field"
          variant="standard"
          color="secondary"
          required
          fullWidth
          name="phoneNumber"
          label="Phone Number"
          id="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />

        <FormControl className="register-form-data-field" variant="standard">
          <InputLabel color="secondary" id="roleLabel">Roles</InputLabel>
          <Select
            color="secondary"
            name="role"
            labelId="roleLabel"
            id="role"
            label="Role"
            value={formData.role}
            onChange={handleChange}
          >
            {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="register-form-button">
        <CustomButton
          onClick={onRegister}
          type="submit"
        >
          Sign Up
        </CustomButton>
      </div>
    </div>
  );
};

export default RegisterForm;
