import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import {
//   Checkbox,
//   Grid,
//   TextField,
//   FormControlLabel,
//   Paper,
//   Button,
//   IconButton,
//   InputAdornment,
//   Box
// } from "@mui/material"

import {
  Container,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  IconButton,
  InputAdornment,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useLoginMutation } from '../../features/auth/authApiSlice';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../features/auth/authSlice';
import './LoginForm.css';
import CustomButton from '../Button/Button';
import LoginIcon from '@mui/icons-material/Login';
import { textFieldStyles } from '../../customStyles/customStyles';
import { useSnackbar } from '../SneckBarProvider';
import PasswordTextField from '../CustomTextFields/PasswordTextField';
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
  const showSnackbar = useSnackbar();
  const [checked, setChecked] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState({ message: '', isError: false });
  const [passwordError, setPasswordError] = useState({
    message: '',
    isError: false,
  });
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { t, i18n } = useTranslation();
  const emailRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();
  const [languageState, setLanguageState] = React.useState('srpski');

  const [login, { isLoading }] = useLoginMutation();

  const dispatch = useDispatch();

  const handleChange = async (event) => {
    setChecked(event.target.checked);
  };

  const changeEmail = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const value = e.target.value;

    setEmail(e.target.value);
    if (value.trim() == '')
      setEmailError({ message:t('login.emailEmpty'), isError: true });
    else setEmailError({ message: '', isError: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  const changePassword = (e) => {
    console.log(e.target.value);
    setPassword(e.target.value);
    const value = e.target.value;
    if (value.trim() == '')
      setPasswordError({ message: 'login.emailEmpty', isError: true });
    else setPasswordError({ message: '', isError: false });
  };
  useEffect(() => {
    // emailRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (passwordError.isError) return;
      const userData = await login({ email, password }).unwrap();
      const { id, roles, token, refreshToken, expires } = userData;

      dispatch(setCredentials({ id, roles, token }));

      setEmail('');
      setPassword('');

      navigate('CreateBooking');
    } catch (error) {
      showSnackbar(error?.data?.Message, 'error');
    }
  };

  useEffect(() => {
    document.body.classList.add('login-page');
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  const hangleChangeLanguage = () => {
    const currentLanguage = i18n.language;
    console.log(currentLanguage);

    if (languageState === 'srpski') {
      setLanguageState('english');
    } else {
      setLanguageState('srpski');
    }

    const newLanguage = i18n.language === 'en' ? 'rs' : 'en';
    i18n.changeLanguage(newLanguage);
  };


  const content = isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <div className="container">
      <div className="left">
        <div className="overlay">
          <img src="/assets/stars.png" alt="stars" />
        </div>
        <div className="content-container">
          <div className="content-title">
            <p className="big-title">
             {t('login.passport')}
            </p>
            <p className="small-title">  {t('login.smallTitle')}</p>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="header-language"> <span  onClick={hangleChangeLanguage}>
          {i18n.language === 'rs' ? 'SRB' : 'ENG'}
        </span>
        </div>
        <div className="login-form">
          <img
            src="/assets/terra-logo.jpeg"
            alt="logo"
            width="40"
            height="40"
          />
          <h2>{t('login.loginText')}</h2>
          <form className="form-style" onKeyDown={handleKeyDown}>
            <p>  {t('login.details')}</p>

            <label className="text-label" htmlFor="username">
              {t("login.email")}:
            </label>
            <input
              className={`input-text ${emailError.isError ? '' : ''}`}
              autoComplete="false"
              onChange={(e) => changeEmail(e)}
              value={email}
              placeholder={t('login.enterEmail')}
              type="text"
              id="username"
              name="username"
              required
            />
            {emailError.isError && (
              <div className="helper-text">{t('login.emailEmpty')}</div>
            )}

            <label className="text-label" htmlFor="password">
            {t('login.password')}:
            </label>
            <div className="input-wrapper">
              <input
                className="input-pass"
                onChange={(e) => changePassword(e)}
                value={password}
                placeholder=  {t('login.enterPassword')}
                name="password"
                type={showPassword ? 'text' : 'password'}
              />
              <i className="password-toggle" onClick={handleMouseDownPassword}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </i>
            </div>
            {passwordError.isError && (
              <div className="helper-text">{t('login.passwordEmpty')}</div>
            )}

            <div className="form-row">
              {/* <div className="remember-me">
                  <input type="checkbox" id="remember" name="remember" />
                  <label className="text-label" htmlFor="remember">Remember for 30 days</label>
                </div>
                <p className="forgot-password">
                  <a href="#">Forgot password</a>
                </p> */}
            </div>
            <button className="log-button" onClick={handleSubmit} type="submit">
            {t('login.signIn')}
            </button>
          </form>
        </div>
        <footer className="footer-text">
          <p>www.terratravel.rs</p>
        </footer>
      </div>
    </div>

    // <Container maxWidth="xl" disableGutters sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
    //   <Grid container spacing={0}>
    //     {/* Left side */}
    //     <Grid item xs={12} sm={8}>
    //       <Box
    //         sx={{
    //           height: '100vh',
    //           position: 'relative',
    //           backgroundImage: 'url(/assets/login-picture.png)',
    //           backgroundRepeat: 'no-repeat',
    //           backgroundSize: 'cover',
    //           backgroundAttachment: 'fixed',
    //           backgroundPosition: '-1450px 0',
    //         }}
    //       >
    //         <Box
    //           sx={{
    //             position: 'absolute',
    //             top: 0,
    //             left: 0,
    //             width: '100%',
    //             height: '100%',
    //             background: 'linear-gradient(rgba(133, 24, 30, 0.8), rgba(58, 22, 24, 1))',
    //             opacity: 0.8,
    //             borderRadius: '0 80px 80px 0',
    //           }}
    //         />
    //         <Box
    //           sx={{
    //             position: 'absolute',
    //             bottom: '70%',
    //             left: '80%',
    //             transform: 'translateX(-50%)',
    //           }}
    //         >
    //           <img src="/assets/stars.png" alt="stars" />
    //         </Box>
    //         <Box
    //           sx={{
    //             color: 'white',
    //             position: 'relative',
    //             zIndex: 1,
    //             top: '653px',
    //             paddingLeft: '120px',
    //             paddingRight: '120px',
    //             height: '234px',
    //             maxHeight: '234px',
    //           }}
    //         >
    //           <Typography variant="h1" sx={{ fontSize: '56px', fontWeight: 500, lineHeight: '0.9' }}>
    //             Za najlepšu priču između stranica tvog pasoša.
    //           </Typography>
    //           <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 500, lineHeight: '30px' }}>
    //             Brzo, sigurno, pouzdano.
    //           </Typography>
    //         </Box>
    //       </Box>
    //     </Grid>

    //     {/* Right side */}
    //     <Grid item xs={12} sm={4}>
    //       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    //         <Box sx={{ width: '100%', maxWidth: '400px', bgcolor: 'background.paper', p: 3, borderRadius: '5px' }}>
    //           <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
    //             <img src="/assets/terra-logo.jpeg" alt="logo" width="40" height="40" style={{ borderRadius: '50%' }} />
    //             <Typography variant="h2" component="h2" sx={{ mt: 2, mb: 2 }}>
    //               Login
    //             </Typography>
    //           </Box>

    //           <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
    //             <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
    //               Welcome back! Please enter your details.
    //             </Typography>

    //             <TextField
    //               id="username"
    //               name="username"
    //               label="Email"
    //               type="text"
    //               value={email}
    //               onChange={changeEmail}
    //               placeholder="Enter your email"
    //               fullWidth
    //               required
    //               sx={{ mb: 2 }}
    //             />

    //             <TextField
    //               id="password"
    //               name="password"
    //               label="Password"
    //               type="password"
    //               value={password}
    //               onChange={changePassword}
    //               fullWidth
    //               required
    //               sx={{ mb: 2 }}
    //             />

    //             <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
    //               <FormControlLabel
    //                 control={<Checkbox id="remember" name="remember" />}
    //                 label="Remember for 30 days"
    //               />
    //               <Link href="#" variant="body2" sx={{ fontWeight: 600, color: 'rgba(133, 24, 30, 1)' }}>
    //                 Forget password
    //               </Link>
    //             </Box>

    //             <Button type="submit" variant="contained" sx={{ borderRadius: '3px', backgroundColor: '#0b382a', color: 'white', '&:hover': { backgroundColor: '#45a049' } }}>
    //               Sign in
    //             </Button>
    //           </form>

    //           <Box sx={{ textAlign: 'center', mt: 2 }}>
    //             <Typography variant="body2">www.terratravel.rs</Typography>
    //           </Box>
    //         </Box>
    //       </Box>
    //     </Grid>
    //   </Grid>
    // </Container>
  );
  return content;
};

export default LoginForm;
