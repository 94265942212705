import React, { useState } from 'react'
import { useApproveReservationMutation, useGetUnapprovedReservationsQuery } from '../../../../features/reservation/reservationSlice'
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSnackbar } from '../../../SneckBarProvider';

const UnapprovedReservations = () => {

  const {data: unapprovedReservations, refetch} = useGetUnapprovedReservationsQuery();
  const [approveData]  = useApproveReservationMutation();
  const snackbarShow = useSnackbar();
 
const handleApprove = async (id) => {
    await approveData(id).unwrap();
    refetch();
    snackbarShow('Uspesno ste odobrili Edinin rad', 'success')
}
  return (
    <div style={{padding:'40px'}}>
    <TableContainer component={Paper} sx={{padding:'10px'}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Polazak</TableCell>
                    <TableCell>Država (Grad) polaska</TableCell>
                    <TableCell>Dolazak</TableCell>
                    <TableCell>Država (Grad) dolaska</TableCell>
                    <TableCell>Povratna vožnja</TableCell>
                    <TableCell>Večernji polazak</TableCell>
                    <TableCell>Prihvatiti</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {unapprovedReservations?.map((reservation) => (
                    <TableRow key={reservation.id}>
                        <TableCell>{reservation.booking.arrival}</TableCell>
                        <TableCell>{`${reservation.startStop.location.country} (${reservation.startStop.location.city})`}</TableCell>
                        <TableCell>{reservation.booking.departure}</TableCell>
                        <TableCell>{`${reservation.endStop.location.country} (${reservation.endStop.location.city})`}</TableCell>
                        <TableCell>{reservation.booking?.isReturnTicket ? 'Yes' : 'No'}</TableCell>
                        <TableCell>{reservation.booking?.isEveningDeparture ? 'Yes' : 'No'}</TableCell>
                        <TableCell>
                                <IconButton onClick={() => handleApprove(reservation.id)} color="primary">
                                    <CheckCircleOutlineIcon />
                                </IconButton>
                            </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    </div>
  )
}

export default UnapprovedReservations
