import { apiSlice } from "../../app/api/apiSlice";

export const passangerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPassangers : builder.query({
      query: ({id: id}
      ) => ({
        url: `Reservation/GetWithPassengers/${id}`,
        method: 'GET'
    })
    }),
    deletePassenger : builder.mutation({
      query: (id) => ({
        url: `Passenger/${id}`,
        method: 'DELETE'
      })
    }),
    getPassengersByNameAnNumber : builder.query({
      query: (name) => ({
        url: `Passenger/GetPassengersByNameAnNumber?name=${name}`,
        method: 'GET'
        
      })
    }),
  }),
});

export const {
    useGetPassangersQuery,
    useDeletePassengerMutation,
    useLazyGetPassengersByNameAnNumberQuery
} = passangerApiSlice;