import React from 'react'
import '../../components/Booking/AddBooking/CreateBookingComponent.css'
export default function SwitchIcon() {
  return (
  <div className='swap-direction'>
      <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6667 13.1667H1.33337M1.33337 13.1667L4.66671 9.83333M1.33337 13.1667L4.66671 16.5M1.33337 4.83333H14.6667M14.6667 4.83333L11.3334 1.5M14.6667 4.83333L11.3334 8.16667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
  </div>
  )
}
