import React from 'react';
import './ThankYouContent.css';

const ThankYouContent = () => {
 

  return <div className="thankYouContentMainContainer">
    <div className='pngContainer'>

    </div>
    <div className='thankYou-text-container'>
      <span >
      Hvala što ste potvrdili vožnju.
      </span>
      <span>
      Vaša rezervacija je sada završena.
      </span>
    </div>
  </div>;
};

export default ThankYouContent;
