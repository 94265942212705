// import { DirectionsService } from "google-maps";

export const getDirections = async (origin, destination, waypoints) => {
  try {
    const directionsService = new window.google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: { lat: origin.lat, lng: origin.long },
      destination: { lat: destination.lat, lng: destination.long },
      waypoints: waypoints.map(
        (waypoint) => (
          {

            location: new window.google.maps.LatLng(
              waypoint.routeStop.location.lat,
              waypoint.routeStop.location.long
            ),
          }
        )
      ),
      travelMode: window.google.maps.TravelMode.DRIVING,
    });
    return results;
  } catch (error) {

    console.error("Error fetching directions:", error);
    throw error;
  }
};

