import React, { useEffect } from "react";
import RegistrationInvitation from "../components/User/AddUserComponent/RegistrationInvitation";
import { useSearchParams } from "react-router-dom";
import { useGetInvitationTokenQuery } from "../features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../components/SneckBarProvider";

const RegisterInvitationPage = () => {
  localStorage.clear();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("invitation");
  const { data, error, isLoading } = useGetInvitationTokenQuery(token);
  //const showSnackbar = useSnackbar();
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && error) {
      showSnackbar(error.data.Message, "error");

      console.log(error.data.Message);
      if (
        error.data.Message ==
        "This token is already used!"
      ) {
        navigate("/");
      }
      if (
        error.data.Message ==
        "Wrong token!" || 
        error.data.Message == "The token has expired!"
      ) {
        navigate("/Error404");
      }
    }
  }, [isLoading]);
  console.log(isLoading);

  console.log(token);

  return <>{data && <RegistrationInvitation token={data} />}</>;
};

export default RegisterInvitationPage;
