import { apiSlice } from "../../app/api/apiSlice"
import { disableBooking, getAllBookings, getBookingRequests } from "../../services/BookingService"

const creatorId = localStorage.getItem('id');
const userRole = localStorage.getItem('roles')
export const bookingsApiSlice = apiSlice.injectEndpoints({


    endpoints: builder => ({
        getBookings: builder.query({            
            query:(filters)=>{
                const { dateFrom, dateTo, code, startCityName, endCityName, startCountryName, endCountryName,creatorID, creatorName, pageSize, pageNumber, bookingType,status } = filters;
                const queryParams = new URLSearchParams();

                // Add parameters only if they are present in the filters object
                if (dateFrom) queryParams.append('dateFrom', dateFrom);
                if (dateTo) queryParams.append('dateTo', dateTo);
                if (code) queryParams.append('code', code);
                if (startCityName) queryParams.append('startCityName', startCityName);
                if (endCityName) queryParams.append('endCityName', endCityName);
                if (startCountryName) queryParams.append('startCountryName', startCountryName);
                if (endCountryName) queryParams.append('endCountryName', endCountryName);
                if(userRole == 'Dispatcher') {
                    queryParams.append('creatorID', creatorId);
                }
                else {
                if (creatorID) queryParams.append('creatorID', creatorID);
                }
                
                if (creatorName) queryParams.append('creatorName', creatorName);
                
                if (pageSize) queryParams.append('pageSize', pageSize);
                else queryParams.append('pageSize', 10); // Default pageSize
                if (pageNumber) queryParams.append('pageNumber', pageNumber);
                else queryParams.append('pageNumber', 1); // Default pageNumber
                if (bookingType) queryParams.append('bookingType', bookingType);
                else queryParams.append('bookingType', "all"); // Default bookingType
                if (status !=undefined ) queryParams.append('status',status.toString());
                else queryParams.append('status',true); // Default status

                return {
                    url: `Booking/GetBookingsPerPage?${queryParams.toString()}`,
                    method: 'GET',
                };
                
            }
          
        }),
        getAvailableBookings: builder.query({
            query: () => ({
                url:'Booking/GetAllBookings',
                method:'GET',
            })
        }),
        getBookingRequests: builder.query({
            query: () => 'Booking/GetAllBookingRequests',
        }),
        getBookingById: builder.query({
            query: (id) => "Booking/GetBookingById?id=" + id,
        }),
        getRoutesListForDropDown: builder.query({
            query: () => "Route/GetAllRoutes"
        }),
        getRouteDetails: builder.query({
            query: (routeId) => "Route/GetRouteById?routeID=" + routeId
        }),
        getPassengersForBooking:builder.query({
            query:(bookingId)=>({
                url:'Reservation/GetAllBookingUsers?bookingId='+bookingId,
                method:'GET'
            })
        }),
        createBooking: builder.mutation({
            query: (body) => ({
                url: 'Booking/AddBooking',
                method: 'POST',
                body
            }),
        }),
        enableBooking: builder.mutation({
            query: (bookingId) => ({
                url: `Booking/EnableBooking/${bookingId}`,
                method: 'PATCH',
            }),
        }),
        disableBooking: builder.mutation({
            query: (bookingId) => ({
                url: `Booking/DisableBooking/${bookingId}`,
                method: 'PATCH'
            })
        }),
        editBooking: builder.mutation({
            query: (body) => ({
                url: `Booking/UpdateBooking`,
                method: 'PUT',
                body
            })
        }),
        deleteBooking: builder.mutation({
            query: (bookingId) => ({
                url: `Booking/DeleteBooking?id=${bookingId}`,
                method: 'DELETE'
            })
        })
    })
})

export const {
    useGetBookingsQuery,useGetAvailableBookingsQuery, useGetBookingRequestsQuery, useGetBookingByIdQuery, useLazyGetRoutesListForDropDownQuery,useGetRoutesListForDropDownQuery, useGetRouteDetailsQuery,
    useGetPassengersForBookingQuery, useCreateBookingMutation, useEnableBookingMutation, useDisableBookingMutation, useEditBookingMutation, useDeleteBookingMutation,
} = bookingsApiSlice 