
export const translateUnammedCity = (city,t) => {
    if(city=="Undefined City")
        {
            return t('Location.undefinedCity')
        }
        else{
            return city
        }

};

export const translateUnammedStreet = (street,t) => {
    // const {t}=useTranslation();
    // console.log(street)
    if(street=="Unnamed Road")
    {
        return t('Location.unnamedRoad')
    }
    else{
        return street
    }
    
};
export const translateUnammedNumber = (number,t) => {
    if(number==="NN"){
        // return t('Location.NN')
        return ""
    }
    else{
        return number
    }
};          

export const startAddreseNumberCombine = (location,t) => {
    if(location.number==="Unammed Road"){
        
    }
        
};        