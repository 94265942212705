import { useTranslation } from "react-i18next";


const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const roles = ["Admin", "Operater"];
export const rolesAll = ["All", "Admin", "Operater"];
export const groupName="Web";

const enableble = ["All", "Enabled", "Disabled"];


export {
    BASE_API_URL
}

export{
    roles
}
export {
    enableble
}
