import React, { useEffect, useState } from 'react';
import { textFieldStyles } from '../../customStyles/customStyles';
import { validTextField } from '../../customStyles/customStyles';
import { invalidTextFieldStyle } from '../../customStyles/customStyles';

import { TextField } from '@mui/material';
import { useValidation } from '../Contexts/ValidationProvider';
import ValidationErrors from '../Error/ValidatoErrorComponent';
import '../Route/AddRouteComponent/AddRouteByAutocomplete.css';
import { InputAdornment } from '@mui/material';
// import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

const PasswordTextField = (props) => {
  const {
    onPasswordChange,
    password,
    name,
    onValidityChange,
    comperePasswords,
    label,
    reset
  } = props;
  const { validatePasswordField } = useValidation();
  const [showPassword, setShowPassword] = useState(false);
  const [paswordValid, setPasswordValid] = useState(null);
  const [passwordErrors, setPasswordErrors] = useState(null);
  const { t } = useTranslation();

  let translatedLabel;
  if (label === 'Password') {
    translatedLabel = t('password.Password');
  } else if (label === 'Confirm password') {
    translatedLabel = t('password.Confirm password');
  } else if (label === 'Current password') {
    translatedLabel = t('password.Current password');
  } else if (label === 'New password') {
    translatedLabel = t('password.New password');
  } else {
    translatedLabel = label;
  }

  useEffect(() => {
    if (
      comperePasswords !== undefined &&
      comperePasswords !== '' &&
      password !== ''
    ) {
      getPasswordValidation(password);
    }
  }, [comperePasswords]);

  const getPasswordValidation = (password) => {
    var validation;
    validation = validatePasswordField(password, comperePasswords);
    setPasswordValid(validation.isValid);
    setPasswordErrors(validation.errors);
    onValidityChange(name, validation.isValid);

    console.log(validation);
  };
  const handlePasswordChange = (e) => {
    // const { name, value } = e.target;
    // console.log(password)
    if (e.target.value !== '') {
      getPasswordValidation(e.target.value);
    } else {
      resetField();
    }
    // setPassword(e.target.value)
    onPasswordChange(e);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const resetField = () => {
    setPasswordErrors(null);
    setPasswordValid(null);
  };

  useEffect(()=>{
    resetField();
  },[reset])

  return (
    <>
      <div style={{ position: 'relative' }}>
        <label className="custom-label">{translatedLabel}</label>
        <TextField
          sx={{
            ...textFieldStyles, // Apply default styles by default
            ...(paswordValid && validTextField), // Apply valid styles if valid state
            ...(paswordValid === false && invalidTextFieldStyle), // Apply invalid styles if invalid state
          }}
          required
          fullWidth
          name={name}
          placeholder={translatedLabel}
          type={showPassword ? 'text' : 'password'}
          id={name}
          value={password}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {password !== '' && (
                  <IconButton
                    position="end"
                    style={{ cursor: 'pointer' }}
                    onMouseDown={togglePasswordVisibility}
                    onMouseUp={togglePasswordVisibility}
                    onMouseLeave={() => setShowPassword(false)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        ></TextField>
        <div style={{ position: 'absolute',lineHeight:"0.5rem", marginBottom:'10px' }}>
          <ValidationErrors errors={passwordErrors} />
        </div>
      </div>
    </>
  );
};

export default PasswordTextField;
