import { useEffect, useState } from "react";
import "./AddCustomLocation.css";
import { CustomLocationsEditor } from "./CustomLocationEditor";
import { useUpdateCustomLocationMutation } from "../../features/customlocations/customLocationSlice";
import { useSnackbar } from "../SneckBarProvider";

export const UpdateCustomLocation = (props) => {
  const { onUpdateCustomLocationTable, locationForUpdate } = props;
  const [updateCustomLocation] = useUpdateCustomLocationMutation();
  const showSnackbar=useSnackbar();
  const markerPosition = {
    lat: locationForUpdate.location.lat,
    lng: locationForUpdate.location.long,
  };
  const [customLocation, setCustomLocation] = useState({
    id:locationForUpdate.id,
    locationName: locationForUpdate.title,
    location: {
      lat: locationForUpdate.location.lat,
      long: locationForUpdate.location.long,
      country: locationForUpdate.location.country,
      city: locationForUpdate.location.city,
      street: locationForUpdate.location.street,
      number: locationForUpdate.location.number,
    },
  });
  const handleUpdateCustomLocation = async () => {
    
    try {
      if (customLocation.locationName != "") {
        const response = await updateCustomLocation(customLocation).unwrap();

        showSnackbar(response?.message, "success");
        onUpdateCustomLocationTable();
      } else {
        showSnackbar("You must choose the name!", "error");
      }
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };
  const handleLocationChange = (newLocation) => {
    setCustomLocation((prevState) => ({
      ...prevState,
      location: {
        ...prevState.location,
        ...newLocation,
      },
    }));
  };
  const handleLocationNameChange = (event) => {
    const newName = event.target.value;
    setCustomLocation((prevState) => ({
      ...prevState,
      locationName: newName,
    }));
  };

  const handleReset = () => {
    setCustomLocation({
      locationName: locationForUpdate.title,
      location: {
        lat: locationForUpdate.location.lat,
        long: locationForUpdate.location.long,
        country: locationForUpdate.location.country,
        city: locationForUpdate.location.city,
        street: locationForUpdate.location.street,
        number: locationForUpdate.location.number,
      },
    });
  };

  useEffect(() => {
    console.log(customLocation);
  }, [customLocation]);

  return (
    <CustomLocationsEditor
      onResetClcik={handleReset}
      editMarkerPosition={markerPosition}
      onLocationChange={handleLocationChange}
      onNameChange={handleLocationNameChange}
      customLocation={customLocation}
      onUpdateCustomLocationTable={onUpdateCustomLocationTable}
      onSubmitClick={handleUpdateCustomLocation}
    />
  );
};
