const mapDestionationToRouteStop = (routeStops,origin) => {

  return routeStops.map((routeStop) => {
    const distance =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng({
          lat: origin.lat,
          lng: origin.long,
        }),
        
        new window.google.maps.LatLng(
          routeStop.location.lat,
          routeStop.location.long
        )
      );

    return { routeStop, distance };
  });
};
export const sortRouteStops = (routeStops,origin) => {
  const sortedWaypoints = mapDestionationToRouteStop(routeStops,origin).sort(
    (a, b) => a.distance - b.distance
  );
  return sortedWaypoints;
};