import * as React from "react";
import CustomDialog from "../../Dialog/CustomDialog";
import AddRouteByAutoCompleteForm from "./AddRouteByAutoCompleteForm";
import { autoCompleteZIndex } from "../../../customStyles/customStyles";

export default function DialogAddRouteAutoComplete(props) {
  //   const fileName = "add-route-auto-complete";
  const { onClose, open, onAddNewRoute,route,onRouteUpdate } = props;

  var routeTitle
  if(route){
    routeTitle="Update Route"
  }
  else{
    routeTitle="New Route"
  }
  
  return (
    <>
      <CustomDialog
        onOpen={open}
        onClose={onClose}
        customStyle={autoCompleteZIndex}
        customMargin="0px"
        hasScroll="nema"
      >
        <AddRouteByAutoCompleteForm
          onAddNewRoute={onAddNewRoute}
          onClose={onClose}
          route={route}
          // onRouteUpdate={onRouteUpdate}
          formTitle={routeTitle}
        />
      </CustomDialog>
    </>
  );
}
