import React, { useEffect } from 'react';
import LoginForm from '../components/Login/LoginForm';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem('id');
  console.log(userId);
  useEffect(() => {
    if (userId !== null) {
      navigate('/CreateBooking');
    }
  }, [userId]);
  return <>{userId === null && <LoginForm />}</>;
};

export default LoginPage;
