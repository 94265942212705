import React, { useState, useEffect } from 'react';
import { textFieldStyles } from '../../customStyles/customStyles';
import { validTextField } from '../../customStyles/customStyles';
import { invalidTextFieldStyle } from '../../customStyles/customStyles';

import { TextField } from '@mui/material';
import { useValidation } from '../Contexts/ValidationProvider';
import ValidationErrors from '../Error/ValidatoErrorComponent';
import '../Route/AddRouteComponent/AddRouteByAutocomplete.css';
import { useTranslation } from 'react-i18next';

const UserNameTextField = (props) => {
  const { onNameChange, userName, name, label, onValidityChange,reset } = props;
  const { validateUserNameField } = useValidation();

  // const [password,setPassword]=useState("");
  const [userNameValid, setUserNameValid] = useState(null);
  const [userNameErrors, setUserNameErrors] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (userName !== '' && userName !== undefined) {
      getNameValidation(userName);
      console.log('ovamo beeee');
    }
  }, []);

  const getNameValidation = (password) => {
    var validation;
    validation = validateUserNameField(password);
    setUserNameValid(validation.isValid);
    setUserNameErrors(validation.errors);
    onValidityChange(name, validation.isValid);

    console.log(validation);
  };
  const handleNameChange = (e) => {
    if (e.target.value !== '') {
      getNameValidation(e.target.value);
    } else {
      resetField();
    }
    onNameChange(e);
  };
  const resetField = () => {
    setUserNameErrors(null);
    setUserNameValid(null);
  };

  useEffect(()=>{
    resetField();
  },[reset])
  return (
    <>
      <div style={{ position: 'relative' }}>
        <label className="custom-label">{t('users.userName')}</label>
        <TextField
          sx={{
            ...textFieldStyles, // Apply default styles by default
            ...(userNameValid && validTextField), // Apply valid styles if valid state
            ...(userNameValid === false && invalidTextFieldStyle), // Apply invalid styles if invalid state
          }}
          required
          fullWidth
          name={name}
          placeholder={t('users.userName')}
          type="text"
          id={name}
          value={userName}
          onChange={handleNameChange}
        />
        <div style={{ position: 'absolute', lineHeight: '0.5rem' }}>
          <ValidationErrors errors={userNameErrors} />
        </div>
      </div>
    </>
  );
};

export default UserNameTextField;
