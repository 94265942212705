import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Icon,
    Typography,
    Card
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import './BookingRequests.css';
import { useEnableBookingMutation, useGetBookingRequestsQuery } from '../../../features/bookings/bookingsApiSlice';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../SneckBarProvider';
import CustomButton from '../../Button/Button';


function BookingRequestsComponent(props) {
    const [requestsList, setRequestsList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    //const showSnackbar = useSnackbar();
    const showSnackbar = useSnackbar();

    const {
        data: rowsList,
        isLoading,
        refetch,
        //refetch:refetchGetBookingRequestsQuery
    } = useGetBookingRequestsQuery();

    const [enableBooking] = useEnableBookingMutation();


    const navigate = useNavigate();

    const handleEnableBooking = async (id) => {
        const bookingId = parseInt(id);
        console.log('param:' + bookingId)
        const response = await enableBooking(bookingId).unwrap();
        //navigate('/Bookings', { replace: true });
        refetch();
        showSnackbar(response?.message, "success");
    }

    const handleEnableMultipleClick = (event) => {
        console.log(selectedRows);
        selectedRows.map(async (row) => {
            await enableBooking(row)
                .then(() =>
                    console.log('ADDED: ' + requestsList))
                .catch((error) => {
                    console.log(error.message)
                });
        })

        setSelectedRows([]);
        setRequestsList([]);
        refetch();
    }

    const handleCheckboxClick = (event, id) => {
        if (event.target.checked) {
            if (!selectedRows || selectedRows.length == 0)
                setSelectedRows([id]);
            else {
                const newRows = selectedRows.concat(id);
                setSelectedRows(newRows);
            }
        }
        else {
            if (selectedRows.length != 1) {
                const newRows = selectedRows.filter((element) => {
                    return element !== id;
                });
                setSelectedRows(newRows);
            }
            else
                setSelectedRows([]);
        }
        console.log(selectedRows);
    }

    useEffect(() => {
        console.log(rowsList)
        refetch();
    }, [])

    function RenderButton(rowId) {
        console.log('id of render button : ' + rowId.rowId)

        if (!selectedRows || selectedRows.length === 0) {
            return (

                <CustomButton type='enable-one'
                    onClick={(event) => handleEnableBooking(rowId.rowId)}>
                </CustomButton>
            )
        }
        else {
            return (
                <CustomButton type='enable-more'>
                </CustomButton>
            )
        }
    }

    function RenderEnableMultipleButton(selected) {
        if (!selectedRows || selectedRows.length === 0) {
            return (
                <strong>SELECT</strong>
            )
        }
        else {
            return (
                <strong
                    onClick={(event) => handleEnableMultipleClick(event)}
                    className='booking-enable-button'>
                    ENABLE SELECTED
                </strong>
            )
        }
    }

    return (
        <Card className='booking-requests-global-container'>
            <TableContainer>
                <Table size="small" aria-label="a dense table">
                    <TableHead id='head' sx={{backgroundColor: 'ButtonShadow'}}>
                        <TableRow id='rows'>
                            <TableCell id='checkbox' sx={{ width: '100px', height: '50px' }}>
                                <RenderEnableMultipleButton selected={selectedRows} />
                            </TableCell>
                            <TableCell id='title'> <strong>TITLE</strong></TableCell>
                            <TableCell id='code'><strong>BOOKING CODE</strong></TableCell>
                            <TableCell id='capacity'><strong>CAPACITY</strong></TableCell>
                            <TableCell id='price'><strong>PRICE</strong></TableCell>
                            <TableCell id='enable'><strong>ENABLE</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody id='body'>
                        {
                            isLoading ?
                                <TableRow>
                                    <TableCell align="center" colSpan={6}>
                                        <Typography variant="h5" style={{ textAlign: 'center', padding: '20px', margin: '20px' }}>Loading...</Typography>
                                    </TableCell>
                                </TableRow>
                                :
                                rowsList.length === 0 ?
                                    (
                                        <TableRow>
                                            <TableCell align="center" colSpan={6}>
                                                <Typography variant="h5" style={{ textAlign: 'center', padding: '20px', margin: '20px' }}>No content for table</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ) :
                                    rowsList.map((row, index) =>
                                    (

                                        <TableRow
                                            key={index}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                height: '70px'
                                            }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="error"
                                                    onChange={(event) => handleCheckboxClick(event, row.id)}
                                                    checked={selectedRows ? selectedRows.find(el => el == row.id) : false}
                                                />
                                            </TableCell>
                                            <TableCell>{row.route.title}</TableCell>
                                            <TableCell>{row.bookingCode}</TableCell>
                                            <TableCell>{row.capacity}</TableCell>
                                            <TableCell>{row.route.price}$</TableCell>
                                            <TableCell>
                                                <RenderButton rowId={row.id} /*selectedLen={selectedRows}*/ />
                                            </TableCell>

                                        </TableRow>
                                    ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )
}

export default BookingRequestsComponent;