import { apiSlice } from "../../app/api/apiSlice";

export const routeStopsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRouteStopsPerRouteId: builder.query({
      query: (routeId) =>
        `RouteStop/GetRouteStopsForDropDown?routeId=${routeId}`,
    }),
    enableRouteStop: builder.mutation({
      query: (routeStopId) => ({
        url: `RouteStop/EnableRouteStop?id=${routeStopId}`,
        method: "PUT",
      }),
    }),
    disableRouteStop: builder.mutation({
      query: (routeStopId) => ({
        url: `RouteStop/DisableRouteStop?id=${routeStopId}`,
        method: "PUT",
      }),
    }),
    addRouteStop: builder.mutation({
      query: (body) => ({
        url: `RouteStop/AddRouteStop`,
        method: "POST",
        body,
      }),
    }),
    addRouteStops: builder.mutation({
      query: (body) => ({
        url: `RouteStop/AddRouteStops`,
        method: "POST",
        body,
      }),
    }),
    deleteRouteStop: builder.mutation({
      query: (routeStopId) => ({
        url: `RouteStop/DeleteRouteStop?id=${routeStopId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetRouteStopsPerRouteIdQuery,
  useEnableRouteStopMutation,
  useDisableRouteStopMutation,
  useAddRouteStopMutation,
  useDeleteRouteStopMutation,
  useAddRouteStopsMutation
} = routeStopsApiSlice;
