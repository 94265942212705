export const addConnection = async (userId,conn) => {
  if (userId) {
    try {
      await conn.invoke('AddConnection', userId);
      // console.log(`Joinning groupe: ${groupName}`);
    } catch (err) {
      console.error('Error joining group:', err.toString());
    }
  }
};
