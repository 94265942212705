import axios from "axios";
import { BASE_API_URL } from "../config.js";

export const getAllUsers = () => {
  return axios.get(BASE_API_URL + "User/GetAllUsers");
};

export const viewUserById = async (id) => {
  return await axios.get(`${BASE_API_URL}User/ViewUserById?id=${id}`);
};
export const getUserRequests = () => {
  return axios.get(BASE_API_URL + "User/GetAllUserRequests");
};

export const enableUser = (id) => {
  return axios({
    method: "put",
    url: BASE_API_URL + "User/EnableUser",
    data: id,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
};

export const disableUser = (id) => {
  return axios({
    method: "put",
    url: BASE_API_URL + "User/DisableUser",
    data: id,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
};

export const editUser = async (user) => {
  return await axios({
    method: "patch",
    url: BASE_API_URL + "User/EditUser",
    data: user,
  });
};
