import React, { useState, useEffect } from "react";
import "./CustomButton.css";
const FilledButton = (props) => {
  const { children,onClick,isValid,type,style } = props

  const handleClick=(event)=>{
    console.log(isValid)
    if(isValid==true){
      onClick(event);
    }
  }

  return (
    <>
      <button
      // type={type}
        style={style}
        className={isValid ? (type=="greenButton" ? 'filled-green-custom-button': 'filled-custom-button') : 'invalid-custom-button'}
        onClick={handleClick}
        
      >
        {children}
      </button>
    </>
  );
};

export default FilledButton;
