import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
    Card, CardContent, Typography, List, ListItem, ListItemText, Divider, Button, Tooltip, 
    Dialog, TextField, InputLabel, Select, MenuItem, Table, TableContainer, TableHead, 
    TableCell, TableRow, TableBody, Paper 
} from '@mui/material';

import './BookingDetails.css';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../SneckBarProvider';
import { useGetReservationByIdQuery } from '../../../features/reservation/reservationSlice';
import { useGetPassangersQuery } from '../../../features/passangers/passangerApiSlice';
import moment from 'moment';

const BookingDetails = () => {
    const { reservationId } = useParams();
    const {data: reservations } = useGetPassangersQuery({id:reservationId});
    const { t } = useTranslation();
    
   
    return (
        <>
            <h3 className='card-title'>{t('passenger_details.bookingDetails')}</h3>
            {reservations?.map((reservation) => (
                <>
                <Card className="card-body" key={reservation.id}>
                    <div className='card-details'>
                    <CardContent>
                        <Typography variant="h5" component="div" sx={{mb:'10px'}}>
                        {t('passenger_details.bookingName')}: {reservation.bookingCode}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {t('passenger_details.departure')}: {reservation.booking?.departure ? moment(reservation.booking.departure).format('DD/MM/YYYY HH:mm') : 'N/A'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {t('passenger_details.arrival')}: {reservation.booking?.arrival ? moment(reservation.booking.arrival).format('DD/MM/YYYY HH:mm') : 'N/A'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {t('passenger_details.from')}: {reservation.startStop?.location?.city || 'Unknown'}, {reservation.startStop?.location?.country || 'Unknown'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {t('passenger_details.to')}: {reservation.endStop?.location?.city || 'Unknown'}, {reservation.endStop?.location?.country || 'Unknown'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {t('passenger_details.price')}: {reservation.price} EUR
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {t('passenger_details.paymentMethod')}: {reservation.paymentMethod?.title}
                        </Typography>
                        {reservation?.paymentMethodReturn?.title && 
                        <Typography variant="body2" color="text.secondary">
                        {t('passenger_details.paymentMethodReturn')}: {reservation?.paymentMethodReturn?.title}
                        </Typography>}
                        <Typography variant="body2" color="text.secondary">
                        {t('passenger_details.createdBy')}: {reservation.booking?.creator ? `${reservation.booking.creator.firstName} ${reservation.booking.creator.lastName}` : 'N/A'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {t('passenger_details.passengers')}:
                        </Typography>
                        <List>
                            {reservation.passengers?.map((passenger, index) => (
                                <ListItem key={passenger.id}>
                                    <ListItemText
                                        primary={`${index + 1}. ${passenger.name} ${passenger.lastName} ${passenger.phoneNumber}`}
                                        secondary={`Status: ${passenger.reservationStatus}`}
                                    />
                                </ListItem>
                            ))}
                        </List> 
                        
                    </CardContent>
                    </div>
                </Card>
                </>))}
                <div className="notification">
                {reservations?.length == 0 && 
                <h1>{t('passenger_details.noBookingDetails')}</h1>
                }
                </div>

         
         </>
        
    );
};

export default BookingDetails;
