import React from 'react';
import ResponsiveAppBar from './Navbar/Appbar';
import { Outlet, useLocation } from 'react-router-dom';

const Layout = () => {
  const location = useLocation();
  const showHeader = !location.pathname.startsWith('/ReservationConfirmation');
  return (
    <>
      {showHeader && <ResponsiveAppBar />}

      <Outlet />
    </>
  );
};

export default Layout;
