import React, { useEffect, useState } from 'react';
import './FilterComponent.css';
import FilledButton from '../../Button/FilledButton';
import WhiteButton from '../../Button/WhiteButton';
import { ReactComponent as ArrowUp } from '../../../Icons/Dropdown.svg';
import { useTranslation } from 'react-i18next';
import { Accordion } from '@mui/material';
const FiltersContainer = (props) => {
  const {
    children,
    onResetClick,
    onFilterClcik,
    containerName,
    hiddenButtons,
    opened
  } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(opened ? true:false);

  const handleClcik = () => {
    setOpen(!open);
  };
  return (
    <>
      {/* <Accordion> */}
      <div className="filters-component-main-container">
        <div className="filters-component-top-container">
          <div className="filters-component-top-content-container">
            <p className="filter-component-header-container">
              {containerName ? containerName : t('reservationsFilters.filter')}
            </p>
            <ArrowUp
              onClick={handleClcik}
              className={`filter-component-arrow ${open ? 'open' : ''}`}
            ></ArrowUp>
          </div>
        </div>
        <div
          //   style={{ display: "none" }}
          className={`filters-component-content-container ${!open ? 'second-class-name' : ''}`}
        >
          {open && children}
          {hiddenButtons != true && (
            <div className="filters-component-buttons-container">
              <WhiteButton onClick={onResetClick}>
                {t('reservationsFilters.filterButtonReset')}
              </WhiteButton>
              <FilledButton isValid={true} onClick={onFilterClcik}>
                {t('reservationsFilters.search')}
              </FilledButton>
            </div>
          )}
        </div>
      </div>
      {/* </Accordion> */}
    </>
  );
};

export default FiltersContainer;
{
  /* <Accordion>
<AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls="panel1-content"
  id="panel1-header"
>
  Accordion 1
</AccordionSummary>
<AccordionDetails>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
  malesuada lacus ex, sit amet blandit leo lobortis eget.
</AccordionDetails>
</Accordion>
<Accordion> */
}
