import React from "react";
import { Button } from "@mui/material";
// import "./CustomDialog.css";
import "./CustomButton.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';

const CustomButton = (props) => {
  let icon = null;
  let style = "outlined-button-style";
  let variant = "outlined";

  let disabled = props.disabled;
  const { children, type, onClick,style:addStyle } = props;
  if (type === "submit") {
    icon = <AddCircleOutlineIcon />;
    disabled = false
  } else if (type === "filter") {
    icon = <SearchIcon />;
    variant = "contained";
    style = "contained-button-style";
    disabled = false
  } else if (type === "add") {
    icon = <AddIcon />;
    variant = "contained";
    style = "contained-button-style";
    disabled = false
  } else if (type === 'enable-one') {
    icon = <CheckIcon />
    variant='contained'
    style = "contained-button-style"
    disabled = false
  } else if (type === 'add-client') {
    icon = <ElectricBoltIcon />
    variant='contained'
    style=`contained-button-style ${addStyle}`
    //disabled=true
  } 
  else if(type === 'button-view'){
    style='button-view'
  }
  
  else {
    variant = "outlined";
    style = "contained-button-style";
    disabled = false
  }

  return (
    <>
      <Button onClick={onClick} className={style} variant={variant} disabled={disabled}>
        {icon && <>{icon}&nbsp;</>}
        {children}
      </Button>
    </>
  );
};

export default CustomButton;
