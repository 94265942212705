import React, { useState } from "react";
import { textFieldStyles } from "../../customStyles/customStyles";
import { TextField } from "@mui/material";
import { useValidation } from "../Contexts/ValidationProvider";
import "./validationErrors.css";
import { useTranslation } from "react-i18next";

const ValidationErrors = (props) => {
  const { t, i18n } = useTranslation();
  const { errors } = props;

  return (
    <span className="validation-error-span">
      {errors && (
        <>
          {/* {errors.map((error, index) => (
            <span key={index}>{error}</span>
          ))} */}
          {errors[0] != undefined && <span>{t(`errors.${errors[0]}`)}</span>}
        </>
      )}
    </span>
  );
};

export default ValidationErrors;
