import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  TextField,
  FormControlLabel,
  Button,
  Card,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import "./AddBookingForm.css";
import {
  useCreateBookingMutation,
  useGetRoutesListForDropDownQuery,
} from "../../../features/bookings/bookingsApiSlice";
import { useSnackbar } from "../../SneckBarProvider";
import { useTranslation } from "react-i18next";

const AddBookingForm = ({ onClose }) => {
  const { t, i18n } = useTranslation();

  const showSnacbar = useSnackbar();
  const isAdmin = localStorage.getItem("roles"); //useSelector(selectCurrentRole);
  const { data, isLoading } = useGetRoutesListForDropDownQuery();
  const [defaultRouteId, setDefaultRouteId] = useState(0);

  const selectRef = useRef(defaultRouteId); // Ref for the Select component

  const initialState = {
    isEnabled: isAdmin === 'Admin' ? true : false,
    enabledAt: isAdmin === 'Admin' ? undefined : null,
    enabledBy: isAdmin === 'Admin' ? localStorage.getItem('id') : null,
    bookingCode: '',
    departure: Date.now,
    arrival: Date.now,
    capacity: 0,
    IsRoundTrip: false,
    routeId: defaultRouteId,
  }
  const [bookingState, setBookingState] = useState(initialState);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleDatePickerOpen = () => {
    setIsDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
  };

  const handleSelectChange = (event) => {
    setDefaultRouteId(event.target.value);

    setBookingState({
      ...bookingState,
      routeId: event.target.value,
    });
  };

  const [createBooking] = useCreateBookingMutation();

  const handleCreateBookingClick = async (event) => {
    event.preventDefault();
    try {
      const response = await createBooking(bookingState).unwrap();
      setBookingState(initialState);
      selectRef.current.value = defaultRouteId;
      if (localStorage.getItem('roles') ==='Admin' ) {
        showSnacbar(response?.message, "success");
      }else{
        showSnacbar("Request for booking is send to Admin,please wait for accepting your booking", "success");
      }
    } catch (error) {
      showSnacbar(error?.data?.Message, "error");
    }

  }

  const handleRoundTrip = () => {
    setBookingState({
      ...bookingState,
      IsRoundTrip: !bookingState.IsRoundTrip,
    });
  };

  useEffect(() => {
    if (!isLoading && data.length > 0) {
      // Set default routeId to the id of the first element in the data array
      setDefaultRouteId(data[0].id);
    }
  }, []);

  return (
    <div className="add-form">
      <Card
        className="add-booking"
        variant="outlined"
        sx={{ border: "none", boxShadow: "none" }}
      >
        <form style={{ margin: '20px' }} onSubmit={(event) => handleCreateBookingClick(event)}>
          <div className="add-booking-booking-item">
            <TextField
              className="add-booking-form-field"
              label= {(t('customDialog.bookingCode'))}
              variant="outlined"
              onChange={(event) => {
                setBookingState({
                  ...bookingState,
                  bookingCode: event.target.value,
                });
              }}
              value={bookingState.bookingCode}
            ></TextField>
          </div>

          <div className="add-booking-booking-item">
            <TextField
              className="add-booking-form-field"
              label= {(t('customDialog.departure'))}
              type="datetime-local"
              onChange={(event) => {
                setBookingState({
                  ...bookingState,
                  departure: event.target.value,
                });
              }}
              value={bookingState.departure}
              InputLabelProps={{ shrink: true }}

              onFocus={handleDatePickerOpen}
              onBlur={handleDatePickerClose}
            ></TextField>
          </div>

          <div className="add-booking-booking-item">
            <TextField
              className="add-booking-form-field"
              label={t("customDialog.arrival")}
              type="datetime-local"
              onChange={(event) => {
                setBookingState({
                  ...bookingState,
                  arrival: event.target.value,
                });
              }}
              value={bookingState.arrival}
              InputLabelProps={{ shrink: true }} // Keep the label always visible

              onFocus={handleDatePickerOpen}
              onBlur={handleDatePickerClose}
            ></TextField>
          </div>

          <div className="add-booking-booking-item">
            <TextField
              className="add-booking-form-field"
              label={(t('customDialog.capacity'))}
              onChange={(event) => {
                setBookingState({
                  ...bookingState,
                  capacity: event.target.value,
                });
              }}
              value={bookingState.capacity}
            ></TextField>
          </div>

          <div className="add-booking-booking-item">
            <FormControl className="add-booking-form-field">
              <InputLabel id="demo-simple-select-label">{(t('main.route'))}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={defaultRouteId}
                label={(t('main.route'))}
                onChange={handleSelectChange}
                ref={selectRef} // Assign the ref to the Select component
              >
                {isLoading ? (
                  <MenuItem>...Loading</MenuItem>
                ) : (
                  data.map((route, index) => {
                    return (
                      <MenuItem key={index} value={route.id}>
                        {route.title}
                      </MenuItem>
                    );
                  })
                )}
              </Select>
            </FormControl>
          </div>

          <div className="add-booking-booking-item">
            <FormControlLabel
              className="add-booking-form-field"
              control={
                <Checkbox
                  checked={bookingState.IsRoundTrip}
                  onChange={handleRoundTrip}
                  label={(t('customDialog.roundTrip'))}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label={(t('customDialog.roundTrip'))}
            />
          </div>
          <div className="add-booking-booking-button">
            <Button type="submit" variant="outlined" color="secondary">
              {t('customDialog.addRide')}
            </Button>

            <Button onClick={onClose} variant="outlined" color="secondary">
            {t('customDialog.cancelRide')}
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddBookingForm;
