import { getNextFullHour, getTwoNextFullHour } from "../../components/Booking/AddBooking/DirectionComponent";

const userRole = localStorage.getItem("roles");

  export const emptyBookingState = {
    id: 0,
    isEnabled: userRole === 'Admin' ? true : false,
    enabledAt: userRole === 'Admin' ? new Date().toJSON() : null,
    enabledBy: userRole === 'Admin' ? localStorage.getItem('id') : null,
    bookingCode: '',
    departure: new Date(),
    departureTime:getNextFullHour().format('HH:mm:ss'), //null, //getNextFullHour(),
    arrival: new Date(),
    arrivalTime: getTwoNextFullHour().format('HH:mm:ss'),//null,//getTwoNextFullHour(),
    capacity: 0,
    IsRoundTrip: true,
    routeId: '',
    direction: true,
    subagent: null,
    isEveningDeparture: false
  }
  
  export const emptyReservationState = {
    id: '',
    isEnabled: userRole === 'Admin' ? true : false,
    enabledAt: userRole === 'Admin' ? new Date().toJSON() : null,
    enabledBy: userRole === 'Admin' ? localStorage.getItem('id') : null,
    preciseStartAddress: '',
    startStopId: 0,
    startAddressNote: '',
    preciseEndAddress: '',
    endStopId: 0,
    endAddressNote: '',
    preciseStartAddressReturn: '',
    startStopIdReturn: null,
    startAddressNoteReturn: '',
    preciseEndAddressReturn: '',
    endStopIdReturn: null,
    endAddressNoteReturn: '',
    isReturnTicket: false,
    bookingId: '',
    bookingIdReturn: null,
    paymentMethodId: null,
    paymentMethodIdReturn: null,
    note: '',
    noteReturn: '',
    carRental: null
  }