import { Button, Divider, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SendIcon from '@mui/icons-material/Send';
import CustomButton from '../../Button/Button';
import './AddReservationFormComponent.css';
import { teal } from '@mui/material/colors';

const AddReservationFormComponent = () => {
  const [passenger, setPassenger] = useState('');
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  
  const handleChange = (event) => {
    const newValue = event.target.value;
    setPassenger(newValue);
    // Perform validation using regex
    const isValid = /^[a-zA-Z]+\s[a-zA-Z]+\s\d{5,20}$/.test(newValue);
    setIsBtnDisabled(isValid);
  };


  const handleAddPassenger = () => {
    console.log("Button ADD clicked!");
  }

  return (

    <>
      <Divider />

      <div className='container'>
        <TextField className='input-user-field'
          placeholder="npr. Milan Ivanović 0650000000"
          helperText="Format: Ime Prezime Telefon"
          value={passenger}
          onChange={handleChange}
        >
        </TextField>

        <CustomButton 
        disabled={!isBtnDisabled} 
        onClick={handleAddPassenger} 
        type='add-client' 
        style={!isBtnDisabled ? 'custom-button-disabled' : 'custom-button'}
         >
          Add client
        </CustomButton> 
      </div>
    </>

  )
}

export default AddReservationFormComponent

// import React, { useEffect, useRef, useState } from 'react'
// import { useNavigate } from 'react-router-dom';
// import { useGetRouteStopsPerRouteIdQuery } from '../../features/routestops/routeStopSlice';
// import { useGetUsersPerPageQuery, useGetUsersQuery } from '../../features/users/UsersApiSlice';
// import { useGetAvailableBookingsQuery, useLazyGetAvailableBookingsQuery } from '../../features/bookings/bookingsApiSlice';
// import { useGetRouteByIdQuery } from '../../features/routes/routesSlice';
// import { Autocomplete, Button, Card, Checkbox, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
// import AddRouteStopsDialog from '../RouteStops/AddRouteStop/AddRouteStopsDialog';
// import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
// import { useCreateReservationMutation, useGetRoutesStopsByRouteIdQuery, useLazyGetRoutesStopsByRouteIdQuery } from '../../features/reservation/reservationSlice';

// const AddReservationFormComponent = () => {

//   //const requestisAdminOrOperater = localStorage.getItem('roles');

//   const initialReservationInsert = {
//     //isEnabled: (requestisAdminOrOperater === 'Admin' || 'Operater') ? true:false,
//     //acceptedAt: (requestisAdminOrOperater === 'Admin' || 'Operater') ? undefined:null,
//     userId: '',
//     bookingId: '',
//     isReturnTicket: false,
//     startStopId: 0,
//     endStopId: 0,
//     price: 0,
//     paymentMethod: ''
//   };
//   const [reservationInsert, setResrvationInsert] = useState(initialReservationInsert);

//   const { data: bookings, isLoading } = useGetAvailableBookingsQuery();
//   const [selectedBookingId, setSelectedBookingId] = useState(null);
//   const [selectedRouteIdFromBooking, setSelectedRouteIdFromBooking] = useState(null);

//   const { data: users, isUsersLoading } = useGetUsersQuery();
//   const [selectedUserId, setSelectedUserId] = useState(null);

//   const [getRouteStops, { data: routeStops, isLoading: isRouteStopsLoading }] = useLazyGetRoutesStopsByRouteIdQuery();//useGetRoutesStopsByRouteIdQuery();
//   const [selectedStartRouteStopId, setSelectedStartRouteStopId] = useState(null);
//   const [selectedEndRouteStopId, setSelectedEndRouteStopId] = useState(null);

//   const [createReservation] = useCreateReservationMutation();

//   const handleBookingChange = async (event, value) => {
//     if (value) {
//       setSelectedBookingId(value.id)
//       setSelectedRouteIdFromBooking(value.routeId);

//       setResrvationInsert({
//         ...initialReservationInsert,
//         bookingId:value.id
//       })
//       /*setSelectedStartRouteStopId(null);
//       setSelectedEndRouteStopId(null);*/

//       getRouteStops(value.routeId);
//     } else {
//       setSelectedRouteIdFromBooking(null);
//     }
//   };

//   const handleUserChange = (event, value) => {
//     if (value) {
//       setSelectedUserId(value.id);

//       setResrvationInsert({
//         ...reservationInsert,
//         userId:value.id
//       })
//     } else {
//       setSelectedUserId(null);
//     }
//   };

//   const handleRouteStopsChange = (event, flag, value) => {
//     if (flag === 'start' && value) {
//       setSelectedStartRouteStopId(value.id);

//       setResrvationInsert({
//         ...reservationInsert,
//         startStopId:value.id
//       })
//     } else if (flag === 'end' && value) {
//       setSelectedEndRouteStopId(value.id);

//       setResrvationInsert({
//         ...reservationInsert,
//         endStopId:value.id
//       })
//     }
//   };

//   const handleCreateBookingClick = async (e)=>{
//     e.preventDefault();

//     try {
//       createReservation(reservationInsert);
//       setResrvationInsert(initialReservationInsert);
//     } catch (error) {
//       console.log(error);
//     }
//   }

//   return (
//     <div className='add-form'>
//       <Card
//         className="add-booking"
//         variant="outlined"
//         sx={{ border: "none", boxShadow: "none" }}
//       >
//         <form style={{ margin: '20px' }} onSubmit={(event) => handleCreateBookingClick(event)}>

//           <div className="add-booking-booking-item">
//             <Autocomplete
//               disablePortal
//               id="combo-box-demo1"
//               options={bookings || []}
//               getOptionLabel={(option) => option.bookingCode} // Specify how to render the options
//               sx={{ width: 300 }}
//               renderInput={(params) => <TextField {...params} label="Booking" />}
//               onChange={handleBookingChange}
//             />
//           </div>

//           <div className="add-booking-booking-item">
//             <Autocomplete
//               disablePortal
//               id="combo-box-demo2"
//               options={users || []}
//               getOptionLabel={(option) => `${option.firstName} ${option.lastName}`} // Specify how to render the options
//               sx={{ width: 300 }}
//               renderInput={(params) => <TextField {...params} label="User" />}
//               onChange={handleUserChange}
//             />
//           </div>

//           <div className="add-booking-booking-item">
//             <Autocomplete
//               disabled={selectedRouteIdFromBooking != null ? false : true}
//               disablePortal
//               id="combo-box-demo3"
//               options={routeStops || []}
//               //options={selectedStartRouteStopId == null ? [] : routeStops || []}
//               getOptionLabel={(option) => option.location}
//               sx={{ width: 300 }}
//               renderInput={(params) => <TextField {...params} label="Start Route Point" />}
//               onChange={(e, selectedOption) => handleRouteStopsChange(e, 'start', selectedOption)}
//             />
//           </div>

//           <div className="add-booking-booking-item">
//             <Autocomplete
//               disabled={selectedRouteIdFromBooking != null ? false : true}
//               disablePortal
//               id="combo-box-demo4"
//               options={routeStops || []}
//               //options={selectedEndRouteStopId == null ? [] : routeStops||[]}
//               getOptionLabel={(option) => option.location}
//               sx={{ width: 300 }}
//               renderInput={(params) => <TextField {...params} label="End Route Point" />}
//               onChange={(e, selectedOption) => handleRouteStopsChange(e, 'end', selectedOption)}
//             />
//           </div>

//           <div className="add-booking-booking-item">
//             <FormControlLabel
//               className="add-booking-form-field"
//               control={
//                 <Checkbox
//                   checked={reservationInsert.isReturnTicket}
//                   onChange={(event) =>({
//                     ...reservationInsert,
//                     isReturnTicket:event.target.checked
//                   })}
//                   label={"Round Trip"}
//                   inputProps={{ "aria-label": "primary checkbox" }}
//                 />
//               }
//               label="Round Trip"
//             />
//           </div>

//           <div className="add-booking-booking-item">
//             <TextField
//               className="add-booking-form-field"
//               label="Price"
//               type='number'
//               inputProps={{ step: 100, min: 0 }} // Set the step size to 100 and minimum value to 0
//               onChange={(event)=>{
//                 setResrvationInsert({
//                   ...reservationInsert,
//                   price: event.target.value
//                 })
//               }}
//               value={reservationInsert.price}
//             ></TextField>
//           </div>

//           <div className='add-booking-booking-item'>
//             <FormControl>
//               <FormLabel id="demo-row-radio-buttons-group-label">Payment Method</FormLabel>
//               <RadioGroup
//                 row
//                 aria-labelledby="demo-row-radio-buttons-group-label"
//                 name="row-radio-buttons-group"
//                 onChange={(event)=>{
//                   setResrvationInsert({
//                     ...reservationInsert,
//                     paymentMethod:event.target.value
//                   })
//                 }}
//               >
//                 <FormControlLabel value="cash" control={<Radio />} label="Cash" />
//                 <FormControlLabel value="check" control={<Radio />} label="Check" />
//                 <FormControlLabel value="card" control={<Radio />} label="Card" />
//               </RadioGroup>
//             </FormControl>
//           </div>

//           <div className="add-booking-booking-button">
//             <Button type="submit" variant="outlined" color="secondary">
//               ADD RESERVATION
//             </Button>
//           </div>
//         </form>
//       </Card>
//     </div>
//   )
// }

// export default AddReservationFormComponent