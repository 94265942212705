import React, { useEffect, useState } from 'react';
import './RegistrationInvitation.css';
import { useTranslation } from 'react-i18next';
const LoginRegisterContainer = (props) => {
  const { children,title } = props;
  const {t}=useTranslation();
  return (
    <>
      <div className="login-register-main-container">
        <div className="login-register-overlay-container">
          <div className="login-register-picture-container">
            <div className="login-register-stars"></div>
            <div className="login-register-text-container">
              <p className="login-register-big-text-container">
                {t("main.loginPageMainText")}
              </p>
              <p className="login-register-small-text-container">
               {t("main.quicksafe")}
              </p>
            </div>
          </div>
        </div>
        <div className="login-register-content-container">
          <div style={{ height: '10vh' }}></div>
          <div className="login-register-content-form-container">
            <div className='login-register-logo-container'>

            </div>
            <span className='login-register-header-container'>
            {title}
            </span>
            <div className='login-register-from-fields-container'>
                {children}
            </div>
          </div>
          <div className='tera-link-container' >
            <a href='https://terratravel.rs/'>www.terratravel.rs</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginRegisterContainer;
