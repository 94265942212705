import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState:{id:null,roles: null,token:null},
    reducers:{
        setCredentials: (state,action) => {
            const {id,roles,token} = action.payload;
            localStorage.setItem('id',id)
            localStorage.setItem('token',token)
            localStorage.setItem('roles',roles)

            state.id = id;
            state.roles = roles;
            state.token = token;
        },
        logOut:(state,action)=>{
            localStorage.removeItem('id')
            localStorage.removeItem('token');
            localStorage.removeItem('roles');

            state.id = null;
            state.roles = null;
            state.token = null;
        },
    },
});

export const {setCredentials,logOut} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentRole = (state) => state.auth.roles;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurentId = (state) => state.auth.id;


