import React, { useCallback, useEffect } from 'react';
import ThankYouContent from '../components/ThankYou/ThankYouContent';
import { useParams } from 'react-router-dom';
import { useConfirmReservationMutation } from '../features/sms/smsSlice';
import { useEditBookingMutation } from '../features/bookings/bookingsApiSlice';

const ThankYouPage = () => {
  const { paramName } = useParams();
  const [confirmReservation] = useConfirmReservationMutation(); 

  
  const handleConfirmReservation = useCallback(async () => {
    if (paramName) {
      try {
        const response = await confirmReservation(paramName).unwrap();
        console.log('Reservation confirmed successfully', response);
      } catch (error) {
        console.error('Failed to confirm reservation', error);
      }
    }
  }, [paramName, confirmReservation]);

  useEffect(() => {
    handleConfirmReservation();
  }, [handleConfirmReservation]);


  console.log(paramName);
  return (
    <div>
      <ThankYouContent />
    </div>
  );
};

export default ThankYouPage;
