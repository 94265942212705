// src/utils/validation.js

export const validatePassword = (password, comperePasswords) => {
  console.log("12312", comperePasswords);
  const minLength = 0;
  //const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-zA-Z]/.test(password);
  const hasDigit = /[0-9]/.test(password);
  const compereExists = comperePasswords === undefined || comperePasswords==="";
  const samePasswords = password === comperePasswords;

  const isValid =
    password.length >= minLength && 
    hasLowerCase &&
    hasDigit &&
    (compereExists || samePasswords);

  const errors = [];
  if (password.length < minLength)
    errors.push(`Password must be at least 1 characters long.`);
  // if (!hasUpperCase)
  //   errors.push("Password must contain at least one uppercase letter.");
  if (!hasLowerCase)
    errors.push("Password must contain at least one letter.");
  if (!hasDigit) errors.push("Password must contain at least one digit.");
  if (!compereExists && !samePasswords) {
    errors.push("Passwords must be the same.");
  }
  console.log(errors);

  return { isValid, errors };
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValid = emailRegex.test(email);
  const errors = [];

  if (!isValid) {
    errors.push("Email is not valid.");
  }

  return { isValid, errors };
};
export const validateRouteTitle = (routeTitle) => {
  // const routeRegex = /^[A-ZČĆŽŠĐ][a-zčćžšđA-ZČĆŽŠĐ\s]*( - [A-ZČĆŽŠĐ][a-zčćžšđA-ZČĆŽŠĐ\s]*)+$/;
  // const isValid = routeRegex.test(routeTitle);
  // const errors = [];

  // if (!isValid) {
  //   errors.push(
  //     'Route must be in the format `City1 - City2`, with each city starting with an uppercase letter.'
  //   );
  // }
  // console.log(errors);
  const isValid=true
  const errors=[]
  return { isValid, errors };
};
export const validateName = (firstName) => {
  const minLength = 2;
  const maxLength = 50;
  const startsWithUpperCase = /^[A-ZČĆŠĐŽ]/.test(firstName);
  const isAlphabetic = /^[a-zA-ZČĆŠĐŽčćšđž\s'-]+$/u.test(firstName);

  const isValid =
    firstName.length >= minLength &&
    firstName.length <= maxLength &&
    startsWithUpperCase &&
    isAlphabetic;

  const errors = [];
  if (firstName.length < minLength)
    errors.push(`First name must be at least 2 characters long.`);
  if (firstName.length > maxLength)
    errors.push(
      `First name must be no more than 50 characters long.`
    );
  if (!startsWithUpperCase)
    errors.push("First name must start with an uppercase letter.");
  if (!isAlphabetic)
    errors.push(
      "First name must contain only alphabetic characters, spaces, hyphens, or apostrophes."
    );

  console.log(errors);

  return { isValid, errors };
};
export const validateUsername = (username) => {
  const minLength = 6;
  const maxLength = 30;
  const startsWithAlpha = /^[A-Za-z]/.test(username);
  const isValidChars = /^[A-Za-z0-9_.]+$/.test(username);

  const isValid =
    username.length >= minLength &&
    username.length <= maxLength &&
    startsWithAlpha &&
    isValidChars;

  const errors = [];
  if (username.length < minLength)
    errors.push(`Username must be at least 6 characters long.`);
  if (username.length > maxLength)
    errors.push(`Username must be no more than 30 characters long.`);
  if (!startsWithAlpha)
    errors.push(
      "Username must start with an alphabetic character (A-Z or a-z)."
    );
  if (!isValidChars)
    errors.push(
      "Username can only contain alphanumeric characters and underscores (_)."
    );

  console.log(errors);

  return { isValid, errors };
};
export const validatePrice = (price) => {


  const isValid =
    price >= 0

  const errors = [];
  if (!isValid)
    errors.push(`Price can't be undefined!`);
  

  console.log(errors);

  return { isValid, errors };
};

export const validetateLocation = (location) => {
  const isValid =
    location !== null &&
    location !== undefined

  const errors = [];
  if (!isValid)
    errors.push(`You must choose the location!`);
  

  console.log(errors);

  return { isValid, errors };
};