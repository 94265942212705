// import { useState } from "react";
// import GoogleMapComponent from "../../GoogleMap/GoogleMap";

// export const DisplayRouteComponent = (props) => {
//   const { routeStops, origin, destination } = props;
//   const [map, setMap] = useState(null);
// //   const [spherical, setSpherical] = useState(null);

// //   const mappedRouteStops = useState([]);

//   console.log(routeStops, origin, destination);

//   const onLeftClick = () => {
//     console.log(map);
//   };

//   return (
//     <GoogleMapComponent
//       onLeftClick={onLeftClick}
//       routeStops={routeStops}
//       origin={origin}
//       destination={destination}
//     ></GoogleMapComponent>
//   );
// };

import { useState, useEffect } from "react";
import GoogleMapComponent from "../../GoogleMap/GoogleMap";
import { getDirections } from "../../../services/DirectionService";
import { sortRouteStops } from "../../../utils/googleMapsHelper/sortRouteStops";
import { getRouteLength } from "../../../utils/googleMapsHelper/getRouteLength";
import { useSnackbar } from "../../SneckBarProvider";
import { useTranslation } from "react-i18next";

export const DisplayRouteComponent = (props) => {
  const { t } = useTranslation();

  const {
    routeStops,
    origin,
    destination,
    onRightClick,
    checkMap,
    getStartRouteLength,
    newRouteStop,
    handleRemoveLastStop
  } = props;
  const [map, setMap] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [sortedRouteStops, setSortedStops] = useState([]);
  const [routesResults, setRoutesResult] = useState([]);
  const [startRouteLength, setStartRouteLength] = useState(null);
  const showSnackBar = useSnackbar();

  useEffect(() => {
    if (map) {
      setDirectionsRenderer(
        new window.google.maps.DirectionsRenderer({
          map,
          polylineOptions: {
            strokeColor: "#e91e63",
            strokeOpacity: 1.0,
            strokeWeight: 3,
          },
        })
      );
      setGeocoder(new window.google.maps.Geocoder());
      if (origin && destination) {
        // setSortedStops(sortRouteStops(routeStops, origin));
      }
      //using this in addRouteStop component;
      if (checkMap) {
        checkMap(true);
      }
    }
  }, [map]);

  useEffect(() => {
    if (map) {

      setSortedStops(sortRouteStops(routeStops, origin));
    }
  }, [routeStops, map]);

  useEffect(() => {
    if (map && directionsRenderer) {
      const fetchDirectionResult = async () => {
        try {
          const result = await getDirections(
            origin,
            destination,
            sortedRouteStops
          );
          // if(routesResults.some(res=>res==result)){
          setRoutesResult((prevRoutes) => {
            return [...prevRoutes, result];
          });
          // }
        } catch (error) {
          if (
            (error.message =
              "DIRECTIONS_ROUTE: ZERO_RESULTS: No route could be found between the origin and destination.")
          )
            console.log("fdasfdasfgdsfdasfdasf");
          if (newRouteStop === null || newRouteStop===undefined) {
            console.log(newRouteStop)
            showSnackBar(
              "Check your stops and identify the one causing issues when drawing the route.",
              "error"
            );
          } else {
            console.log(newRouteStop)
            // if(newRouteStop!=undefined){
            showSnackBar(
              t('responses.cannotAddStop', { city: newRouteStop.location.city }),
              "error"
            );
          // }
          }
          // handleRemoveLastStop();

          console.error(error.message);
        }
      };
      fetchDirectionResult();
    }
  }, [sortedRouteStops]);

  useEffect(() => {
    if (getStartRouteLength) {
      props.getStartRouteLength(startRouteLength);
    }
  }, [startRouteLength]);

  useEffect(() => {
    if (directionsRenderer && routesResults.length > 0) {
      console.log(routesResults);
      if (!startRouteLength && routesResults) {
        if (routesResults[0] != undefined) {
          const routeLength = getRouteLength(routesResults[0].routes[0]);
          setStartRouteLength(routeLength);
        }
      }
      directionsRenderer.setDirections(routesResults[routesResults.length - 1]);
    }
  }, [routesResults]);

  const onLeftClick = () => {
    console.log(map);
  };
  const handleRightClick = (cords) => {
    console.log(cords);
    if (onRightClick) {
      onRightClick(cords);
    }
  };
  return (
    <GoogleMapComponent
      onLeftClick={onLeftClick}
      onRightClick={handleRightClick}
      setMap={setMap}
    />
  );
};
