import React, { useEffect, useState } from 'react';

const CardContainer = (props) => {
  const { reservationsCount, description, maskClass,type } = props;

  return (
    <div className="reservation-filters-card-container">
      <div className="reservation-filters-image-container">
        <div className={`reservation-filters-image-mask ${maskClass}`}>
          <span>{description}</span>
        </div>
      </div>

      <span className={`all-reservations-info-${type}`}>{reservationsCount}</span>
    </div>
  );
};

export default CardContainer;
