import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Checkbox,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { Link, useNavigate } from 'react-router-dom';
import {
  useCreateReservationExcelExportMutation,
  useCreateReservationPdfExportMutation,
} from '../../../features/export/exportApiSlice';
import {
  useChangeReservationStatusMutation,
  useGetReservationsBySubagentQuery,
  useGetReservationsQuery,
} from '../../../features/reservation/reservationSlice';
import './AllReservationsComponent.css';
import ReservationsFilters from './ReservationsFilters';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import PendingIcon from '@mui/icons-material/Pending';
import dayjs from 'dayjs';
import '../../../customStyles/globalDesignStyles.css';
import { useGetUserByIdQuery } from '../../../features/users/UsersApiSlice';
import { ReactComponent as ExportIcon } from '../../../Icons/exportIcon.svg';
import {
  translateUnammedNumber,
  translateUnammedStreet,
} from '../../../utils/translateUnammedLocation';
import SMSButton from '../../Button/SMSButton';

import { ReactComponent as EditIcon } from '../../../Icons/edit-01.svg';
import moment from 'moment/moment';
import CustomDialog from '../../Dialog/CustomDialog';
import EditDepartueTime from './EditDepartureTime';
import { useSnackbar } from '../../SneckBarProvider';
import { CircleNotifications } from '@mui/icons-material';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

const AllReservationsComponent = () => {
  const [isCheckAllChecked, setIsCheckAllChecked] = useState(false);
  const [isFiltersClear, setIsFiltersClear] = useState(true);
  const [checkedReservations, setCheckedReservations] = useState(new Set());
  const [checkedReservationsList, setCheckedReservationsList] = useState([]);

  const [
    isCheckAllVisibleReservationUnavailable,
    setIsCheckAllVisibleReservationUnavailable,
  ] = useState(true);

  const [changeReservationStatus] = useChangeReservationStatusMutation();
  const { t, i18n } = useTranslation();
  const [expandedRows, setExpandedRows] = useState([]);
  const [openEditTimeDialog, setOpenEditTimeDialog] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [timeType, setTimeType] = useState(false);
  const [downloadExcelFile] = useCreateReservationExcelExportMutation();
  const [downloadPdfFile] = useCreateReservationPdfExportMutation();
  const randomColors = ['rgba(254, 240, 199, 0.6)', 'rgba(92, 118, 112, 0.6)'];
  const role = localStorage.getItem('roles');
  const id = localStorage.getItem('id');
  const { data: userForId } = useGetUserByIdQuery(id);
  const showSnackbar = useSnackbar();
  const { data: reservationsBySubagent } =
    useGetReservationsBySubagentQuery(id);
  const [hideTooltip, setHideTooltip] = useState(true);

  const handleCreateDownloadLink = (documentBlob, flag) => {
    const url = window.URL.createObjectURL(documentBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `reservations-${filters.date || 'default'}` +
      (flag === 'excel' ? '.xlsx' : '.pdf')
    );
    if (filters.date != undefined && filters != null) {
      link.setAttribute(
        'download',
        `reservations-${filters.date}` + (flag == 'excel' ? 'xlsx' : 'pdf')
      );
    } else {
      link.setAttribute(
        'download',
        'reservations.' + (flag == 'excel' ? 'xlsx' : 'pdf')
      );
    }

    document.body.appendChild(link);
    link.click();
  };

  const handleOnExcelDownload = async () => {
    const languagePrefix = i18n.language == 'en' ? 'en' : 'rs';
    //const way = filters.direction == "departure"  ? "departure" : "return";
    console.log(filters.direction);
    const way =
      filters.direction == 'departure' ||
        filters.direction == '' ||
        filters.direction == undefined
        ? 'departure'
        : 'return';

    const swapDirection =
      filters.direction == '' || filters.direction == undefined ? false : true;
    try {
      const response = await downloadExcelFile({
        reservations: data,
        lang: languagePrefix,
        direction: way,
        swapDirection: swapDirection,
      });

      // Assuming response.data contains the blob data returned from the server
      handleCreateDownloadLink(response.data, 'excel');
    } catch (error) {
      console.error('Error while downloading Excel file:', error);
    }
  };

  /*const handleOnPdfDownload = async () => {
        try {
            //const { data: pdfBlob } = await downloadPdfFile(data);
            //handleCreateDownloadLink(pdfBlob, "pdf");

            const { data: excelBlob } = await downloadTickets(data);
            handleCreateDownloadLink(excelBlob, "excel");
        } catch (error) {
            console.error('Error while downloading PDF file:', error);
        }
    };*/

  const navigate = useNavigate();
  const initialStateFilters = {
    date: null,
    bookingId: undefined,
    routeId: undefined,
    //direction: 'all',
    direction: '',
    isReturnTicket: undefined,
    isNightLine: undefined,
    createdFrom: null,
    createdTo: null,
    passengerName:
      role == 'Subagent'
        ? userForId?.firstName + ' ' + userForId?.lastName
        : '',
    reservationId: '',
    reservationType: 'all',
    nightLine: false,
    pageSize: 25,
    pageNumber: 1,
  };
  const [filters, setFilters] = useState(initialStateFilters);

  const { data, isLoading, refetch, isFetching } =
    useGetReservationsQuery(filters);

  const totalPassengersReservations = data
    ? data?.totalPassengersReservations
    : 0;

  const uncheckCheckbox = () => {
    setIsCheckAllChecked(false);
  };

  const handleFilterReservation = (newFilters) => {
    setFilters(newFilters);

    const filtersAreDifferent = areFiltersDifferent(
      newFilters,
      initialStateFilters
    );

    if (filtersAreDifferent) {
      console.log('filter are NOT same as initial');
      //setIsFiltersClear(false);
      uncheckCheckbox();
      setCheckedReservations(new Set());
      setCheckedReservationsList([]);
    } else {
      //setIsFiltersClear(true);
      uncheckCheckbox();
      setCheckedReservations(new Set());
      setCheckedReservationsList([])
      console.log('filters are RESETED')
    }
  };

  const handleIdClick = (reservationId) => {
    const languagePrefix = i18n.language === 'en' ? '/en' : '/rs';
    const url = `${languagePrefix}/EditBooking/${reservationId}`;
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      navigate(url);
    }
  };

  const handleChangeRowsPerPage = () => {
    setFilters({
      ...filters,
      pageSize: filters.pageSize + 25,
    });
  };

  const openEditTimeDialogHandler = (reservation, type) => {
    setOpenEditTimeDialog(true);
    setSelectedReservation(reservation);
    setTimeType(type);
  };

  const closeEditTimeDialog = () => {
    setOpenEditTimeDialog(false);
    setSelectedReservation(null);
  };

  const showTime = (reservation) => {
    let isReturnDepartureTime =
      (reservation?.startStopReturn &&
        reservation.bookingReturn.direction === true &&
        filters.direction === 'departure') ||
      (reservation.startStopReturn &&
        reservation.bookingReturn.direction === false &&
        filters.direction === 'return') ||
      (reservation?.isReturnTicket &&
        reservation.bookingReturn.direction === false &&
        reservation.booking.direction === true &&
        isSameDate(reservation.bookingReturn?.departure, filters?.date)) ||
      (reservation?.isReturnTicket &&
        reservation.booking.direction === false &&
        reservation.bookingReturn.direction === true &&
        isSameDate(reservation.bookingReturn?.departure, filters?.date));

    let formattedTime = moment(
      isReturnDepartureTime
        ? reservation?.bookingReturn?.departureTime
        : reservation?.booking?.departureTime,
      'HH:mm:ss'
    ).format('HH:mm');

    const icon = (
      <Button
        className="icon"
        onClick={openEditTimeDialogHandler.bind(
          this,
          reservation,
          isReturnDepartureTime
        )}
        disabled={isPastReservation(reservation, isReturnDepartureTime)}
      >
        <EditIcon style={{ cursor: 'pointer' }} />
      </Button>
    );
    if (formattedTime == 'Invalid date')
      formattedTime = t('reservations.undefined');
    // console.log(formattedTime);
    return (
      <span style={{ alignItems: 'center' }}>
        {formattedTime} <br /> {icon}
      </span>
    );
  };

  const isSwapedDirection = (reservation) => {
    return (
      (reservation?.startStopReturn &&
        reservation.bookingReturn.direction === true &&
        filters.direction === 'departure') ||
      (reservation.startStopReturn &&
        reservation.bookingReturn.direction === false &&
        filters.direction === 'return') ||
      (reservation?.isReturnTicket &&
        isSameDate(reservation.bookingReturn?.departure, filters?.date))
    );
  };

  const showStartLocation = (reservation) => {
    let isReturnDepartureTime = isSwapedDirection(reservation);
    let location = isReturnDepartureTime
      ? reservation?.startStopReturn?.location?.city
      : reservation?.startStop?.location?.city;
    return <span style={{ alignItems: 'center' }}>{location}</span>;
  };

  const showEndLocation = (reservation) => {
    let isReturnDepartureTime = isSwapedDirection(reservation);
    let location = isReturnDepartureTime
      ? reservation?.endStopReturn?.location?.city
      : reservation?.endStop?.location?.city;
    return <span style={{ alignItems: 'center' }}>{location}</span>;
  };

  const showStartAddress = (reservation) => {
    let isReturnDepartureTime = isSwapedDirection(reservation);
    let location = isReturnDepartureTime
      ? getLocationDisplay(
        reservation?.startStopReturn,
        reservation?.startLocationReturnTitle
      )
      : getLocationDisplay(
        reservation?.startStop,
        reservation?.startLocationTitle
      );
    return <span style={{ alignItems: 'center' }}>{location}</span>;
  };

  const showEndAddress = (reservation) => {
    let isReturnDepartureTime = isSwapedDirection(reservation);
    let location = isReturnDepartureTime
      ? getLocationDisplay(
        reservation?.endStopReturn,
        reservation?.endLocationReturnTitle
      )
      : getLocationDisplay(reservation?.endStop, reservation?.endLocationTitle);
    return <span style={{ alignItems: 'center' }}>{location}</span>;
  };

  const isPastReservation = (reservation, isReturnDepartureTime) => {
    if (
      isReturnDepartureTime &&
      dayjs(reservation.bookingReturn.departure)
        .startOf('day')
        .isBefore(dayjs().startOf('day'))
    )
      return true;
    else if (
      !isReturnDepartureTime &&
      dayjs(reservation.booking.departure)
        .startOf('day')
        .isBefore(dayjs().startOf('day'))
    )
      return true;
    else return false;
  };

  const showDate = (reservation) => {
    const formattedDate =
      (reservation?.startStopReturn &&
        reservation.bookingReturn.direction == true &&
        filters.direction == 'departure') ||
        (reservation.startStopReturn &&
          reservation.bookingReturn.direction == false &&
          filters.direction == 'return') ||
        (reservation?.isReturnTicket &&
          reservation.bookingReturn.direction == false &&
          reservation.booking.direction == true &&
          isSameDate(reservation.bookingReturn?.departure, filters?.date)) ||
        (reservation?.isReturnTicket &&
          reservation.booking.direction == false &&
          reservation.bookingReturn.direction == true &&
          isSameDate(reservation.bookingReturn?.departure, filters?.date))
        ? dayjs(reservation.bookingReturn?.departure).format('DD/MM/YYYY')
        : dayjs(reservation.booking?.departure).format('DD/MM/YYYY');
    return <span>{formattedDate}</span>;
  };

  const getReservationsHandler = () => {
    refetch();
    showSnackbar(t('reservations.departureTimeUpdated'), 'success');
  };
  useEffect(() => {
    // Implement any necessary logic in useEffect
    setFilters({
      ...filters,
      passengerName:
        role === 'Subagent'
          ? `${userForId?.firstName} ${userForId?.lastName}`
          : filters.passengerName,
    });
    //refetch(); // query havae param from state filters already and dont require any refetch
  }, [data]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (totalPassengersReservations >= filters.pageSize) {
        handleChangeRowsPerPage();
      }
    }
  };

  const navigateToReservations = () => {
    navigate('/UnapprovedReservations');
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [filters, isLoading]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Paid':
        return 'green';
      case 'SMSConfirmed':
        return 'green';
      case 'Cancelled':
        return 'red';
      case 'Pending':
        return '#FFBF00'; // Yellow color
      default:
        return 'inherit';
    }
  };

  const getStatusMenuItems = () => {
    return ['Paid', 'Cancelled', 'Pending'].map((status) => (
      <MenuItem
        key={status}
        value={status}
        disabled={status == 'SMSConfirmed' ? true : false}
      >
        {status === 'Paid' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              width: '100%',
            }}
          >
            <CheckIcon style={{ fontSize: '0.875rem', color: '#12B76A' }} />
            <p style={{ fontSize: '14px', margin: '0' }}>
              {t('reservations.statusPaid')}
            </p>
          </div>
        )}

        {status === 'Cancelled' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              width: '100%',
            }}
          >
            <ClearIcon style={{ fontSize: '0.875rem', color: '#F04438' }} />
            <p style={{ fontSize: '14px', margin: '0' }}>
              {t('reservations.statusCancelled')}
            </p>
          </div>
        )}
        {status === 'Pending' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              width: '100%',
            }}
          >
            <PendingIcon style={{ fontSize: '0.875rem', color: '#FFBF00' }} />
            <p style={{ fontSize: '14px', margin: '0', marginRight: '5px' }}>
              {t('reservations.statusPending')}
            </p>
          </div>
        )}
      </MenuItem>
    ));
  };

  const handleStatusChange = async (
    event,
    reservationTicketType,
    passenger
  ) => {
    event.preventDefault();
    setHideTooltip(false);
    try {
      await changeReservationStatus({
        reservationTicketType: reservationTicketType,
        passengerId: passenger.id,
        status: event.target.value,
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  function getLocationDisplay(routeStop, title) {
    if (title === '' || title === null) {
      const street = translateUnammedStreet(routeStop?.location?.street, t);
      const number = translateUnammedNumber(routeStop?.location?.number, t);
      return `${street} ${number}`;
    }

    return title;
  }

  const isSameDate = (departure, date) => {
    if (date == null) return false;
    const departureDate = new Date(departure);
    const filterDate = new Date(date);

    const isSameDate =
      departureDate.toISOString().slice(0, 10) ===
      filterDate.toISOString().slice(0, 10);
    return isSameDate;
  };

  const renderReservationsFilters = () => {
    try {
      if (
        data?.totalPassengersReservations != null ||
        data?.totalPassengersReservations ==
        undefined /*|| data.totalPassengersReservations == 0*/
      ) {
        return (
          <ReservationsFilters
            filters={filters}
            onReceiveFilteredReservation={handleFilterReservation}
            role={'Admin'} // TO DO: add userRole, not admin
            total={data?.totalPassengersReservations}
            cancelled={data?.totalCanceledReservations}
            uncancelled={data?.totalNoCanceledReservations}
          />
        );
      } else {
        return <Typography>No filters</Typography>;
      }
    } catch (error) {
      console.error('Error rendering ReservationsFilters LINE 347:', error);
      return (
        <Typography>
          Something went wrong while rendering the filters.
        </Typography>
      );
    }
  };

  const checkForDirection = (reservation) => {

    let isReturnBooking = false;

    ((reservation?.startStopReturn && reservation.bookingReturn.direction == true && filters.direction == 'departure') ||
      (reservation.startStopReturn && reservation.bookingReturn.direction == false && filters.direction == 'return') ||
      (reservation?.isReturnTicket && reservation.bookingReturn.direction == false && reservation.booking.direction == true && isSameDate(reservation.bookingReturn?.departure, filters?.date)) ||
      (reservation?.isReturnTicket && reservation.booking.direction == false && reservation.bookingReturn.direction == true && isSameDate(reservation.bookingReturn?.departure, filters?.date))
      ?
      isReturnBooking = false // returnBooking
      :
      isReturnBooking = true  // booking
    )
    return isReturnBooking == false ? 'returnBooking' : 'booking'
  };

  const handleMainCheckboxChange = (reservationId, bookingDirection) => {
    setCheckedReservations((prevSelectedReservations) => {
      const newSelectedReservations = new Set(prevSelectedReservations);
      const newSelectedReservationsList = [...checkedReservationsList];

      if (newSelectedReservations.has(reservationId)) {
        // If the reservation is already selected, deselect it
        newSelectedReservations.delete(reservationId);

        const index = newSelectedReservationsList.findIndex(res => res.reservationId === reservationId);
        if (index !== -1) {
          newSelectedReservationsList.splice(index, 1);
        }
      } else {
        // Otherwise, select the reservation
        newSelectedReservations.add(reservationId);
        newSelectedReservationsList.push({ reservationId: reservationId, direction: bookingDirection });
      }

      console.log(newSelectedReservations);
      console.log(newSelectedReservationsList);

      setCheckedReservationsList(newSelectedReservationsList);
      return newSelectedReservations;
    });
  };

  const isChecked = (reservationId) => checkedReservations.has(reservationId);

  const areFiltersDifferent = (currentFilters, initialFilters) => {
    return Object.keys(initialFilters).some(
      (key) => currentFilters[key] != initialFilters[key]
    );
  };

  const handleCheckAllVisible = (event) => {
    setIsCheckAllChecked((prevState) => {
      return !prevState;
    });

    const newSelectedReservationsList = [...checkedReservationsList];

    if (event.target.checked) {
      const allVisibleReservationIds = data?.reservations
        .filter(reservation => reservation.routeId != null && reservation.passengers.length > 0
          &&
          (
            checkForDirection(reservation) == 'returnBooking' ? checkBooking(reservation.bookingReturn) : checkBooking(reservation.booking)
          )
        )
        //.map((reservation) => reservation.id);
        .map((reservation) => {
          // Add the reservation with its direction to the selected list
          const direction = checkForDirection(reservation) == 'returnBooking' ? reservation.bookingReturn.direction : reservation.booking.direction

          newSelectedReservationsList.push({ reservationId: reservation.id, direction: direction });
          return reservation.id;
        });

      console.log(new Set(allVisibleReservationIds));
      setCheckedReservations(new Set(allVisibleReservationIds));

      console.log(newSelectedReservationsList);
      setCheckedReservationsList(newSelectedReservationsList);
    } else {
      // If "Check All" is unchecked, clear all checked reservations
      setCheckedReservations(new Set());
      setCheckedReservationsList([]); // Clear the checkedReservationsList

    }
  };

  const notifyHandler = () => {
    showSnackbar('notifyyy', 'success');
  };

  dayjs.extend(isSameOrAfter);

  const checkBooking = (booking) => {
    const hasValidDeparture = dayjs(booking.departure).startOf('day').isSameOrAfter(dayjs().startOf('day'));
    const res = booking.departureTime != null && hasValidDeparture;
    return res;
  };

  const isCheckboxDisabled = (reservation) => {
    return checkForDirection(reservation) == 'returnBooking' ? !checkBooking(reservation.bookingReturn) : !checkBooking(reservation.booking);
  };

  useEffect(() => {
    const filtersAreDifferent = areFiltersDifferent(
      filters,
      initialStateFilters
    );
    setIsCheckAllVisibleReservationUnavailable(!filtersAreDifferent);

    if (dayjs(filters.date).startOf('day').isBefore(dayjs().startOf('day'))) {
      setIsCheckAllVisibleReservationUnavailable(true);
    }
  }, [filters, initialStateFilters]);
  const renderSelectValue = (selected) => {
    if (selected === 'Paid') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <CheckIcon style={{ fontSize: '0.875rem', color: '#12B76A' }} />
          <p style={{ fontSize: '14px', margin: '0' }}>
            {t('reservations.statusPaid')}
          </p>
        </div>
      );
    } else if (selected === 'SMSConfirmed') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <CheckIcon style={{ fontSize: '0.875rem', color: '#12B76A' }} />
          <p style={{ fontSize: '14px', margin: '0', marginRight: '5px' }}>
            {t('reservations.statusConfirmedSMS')}
          </p>
        </div>
      );
    } else if (selected === 'Pending') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <PendingIcon style={{ fontSize: '0.875rem', color: '#FFBF00' }} />
          <p style={{ fontSize: '14px', margin: '0', marginRight: '5px' }}>
            {t('reservations.statusPending')}
          </p>
        </div>
      );
    } else if (selected === 'Cancelled') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <ClearIcon style={{ fontSize: '0.875rem', color: '#F04438' }} />
          <p style={{ fontSize: '14px', margin: '0' }}>
            {t('reservations.statusCancelled')}
          </p>
        </div>
      );
    }
    return selected;
  };

  return (
    <div className="all-reservations-main-container">
      {openEditTimeDialog && (
        <CustomDialog
          onOpen={openEditTimeDialog}
          onClose={closeEditTimeDialog}
          contentTitle={t('reservations.editDepartureTime')}
        >
          <EditDepartueTime
            onSuccess={closeEditTimeDialog}
            getReservationAfterEdit={getReservationsHandler}
            selectedReservation={selectedReservation}
            type={timeType}
          ></EditDepartueTime>
        </CustomDialog>
      )}
      <div className="all-reservations-content-container">
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            <h2 className="title-card-booking">{t('booking-list.name')}</h2>

            {renderReservationsFilters()}

            <div className="all-reservations-component-buttons">
              {/* { role === 'Admin' && 
                            <Button onClick={navigateToReservations} className='filters-button'>
                                <CheckCircleIcon /> &nbsp; {t('reservations.confirmList')}
                            </Button> } */}
              {/* <Button onClick={handleOnPdfDownload} className='filters-button'>
                                <PrinterIcon /> &nbsp; {t('reservations.pdfButton')}
                            </Button> */}
              <SMSButton reservations={checkedReservationsList} />


              {/* <Button onClick={notifyHandler} className="button-excel">
                <CircleNotifications /> &nbsp; {t('reservations.notify')}
              </Button> */}
              <Button onClick={handleOnExcelDownload} className="button-excel">
                <ExportIcon /> &nbsp; {t('reservations.excelButton')}
              </Button>
            </div>
            {isCheckAllChecked == true || checkedReservations.size != 0 ? (
              <div className="label-container">
                <p className="check-count-label">
                  {t('reservations.number-of-checked-box-label')}{' '}
                  {checkedReservations.size}
                </p>
              </div>
            ) : (
              <></>
            )}
            <TableContainer component={Paper} className="table-container">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {data?.reservations?.length > 0 && (
                  <TableHead sx={{ backgroundColor: 'ButtonShadow' }}>
                    <TableRow className="table-row-header">
                      <TableCell
                        sx={{
                          borderLeft: '10px solid buttonShadow',
                          width: '100px',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip
                            title={t('reservations.selectAllCheckboxTooltip')}
                          >
                            <Checkbox
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  // color: '#ced9d1',
                                  color: isCheckAllVisibleReservationUnavailable
                                    ? '#ced9d1'
                                    : 'initial',
                                },
                              }}
                              disabled={(isCheckAllVisibleReservationUnavailable && isFiltersClear) /*|| dayjs(filters.date).startOf('day').isBefore(dayjs().startOf('day'))*/}
                              color="default"
                              onChange={handleCheckAllVisible}
                              checked={isCheckAllChecked}
                            ></Checkbox>
                          </Tooltip>
                          {t('reservations.bookingId')}
                        </div>
                      </TableCell>
                      <TableCell>{t('reservations.fullName')}</TableCell>
                      <TableCell sx={{ width: '120px' }}>
                        {t('reservations.phone')}
                      </TableCell>
                      <TableCell sx={{ width: '200px' }}>
                        {t('reservations.startStopAddress')}
                      </TableCell>
                      <TableCell sx={{ width: '150px' }}>
                        {t('reservations.startStopTown')}
                      </TableCell>
                      <TableCell sx={{ width: '200px' }}>
                        {t('reservations.endStopAddress')}
                      </TableCell>
                      <TableCell sx={{ width: '150px' }}>
                        {t('reservations.endStopTown')}
                      </TableCell>
                      <TableCell sx={{ width: '150px' }}>
                        {t('reservations.paymentMethod')}
                      </TableCell>
                      <TableCell sx={{ width: '150px' }}>
                        {t('reservations.isReturnTicket')}
                      </TableCell>
                      <TableCell sx={{ width: '120px' }}>
                        {t('reservations.createdAt')}
                      </TableCell>
                      <TableCell sx={{ width: '120px' }}>
                        {t('reservations.departureTime')}
                      </TableCell>
                      <TableCell sx={{ width: '200px' }}>
                        {t('reservations.status')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {data?.reservations?.map((reservation, index) => (
                    <React.Fragment key={index}>
                      {reservation.routeId == null ||
                        reservation.passengers.length < 1 ? (
                        <></>
                      ) : (
                        <>
                          {/* Main row for the first passenger */}
                          <TableRow
                            key={`main-${index}`}
                            sx={{ padding: '0px' }}
                            className="table-row"
                          >
                            <TableCell
                              sx={{
                                color: 'green',
                                textAlign: 'left !important',
                                borderLeft: `10px solid ${randomColors[index % 2]}`,
                                cursor: 'pointer',
                              }}
                              className="booking-number"
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Checkbox
                                  checked={isChecked(reservation.id)}
                                  /*onChange={() =>
                                    handleMainCheckboxChange(reservation.id,
                                      (reservation?.startStopReturn && reservation.bookingReturn.direction == true && filters.direction == 'departure') ||
                                        (reservation.startStopReturn && reservation.bookingReturn.direction == false && filters.direction == 'return') ||
                                        (reservation?.isReturnTicket && reservation.bookingReturn.direction == false && reservation.booking.direction == true && isSameDate(reservation.bookingReturn?.departure, filters?.date)) ||
                                        (reservation?.isReturnTicket && reservation.booking.direction == false && reservation.bookingReturn.direction == true && isSameDate(reservation.bookingReturn?.departure, filters?.date))
                                        ? reservation.bookingReturn.direction
                                        : reservation.booking.direction
                                    )
                                  }*/
                                  onChange={() =>
                                    handleMainCheckboxChange(
                                      reservation.id,
                                      checkForDirection(reservation) == 'returnBooking' ? reservation.bookingReturn.direction : reservation.booking.direction
                                    )
                                  }
                                  sx={{ display: 'inline' }}
                                  color="primary"
                                  disabled={isCheckboxDisabled(reservation)}
                                ></Checkbox>
                                <a
                                  onClick={() => handleIdClick(reservation.id)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="booking-number"
                                  style={{ textAlign: 'left !important' }}
                                >
                                  #{reservation.passengers[0].reservationId}
                                </a>
                              </div>
                            </TableCell>
                            <TableCell
                              sx={{
                                wordWrap: 'break-word' /* Break long words */,
                                wordBreak: 'break-word',
                                whiteSpace: 'normal',
                                minWidth: '100px !important',
                                // padding:"0px !important",
                                // maxWidth:"100px",
                              }}
                            >{`${reservation.passengers[0].name} ${reservation.passengers[0].lastName == null ? '' : reservation.passengers[0].lastName}`}</TableCell>
                            <TableCell
                              sx={{
                                wordWrap: 'break-word' /* Break long words */,
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                                padding: '0px !important',
                                minWidth: '120px',
                              }}
                            >
                              {reservation.passengers[0].phoneNumber || '/'}
                            </TableCell>

                            <TableCell>
                              {showStartAddress(reservation)}
                              {/* {`${translateUnammedStreet(reservation?.startStop?.location?.street, t)}`}
                                  {` ${translateUnammedNumber(reservation?.startStop?.location?.number, t)}`} */}
                            </TableCell>
                            <TableCell>
                              {showStartLocation(reservation)}
                            </TableCell>
                            <TableCell>
                              {showEndAddress(reservation)}

                              {/* {`${translateUnammedStreet(reservation.endStop.location.street, t)}`}{' '}
                                  {` ${translateUnammedNumber(reservation.endStop.location.number, t)}`} */}
                            </TableCell>

                            <TableCell>
                              {showEndLocation(reservation)}
                            </TableCell>
                            <TableCell>
                              {filters.direction == '' ||
                                filters.direction == 'departure'
                                ? reservation.paymentMethod?.title
                                : reservation.paymentMethodReturn?.title ==
                                  null ||
                                  reservation.paymentMethodReturn?.title == ''
                                  ? reservation.paymentMethod?.title
                                  : reservation.paymentMethodReturn?.title}
                            </TableCell>
                            <TableCell>
                              {reservation.isReturnTicket
                                ? t('reservations.withReturnTicket')
                                : t('reservations.withoutReturnTicket')}
                            </TableCell>

                            <TableCell>{showDate(reservation)}</TableCell>

                            <TableCell>{showTime(reservation)}</TableCell>
                            <TableCell>
                              {((filters.direction == 'return' &&
                                reservation.isReturnTicket &&
                                !reservation.booking.direction) ||
                                (filters.direction == 'return' &&
                                  !reservation.booking.direction) ||
                                (filters.direction == 'departure' &&
                                  reservation.booking.direction) ||
                                filters.direction == '' ||
                                filters.direction == undefined) && (
                                <Tooltip
                                  title={t('booking.departureTicket')}
                                  placement="right-start"
                                >
                                  <Select
                                    value={
                                      reservation.passengers[0]
                                        .reservationStatus
                                    }
                                    onChange={(event) =>
                                      handleStatusChange(
                                        event,
                                        'departureTicket',
                                        reservation.passengers[0]
                                      )
                                    }
                                    renderValue={renderSelectValue}
                                    sx={{
                                      color: getStatusColor(
                                        reservation.passengers[0]
                                          .reservationStatus
                                      ),
                                      margin: '5px',
                                      maxWidth: '160px',
                                      minWidth: '160px',
                                      height: '30px',
                                      backgroundColor: 'white',
                                    }}
                                  >
                                    {getStatusMenuItems()}
                                  </Select>
                                </Tooltip>
                              )}
                              {reservation.bookingIdReturn &&
                                ((filters.direction == 'departure' &&
                                  reservation.isReturnTicket &&
                                  !reservation.booking.direction) ||
                                  (filters.direction == 'return' &&
                                    reservation.isReturnTicket &&
                                    reservation.booking.direction) ||
                                  filters.direction == '' ||
                                  filters.direction == undefined) && (
                                  <Tooltip
                                    title={t('booking.returnTicket')}
                                    placement="right-start"
                                    slotProps={{
                                      popper: {
                                        sx: {
                                          [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                          {
                                            marginBottom: '30px',
                                          },
                                        },
                                      },
                                    }}
                                  >
                                    <Select
                                      value={
                                        reservation.passengers[0]
                                          .returnReservationStatus
                                      }
                                      onChange={(event) =>
                                        handleStatusChange(
                                          event,
                                          'returnTicket',
                                          reservation.passengers[0]
                                        )
                                      }
                                      sx={{
                                        color: getStatusColor(
                                          reservation.passengers[0]
                                            .returnReservationStatus
                                        ),
                                        margin: '5px',
                                        maxWidth: '160px',
                                        minWidth: '160px',
                                        height: '30px',
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      {getStatusMenuItems()}
                                    </Select>
                                  </Tooltip>
                                )}
                            </TableCell>
                          </TableRow>

                          {/* Additional rows for other passengers */}
                          {reservation.passengers
                            .slice(1)
                            .map((passenger, passengerIndex) => (
                              <React.Fragment
                                key={`${index}-${passengerIndex}`}
                              >
                                <TableRow
                                  sx={{ padding: '0px' }}
                                  className="table-row"
                                >
                                  <TableCell
                                    sx={{
                                      color: 'green',
                                      textAlign: 'center !important',
                                      cursor: 'pointer',
                                      borderLeft: `10px solid ${randomColors[index % 2]}`,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Checkbox
                                        checked={isChecked(reservation.id)}
                                        onChange={() =>
                                          handleMainCheckboxChange(
                                            reservation.id
                                          )
                                        }
                                        style={{ visibility: 'hidden' }}
                                        sx={{ display: 'inline' }}
                                        color="default"
                                      ></Checkbox>
                                      <a
                                        onClick={() =>
                                          handleIdClick(passenger.reservationId)
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        &nbsp;&nbsp;&nbsp;#
                                        {
                                          reservation.passengers[0]
                                            .reservationId
                                        }
                                      </a>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      wordWrap:
                                        'break-word' /* Break long words */,
                                      wordBreak: 'break-word',
                                      whiteSpace: 'normal',
                                      minWidth: '100px !important',
                                      // padding:"0px !important",
                                      // minWidth:"120px",
                                    }}
                                  >{`${passenger.name}  ${passenger.lastName == null ? '' : passenger.lastName}`}</TableCell>
                                  <TableCell
                                    sx={{
                                      wordWrap:
                                        'break-word' /* Break long words */,
                                      wordBreak: 'break-all',
                                      whiteSpace: 'normal',
                                      padding: '0px !important',
                                      minWidth: '120px',
                                    }}
                                  >
                                    {passenger.phoneNumber || '/'}
                                  </TableCell>
                                  <TableCell>
                                    {showStartAddress(reservation)}
                                    {/* {{`${translateUnammedStreet(reservation?.startStop?.location?.street, t)}`}{' '}
                                        {`${translateUnammedNumber(reservation?.startStop?.location?.number, t)}`}} */}
                                  </TableCell>
                                  <TableCell>
                                    {showStartLocation(reservation)}
                                  </TableCell>

                                  <TableCell>
                                    {showEndAddress(reservation)}
                                  </TableCell>

                                  <TableCell>
                                    {showEndLocation(reservation)}
                                  </TableCell>
                                  <TableCell>
                                    {filters.direction == '' ||
                                      filters.direction == 'departure'
                                      ? reservation.paymentMethod?.title
                                      : reservation.paymentMethodReturn
                                        ?.title == null ||
                                        reservation.paymentMethodReturn
                                          ?.title == ''
                                        ? reservation.paymentMethod?.title
                                        : reservation.paymentMethodReturn
                                          ?.title}
                                  </TableCell>
                                  <TableCell>
                                    {reservation.isReturnTicket
                                      ? t('reservations.withReturnTicket')
                                      : t('reservations.withoutReturnTicket')}
                                  </TableCell>
                                  <TableCell>{showDate(reservation)}</TableCell>
                                  <TableCell>{showTime(reservation)}</TableCell>
                                  <TableCell>
                                    {((filters.direction == 'return' &&
                                      reservation.isReturnTicket &&
                                      !reservation.booking.direction) ||
                                      (filters.direction == 'return' &&
                                        !reservation.booking.direction) ||
                                      (filters.direction == 'departure' &&
                                        reservation.booking.direction) ||
                                      filters.direction == '' ||
                                      filters.direction == undefined) && (
                                        <Tooltip
                                          title={t('booking.departureTicket')}
                                          placement="right-start"
                                          slotProps={{
                                            popper: {
                                              sx: {
                                                [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                                {
                                                  marginBottom: '30px',
                                                },
                                            },
                                          },
                                        }}
                                      >
                                        <Select
                                          value={passenger.reservationStatus}
                                          onChange={(event) =>
                                            handleStatusChange(
                                              event,
                                              'departureTicket',
                                              passenger
                                            )
                                          }
                                          sx={{
                                            color: getStatusColor(
                                              passenger.reservationStatus
                                            ),
                                            margin: '5px',
                                            maxWidth: '160px',
                                            minWidth: '160px',
                                            height: '30px',
                                            backgroundColor: 'white',
                                          }}
                                        >
                                          {getStatusMenuItems()}
                                        </Select>
                                      </Tooltip>
                                    )}
                                    {reservation.bookingIdReturn &&
                                      ((filters.direction == 'departure' &&
                                        reservation.isReturnTicket &&
                                        !reservation.booking.direction) ||
                                        (filters.direction == 'return' &&
                                          reservation.isReturnTicket &&
                                          reservation.booking.direction) ||
                                        filters.direction == '' ||
                                        filters.direction == undefined) && (
                                        <Tooltip
                                          title="Return ticket status"
                                          placement="right-start"
                                          slotProps={{
                                            popper: {
                                              sx: {
                                                [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                                {
                                                  marginBottom: '30px',
                                                },
                                              },
                                            },
                                          }}
                                        >
                                          <Select
                                            value={
                                              passenger.returnReservationStatus
                                            }
                                            onChange={(event) =>
                                              handleStatusChange(
                                                event,
                                                'returnTicket',
                                                passenger
                                              )
                                            }
                                            sx={{
                                              color: getStatusColor(
                                                passenger.returnReservationStatus
                                              ),
                                              margin: '5px',
                                              maxWidth: '160px',
                                              minWidth: '160px',
                                              height: '30px',
                                              backgroundColor: 'white',
                                            }}
                                          >
                                            {getStatusMenuItems()}
                                          </Select>
                                        </Tooltip>
                                      )}
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {data?.reservations.length === 0 && (
              <h1 className="notification">
                {t('reservations.noReservationsWereFound')}
              </h1>
            )}
          </>
        )}
      </div>
    </div >
  );
};

export default AllReservationsComponent;
