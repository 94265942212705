import { apiSlice } from "../../app/api/apiSlice";

export const subagentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubagents : builder.query({
        query: () => 'Subagent/GetAllSubagents',
    }),
    getSubagentById: builder.query({
        query: (subagentId) => `Subagent/GetSubagent/${subagentId}`,
    }),
    disableSubagent: builder.mutation({
        query: (id) => ({
            url: `Subagent/DisableSubagent/${id}`,
            method: 'PUT'
        }),
    }),
    createSubagent: builder.mutation({
        query: (body) => ({
            url: 'Subagent/AddSubagent',
            method: 'POST',
            body,
        }),
    }),
    updateSubagent: builder.mutation({
        query: ({body, id}) => ({
            url: `Subagent/UpdateSubagent/${id}`,
            method: 'PUT', 
            body
        })
    }),
    getSubagentsPerPage: builder.query({
        query: (body) => {
            const {currentPage, pageSize} = body;
            const queryParams = new URLSearchParams();
            queryParams.append('currentPage', currentPage);
            queryParams.append('pageSize', pageSize);
            return {
                url: `Subagent?${queryParams.toString()}`,
                method: 'GET'
            }
        }
    })
  }),
});

export const {
    useLazyGetSubagentsQuery,
    useGetSubagentsQuery,
    useGetSubagentByIdQuery,
    useDisableSubagentMutation,
    useCreateSubagentMutation,
    useUpdateSubagentMutation,
    useLazyGetSubagentsPerPageQuery
} = subagentApiSlice;