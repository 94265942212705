import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useMediaQuery } from '@mui/material';
import './GoogleMap.css';
import useGoogleMapsLoader from './GoogleMapLoader';



const GoogleMapComponent = (props) => {
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: process.env.REACT_APP_PTB_MAP_APIKEY,
  //   libraries: libs,
  //   language: 'sr-Latn',
  // });
  const isLoaded = useGoogleMapsLoader('sr-Latn');
  const matches = useMediaQuery('(max-height: 850px)');
  // const [markerPosition, setMarkerPosition] = useState(null);
  const {
    onLeftClick,
    setMap,
    onRightClick,
    showMarker,
    autoCompleteMarker,
    markerReset,
    editMarker,
    disableActions,
  } = props;
  const [center, setCenter] = useState({ lat: 43.3209, lng: 21.8954 });
  const [markerPosition, setMarkerPosition] = useState(null);

  useEffect(() => {
    if (editMarker !== undefined && isLoaded) {
      console.log("qwerweqrwqer")
      setMarkerPosition(editMarker);
      setCenter(editMarker);
    }
    console.log('marekReDIT');
    console.log(editMarker);
  }, [isLoaded]);

  useEffect(() => {
    if (showMarker && autoCompleteMarker != null) {
      console.log(autoCompleteMarker);
      setMarkerPosition(autoCompleteMarker);
      setCenter(autoCompleteMarker);
      console.log('MARKERaUTO');
    }
  }, [autoCompleteMarker]);

  useEffect(() => {
    if (editMarker === undefined) {
      setMarkerPosition(null);
      setCenter({ lat: 44.8115009, lng: 20.4579607 });
    } else {
      setMarkerPosition(editMarker);
      setCenter(editMarker);
    }
    console.log(markerReset);
  }, [markerReset]);

  const headleRightClick = (event) => {
    if (disableActions !== true) {
      handleSetNewPosition(event);
      onRightClick(event);
    }
  };

  const handleSetNewPosition = (cords) => {
    if (showMarker && disableActions != true) {
      const newPosition = { lat: cords.latLng.lat(), lng: cords.latLng.lng() };
      setMarkerPosition(newPosition);
      console.log('Marker dragged to:', newPosition);
      // setCenter(autoCompleteMarker);A
      // Log the new position
    }
  };

  const handleMarkerDragEnd = (event) => {
    console.log('1243234234');

    if (disableActions !== true) {
      handleSetNewPosition(event);
      onRightClick(event);
    }
  };

  const onLoad = useCallback(
    function callback(map) {
      setMap(map);
      // const { AdvancedMarkerElement, PinElement } =  google.maps.importLibrary("marker");
    },
    [setMap]
  );

  const onUnmount = useCallback(
    function callback(map) {
      setMap(null);
    },
    [setMap]
  );

  return (
    <>
      {isLoaded && center ? (
        <GoogleMap
          center={center}
          zoom={13}
          mapContainerClassName="google-map-container"
          options={{
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: true,
            fullscreenControl: true,
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={onLeftClick}
          onRightClick={headleRightClick}
        >
          {markerPosition && showMarker && (
            <MarkerF
              position={markerPosition}
              draggable={disableActions !== true ? true : false}
              onDragEnd={handleMarkerDragEnd}
            />
          )}
          {/* <Marker position={center}></Marker> */}
        </GoogleMap>
      ) : (
        <>Map is loading...</>
      )}
    </>
  );
};

export default GoogleMapComponent;
