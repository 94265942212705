// import { useSnackbar } from "../../components/SneckBarProvider";

export const geocodeAddress = (location, geocoder) => {
  return new Promise((resolve, reject) => {
    console.log(location.lat())
    let formattedLocation = {
      lat: Number(location.lat().toFixed(14)),
      lng: Number(location.lng().toFixed(14))
    };
    geocoder.geocode({ location: formattedLocation }, (results, status) => {
      if (status === "OK" && results[0]) {
        console.log(results[0].geometry.location.lat().toFixed(14)

        )
        const extractedData=pom(results,location)
        console.log(extractedData)
        resolve(extractedData);
      } else {
        reject(
          new Error(
            `Geocode was not successful for the following reason: ${status}`
          )
        );
      }
    });
  });
};
export const geocodeByPlaceId = (placeId, geocoder) => {
  return new Promise((resolve, reject) => {

    geocoder.geocode({ placeId: placeId }, (results, status) => {
      if (status === "OK" && results[0]) {
        const extractedData=pom(results,false)
        console.log(extractedData)
        resolve(extractedData);
      } else {
        reject(
          new Error(
            `Geocode was not successful for the following reason: ${status}`
          )
        );
      }
    });
  });
};

const pom = (results,location)=>{
  const extractedData = {};

  console.log(results[0].geometry.location.lat());
  if(!location){
    extractedData.lat=results[0].geometry.location.lat()
    extractedData.long=results[0].geometry.location.lng()
  }
  else{
    extractedData.lat=location.lat()
    extractedData.long=location.lng()
  }

  const addressComponents = results[0].address_components;
  addressComponents.forEach((component) => {
    console.log(component);
    if (
      component.types.includes("route") ||
      component.types.includes("establishment") ||
      component.types.includes("neighborhood")
    ) {
      if (extractedData.street === undefined) {
        extractedData.street = component.long_name;
      }
    }
    if (component.types.includes("street_number")) {
      extractedData.number = component.long_name;
    }
    if (
      component.types.includes("locality") ||
      component.types.includes("administrative_area_level_2") ||
      component.types.includes("administrative_area_level_1")
    ) {
      if (extractedData.city === undefined) {
        extractedData.city = component.long_name;
      }
    } else if (component.types.includes("country")) {
      extractedData.country = component.long_name;
    }
  });
  if (extractedData.street === undefined) {
    extractedData.street = "Unnamed Road";
  }
  if (extractedData.number === undefined) {
    extractedData.number = "NN";
  }
  if (extractedData.city === undefined) {
    extractedData.city = "Undefined city";
  }
  return extractedData
}

export const checkGeocodedAddres = (geocodedAddr) => {
  if (geocodedAddr.number === undefined) {
    return (
      "Invalid street number.Please choose a location that includes a street number."
    );
  } else if (geocodedAddr.city === undefined) {
    return (
      "Please choose a more specific location like a city, town, or village."
    );
  } else if (geocodedAddr.street === undefined) {
    return (
      "Invalid street name.Please choose location that include specific street."
    );
  } else if (geocodedAddr.country === undefined) {
    return (
      "Invalid country name.Please choose location that include specific country."
    );
  } else {
    return true;
  }
};
