import * as React from "react";
import CustomDialog from "../../Dialog/CustomDialog";
import EditUserForm from "./EditUserForm";
import { useTranslation } from "react-i18next";

export default function EditUserDialog(props) {
  const { onClose, open, onUpdate, formData } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };
  const hendleUpdate = () => {
    onUpdate();
    handleClose();
  };

  return (
    <>
      <CustomDialog onOpen={open} onClose={handleClose} contentTitle={t("users.editUser")}>
        <EditUserForm
          id={formData.id}
          firstName={formData.firstName}
          lastName={formData.lastName}
          phoneNumber={formData.phoneNumber}
          email={formData.email}
          userName={formData.userName}
          role={formData.role}
          onUpdate={hendleUpdate}
        ></EditUserForm>
      </CustomDialog>
    </>
  );
}
