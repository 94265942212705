import { RouteService } from "../../../services/RouteService";
import React, { useState, useEffect } from "react";
import RouteTable from "../RouteTableComponet/RouteTable";
import "./RouteComponent.css";
import { useGetRouteByIdQuery } from "../../../features/routes/routesSlice";

export default function RouteComponent({ routeId }) {
  // const { t, i18n } = useTranslation();

  const {
    data: route,
    error,
    isLoading,
    refetch: refetchGetRouteByIdQuery,
  } = useGetRouteByIdQuery(routeId);

  const onEditRoute =  (id) => {
    console.log(id);
    refetchGetRouteByIdQuery();
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error?.message || "An error occurred"}</div>;
  return (
    <>
    <div></div>
      <div className="route-component-main-container">
        {route && (
          <RouteTable
            className="route-component-table"
            heandleEdit={onEditRoute}
            routes={[route]}
          />
        )}
      </div>
    </>
  );
}
