import React from "react";
import RoutesComponent from "../components/Route/RoutesComponent/RoutesComponent";

const RoutesPage = () => {
  return (
    <>
      <RoutesComponent />
    </>
  );
};

export default RoutesPage;
