import * as React from "react";
import CustomDialog from "../Dialog/CustomDialog.js";
import AddCustomLocationForm from "./AddCustomLocationForm.jsx";
import { AddCustomLocation } from "./AddCustomLocation.jsx";
import { CustomLocationsEditor } from "./CustomLocationEditor.jsx";
import { UpdateCustomLocation } from "./UpdateCustomLocation.jsx";

export default function AddCustomLocationDialog(props) {
  const { onClose, open, location,onUpdateCustomLocationTable,locationForUpdate } = props;

  // const handleClose = () => {
  //   onClose();
  // };


  return (
    <>
      <CustomDialog onOpen={open} onClose={onClose} >
        <AddCustomLocation
          // location={location}
          onUpdateCustomLocationTable={onUpdateCustomLocationTable}
        ></AddCustomLocation>
                {/* <UpdateCustomLocation
                locationForUpdate={locationForUpdate}
          location={location}
          onUpdateCustomLocationTable={onUpdateCustomLocationTable}
        ></UpdateCustomLocation> */}
      </CustomDialog>
    </>
  );
}
