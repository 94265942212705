import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
} from '@mui/material';
import { getUserRequests } from '../../../services/UserService';
import './UserRequests.css';
// import { enableUser } from '../../../services/UserService';
import CustomButton from '../../Button/Button';
import { useEnableUserMutation } from '../../../features/users/UsersApiSlice';
import { useSnackbar } from '../../SneckBarProvider';


function UserRequestsComponent(props) {
  const [requestsList, setRequestsList] = useState([]);
  const [rowsList, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [enableUser] = useEnableUserMutation();
  //const showSnackbar=useSnackbar();
  const showSnacbar = useSnackbar();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserRequests();
        const json = await response.data;
        setRequestsList(json);

        console.log(json);

        const newRows = json.map((request) => ({
          id: request.id,
          FirstName: request.firstName,
          LastName: request.lastName,
          Email: request.email,
          PhoneNumber: request.phoneNumber,
        }));

        setRows(newRows);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedRows]);

  // const handleEnableUser = async (id) => {
  //     await enableUser(id)
  //         .then(() =>
  //             console.log('ENABLED: ' + requestsList))
  //         .catch((error) => {
  //             console.log(error.message)
  //         });

  //     setSelectedRows([]);
  //     setRequestsList([]);
  // }
  const handleEnableUser = async (id) => {
    try {
      const response = await enableUser(id).unwrap();
      showSnacbar(response?.message, 'success');
    } catch (err) {
      showSnacbar(err?.data?.Message, 'error');
    }
  };

  const handleEnableMultipleClick = (event) => {
    console.log(selectedRows);
    selectedRows.map(async (row) => {
      await enableUser(row)
        .then(() => console.log('ADDED: ' + requestsList))
        .catch((error) => {
          console.log(error.message);
        });
    });

    setSelectedRows([]);
    setRequestsList([]);
  };

  const handleCheckboxClick = (event, id) => {
    if (event.target.checked) {
      if (!selectedRows || selectedRows.length == 0) setSelectedRows([id]);
      else {
        const newRows = selectedRows.concat(id);
        setSelectedRows(newRows);
      }
    } else {
      if (selectedRows.length != 1) {
        const newRows = selectedRows.filter((element) => {
          return element !== id;
        });
        setSelectedRows(newRows);
      } else setSelectedRows([]);
    }
    console.log(selectedRows);
  };

  function RenderButton(rowId) {
    // /useEffect(()=>{}, [selectedRows])
    console.log(selectedRows);
    console.log(rowId);

    if (!selectedRows || selectedRows.length === 0) {
      return (
        <CustomButton
          type="enable-one"
          onClick={() => handleEnableUser(rowId.rowId)}
        ></CustomButton>
      );
    } else {
      return <CustomButton type="enable-more"></CustomButton>;
    }
  }

  function RenderEnableMultipleButton(selected) {
    if (!selectedRows || selectedRows.length === 0) {
      return <strong>SELECT</strong>;
    } else {
      return (
        <strong
          onClick={(event) => handleEnableMultipleClick(event)}
          className="users-enable-button"
        >
          ENABLE SELECTED
        </strong>
      );
    }
  }

  return (
    <Card className="user-requests-global-container">
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead id="head" sx={{ backgroundColor: 'ButtonShadow' }}>
            <TableRow id="rows">
              <TableCell id="checkbox" sx={{ width: '100px', height: '50px' }}>
                <RenderEnableMultipleButton selected={selectedRows} />
              </TableCell>
              <TableCell id="email">
                <strong>EMAIL</strong>
              </TableCell>
              <TableCell id="firstname">
                {' '}
                <strong>FIRST NAME</strong>
              </TableCell>
              <TableCell id="lastname">
                {' '}
                <strong>LAST NAME</strong>
              </TableCell>
              <TableCell id="phone">
                <strong>PHONE NUMBER</strong>
              </TableCell>
              <TableCell id="enable">
                <strong>ENABLE</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="body">
            {rowsList.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  height: '70px',
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    // name="enableCheck"
                    color="error"
                    onChange={(event) => handleCheckboxClick(event, row.id)}
                    checked={
                      selectedRows
                        ? selectedRows.find((el) => el == row.id)
                        : false
                    }
                  />
                </TableCell>
                <TableCell>{row.Email}</TableCell>
                <TableCell>{row.FirstName}</TableCell>
                <TableCell>{row.LastName}</TableCell>
                <TableCell>{row.PhoneNumber}</TableCell>
                <TableCell>
                  <RenderButton rowId={row.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default UserRequestsComponent;
