import { apiSlice } from "../../app/api/apiSlice"

export const exportApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createExcelExport: builder.mutation({
            //query: (body) => ({
            query: ({ bookings, lang }) => ({
                //url: 'Export/export-booking-excel?fileName=test&pageSize=10&pageNumber=1&bookingType=all',
                url: `Export/export-booking-excel/${lang}`,
                method: 'POST',
                body: bookings,
                responseType: 'blob',
                responseHandler: response => response.blob()
            }),
        }),
        createPdfExport: builder.mutation({
            query: (body) => ({
                url: 'Export/pdf-booking?fileName=test&pageSize=10&pageNumber=1&bookingType=all',
                method: 'POST',
                body: body,
                responseType: 'blob',
                responseHandler: response => response.blob()
            }),

        }),
        createReservationExcelExport: builder.mutation({
            query: ({ reservations, lang, direction,swapDirection }) => ({
                url: `Export/export-reservation-excel/${lang}/${direction}/${swapDirection}`,  // Include lang as part of the URL
                method: 'POST',
                body: reservations,  // Assuming reservations is the body data
                responseType: 'blob',
                responseHandler: response => response.blob()
            }),
        }),
        createReservationPdfExport: builder.mutation({
            query: (body) => ({
                url: 'Export/pdf-reservation',
                method: 'POST',
                body: body,
                responseType: 'blob',
                responseHandler: response => response.blob()
            }),

        }),
        createReservationTickets: builder.mutation({
            query: (body) => ({
                url: 'Export/excel-tickets',
                method: 'POST',
                body: body,
                responseType: 'blob',
                responseHandler: response => response.blob()
            }),

        }),
    })
})

export const {
    // useGetExcelExportQuery,useGetPdfExportQuery,
    useCreateExcelExportMutation, useCreatePdfExportMutation, useCreateReservationExcelExportMutation, useCreateReservationPdfExportMutation, useCreateReservationTicketsMutation
} = exportApiSlice