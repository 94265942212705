import * as React from "react";
import CustomDialog from "./CustomDialog";
import "./ConfirmationDialog.css";
import { useTranslation } from "react-i18next";
import CustomButton from "../Button/Button";
import { Typography } from "@mui/material";
import { ImportantDevices } from "@mui/icons-material";

export default function ComfirmationDialog(props) {
  const { onClose, open, dialogContent, onNo, onYes } = props;
  const { t } = useTranslation();

  var translatedContent;
  translatedContent = t(`confirmation-dialog.${dialogContent}`);
  // console.log(translatedContent);
  if (
    translatedContent === `confirmation-dialog.${dialogContent}` ||
    !translatedContent
  ) {
    translatedContent = dialogContent;
  }

  const handleClose = () => {
    onClose();
  };
  const handleOnYesClick = () => {
    onYes();
  };
  const handleOnNoClick = () => {
    onNo();
  };

  return (
    <>
      {/* <div sx={{ zIndex: 110 }}> */}
        <CustomDialog
          onOpen={open}
          onClose={handleClose}
          content={
            <Typography component="div">
              <div style={{ textAlign: "center", maxWidth: "500px" }}>
                {translatedContent}
              </div>
            </Typography>
          }
        >
          <div className="confirmation-dialog-button-cotainer">
            <CustomButton onClick={handleOnYesClick}>
              {t("main.yes")}
            </CustomButton>
            <CustomButton onClick={handleOnNoClick}>
              {t("main.no")}
            </CustomButton>
          </div>
        </CustomDialog>
      {/* </div> */}
    </>
  );
}
