import { useJsApiLoader } from '@react-google-maps/api';
const libs = ['places', 'routes', 'drawing', 'geometry'];

const useGoogleMapsLoader = (language) => {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_PTB_MAP_APIKEY,
    libraries: libs,
    language: language,
  });
  
  return isLoaded;
};

export default useGoogleMapsLoader;