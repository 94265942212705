import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "../../config";
import { logOut, setCredentials } from "../../features/auth/authSlice";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem('token')

        //const token = getState().auth.token;
        //console.log("line 10: " + token)
        if (token) {
            headers.set("Authorization", `Bearer ${token}`)
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    //console.log(result);

    if (result?.error?.status === 401) {

        console.log('sending refresh token')
        //console.log(api);
        //console.log("between",api.getState())

        const tok = localStorage.getItem('token');
       // console.log(tok);
        
        // send refresh token to get new access token 
        const refreshResult = await
            baseQuery(
              {
                url: `Authentification/Refresh-access-token`,
                method: 'POST',
                body: ({
                    accessToken: tok,
                })
              },
              api,
              extraOptions
            );
        
        if (refreshResult?.data) {
            const newAccessToken = refreshResult.data.accessToken; 
            console.log("storing new access token")
            // store the new token 
            api.dispatch(setCredentials({ id:localStorage.getItem('id'), roles: localStorage.getItem('roles') , token: newAccessToken })) //...refreshResult.data //localStorage.getItem('accessToken')
            // retry the original query with new access token 
            result = await baseQuery(args, api, extraOptions)
        } else if (refreshResult?.error?.status === 500) {
            window.location.href = '/';
            api.dispatch(logOut());
            return refreshResult;//refreshResult?.error ? refreshResult : result;

        } else {
            window.location.href = '/';
            api.dispatch(logOut());
            return refreshResult;//refreshResult?.error ? refreshResult : result;
        }

    }
    return result;
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})