import React, { useState } from "react";
import "./EditUser.css";
import { useSnackbar } from "../../SneckBarProvider";
import { useEditUserMutation } from "../../../features/users/UsersApiSlice";
import { useTranslation } from "react-i18next";

import NameTextField from "../../CustomTextFields/NameTextField";
import PhoneTextField from "../../CustomTextFields/PhoneTextField";
import UserNameTextField from "../../CustomTextFields/UserNameTextField";
import ButtonValidate from "../../Button/ButtonValidate";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ComfirmationDialog from "../../Dialog/ConfirmationDialog";
import WhiteButton from "../../Button/WhiteButton";
import FilledButton from "../../Button/FilledButton";
import CustomDropDown from "../../CustomTextFields/CustomDropDown";
import { roles } from "../../../config";
function EditUserForm(props) {
  const showSnackbar = useSnackbar();
  const [editUser] = useEditUserMutation();
  const { t } = useTranslation();
  const [reset,setReset]=useState(false);
  const [open, setOpen] = useState(false);
  const loggedUserRole = localStorage.getItem('roles');

  const { onUpdate } = props;
  const [userData, setUserData] = useState({
    id: props.id,
    firstName: props.firstName,
    lastName: props.lastName,
    phoneNumber: props.phoneNumber,
    userName: props.userName,
    role:props.role
  });
  const [fieldValidities, setFieldValidities] = useState({
    // firstName: false,
    // lastName: false,
    userName: false,
  });

  const handleChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(userData);
  };
  const handlePhoneNumberChange = (phone) => {
    console.log(userData);
    setUserData((prevData) => ({
      ...prevData,
      phoneNumber: phone, // Update the phone number directly
    }));
  };
  const handleFieldValidityChange = (fieldName, isValid) => {
    setFieldValidities((prevValidities) => ({
      ...prevValidities,
      [fieldName]: isValid,
    }));
    // console.log(fieldValidities)
  };
  const handleUpdateUserClick = async () => {
    try {
      const response = await editUser(userData).unwrap();
      console.log(response);
      showSnackbar(response?.message, "success");
      onUpdate();
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };
  const handleOpenDialg = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleYesClick = () => {
    handleUpdateUserClick();
    setOpen(false);
  };
  const handleReset=()=>{
    setUserData({
      id: props.id,
      firstName: props.firstName,
      lastName: props.lastName,
      phoneNumber: props.phoneNumber,
      userName: props.userName,
    })
    setReset(!reset);
  }
  const handleInputChange = (e) => {
    console.log(e.target.name);
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <>
      <div className="update-user-form-container">
        {/* <form className="update-user-form-container"> */}
        <NameTextField
          label="First Name"
          name="firstName"
          nameValue={userData.firstName}
          onNameChange={handleChange}
          // onValidityChange={handleFieldValidityChange}
        />
        <NameTextField
          label="Last Name"
          name="lastName"
          nameValue={userData.lastName}
          onNameChange={handleChange}
          // onValidityChange={handleFieldValidityChange}
        />
        <UserNameTextField
          label="User Name"
          name="userName"
          userName={userData.userName}
          onNameChange={handleChange}
          onValidityChange={handleFieldValidityChange}
          reset={reset}
        />
        {loggedUserRole==="Admin" &&
        <CustomDropDown
            name="role"
            id="role"
            value={userData.role}
            onChange={handleInputChange}
            label={t('users.filterRole')}
            options={roles}
          />
        }
        <PhoneTextField
          name="phoneNumber"
          value={userData.phoneNumber}
          onPhoneChange={handlePhoneNumberChange}
        />

        <ButtonValidate
          onClick={handleOpenDialg}
          fieldValidities={fieldValidities}
        >
          <SaveOutlinedIcon /> {t("main.save")}
        </ButtonValidate>
        <div  style={{display:"none", width:"100%",gap:"16px",justifyContent:"flex-end",paddingTop:"24px", paddingBottom:"32px"}} className="change-password-buttons-container">
          <WhiteButton onClick={handleReset}>{t("main.cancel")}</WhiteButton>
          <FilledButton
            onClick={handleOpenDialg}
            isValid={
              fieldValidities.userName
            }
          >
            {t("main.saveChanges")}
          </FilledButton>
        </div>
        {/* </form> */}
        {open && (
          <ComfirmationDialog
            // sx={{ zIndex: "1000000" }}
            // style={{zIndex:1500}}
            open={open}
            onClose={handleCloseDialog}
            dialogContent={"Are you sure you want to update this information?"}
            onNo={handleCloseDialog}
            onYes={handleYesClick}
          />
        )}
      </div>
    </>
  );
}

export default EditUserForm;
