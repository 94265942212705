import React, { useRef, useEffect, useState } from "react";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import "./TextAreaWithAutoComplete.css";
import { geocodeByPlaceId } from "../../utils/googleMapsHelper/geocode";
import { useSnackbar } from "../SneckBarProvider";
import { checkGeocodedAddres } from "../../utils/googleMapsHelper/geocode";
import { isEditable } from "@testing-library/user-event/dist/utils";
import { useTranslation } from "react-i18next";


const libs = ["places", "routes", "drawing", "geometry"];

const AutocompleteTextarea = (props) => {
  const { onRouteStopsChange, reesetChange, stops } = props;
  const [autocomplete, setAutocomplete] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [curentLocations, setCurentLocations] = useState([]);
  const [geocoder, setGeocoder] = useState(null);

  const [oldTextAreaValue, setOldTextAreaValue] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [routeStops, setRouteStops] = useState([]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_PTB_MAP_APIKEY,
    libraries: libs,
    language: "sr-Latn",
  });
  const { t } = useTranslation();

  //const showSnackbar = useSnackbar();
  const showSnacbar = useSnackbar();
  useEffect(() => {
    if (isLoaded) {
      const autocompleteInstance =
        new window.google.maps.places.AutocompleteService();
      setGeocoder(new window.google.maps.Geocoder());
      setAutocomplete(autocompleteInstance);
      if (stops != undefined) {
        setLocationsAndStopsForUpdate();
      }
    }
  }, [isLoaded]);

  const setLocationsAndStopsForUpdate = () => {
    const newLocations = stops.map(
      (stop) => `${stop.location.country}, ${stop.location.city} (${stop.location.street} ${stop.location.number}); `
    );

    // const locations = stops.map((stop) => stop.location);

    setRouteStops(stops);
    setCurentLocations(newLocations);
    // console.log(stops);
    console.log("fasdgdjsfkdgfjdkfjk");
  };

  const countSemicolons = (str, length) => {
    let count = 0;
    for (let i = 0; i < length; i++) {
      if (str[i] === ";") {
        count++;
      }
    }
    return count;
  };
  const deletedFromIndex = (newString) => {
    let diffIndex = -1;
    for (let i = 0; i < oldTextAreaValue.length; i++) {
      if (oldTextAreaValue[i] !== newString[i]) {
        diffIndex = i;
        break;
      }
    }
    return diffIndex;
  };

  const handleChange = (event) => {
    const newTextAreaValue = event.target.value;

    setTextAreaValue(event.target.value);
    var stringsDifference = oldTextAreaValue.length - newTextAreaValue.length;
    if (oldTextAreaValue.length < newTextAreaValue.length) {
      var autocompleteString = newTextAreaValue.slice(stringsDifference);
    } else if (oldTextAreaValue.length > newTextAreaValue.length) {
      handleDeleteFromTextArea(newTextAreaValue);
    }
    getAutocompletePredictions(newTextAreaValue, autocompleteString);
  };

  const handleDeleteFromTextArea = (newTextAreaValue) => {
    var newLocationsArr = [...curentLocations];
    var newRouteStopsArr = [...routeStops];

    let diffIndex = deletedFromIndex(newTextAreaValue);

    const deletedLength = oldTextAreaValue.length - newTextAreaValue.length;
    const deletedText = oldTextAreaValue.substr(diffIndex, deletedLength);

    let indicesToRemove = [];

    if (countSemicolons(deletedText, deletedText.length) > 0) {
      let semicolonsCouter = countSemicolons(deletedText, deletedText.length);
      let wordsIndex = countSemicolons(newTextAreaValue, diffIndex);

      while (semicolonsCouter != 0) {
        indicesToRemove.push(wordsIndex);
        semicolonsCouter--;
        wordsIndex++;
      }
    } else {
      if (
        oldTextAreaValue[diffIndex] === " " &&
        oldTextAreaValue[diffIndex - 1] === ";"
      ) {
        diffIndex = diffIndex - 1;
      }
      let wordsIndex = countSemicolons(newTextAreaValue, diffIndex);
      console.log(wordsIndex);
      indicesToRemove.push(wordsIndex);
    }

    for (let i = indicesToRemove.length - 1; i >= 0; i--) {
      const index = indicesToRemove[i];
      newLocationsArr.splice(index, 1);
      newRouteStopsArr.splice(index, 1);
    }

    setCurentLocations(newLocationsArr);
    setRouteStops(newRouteStopsArr);
  };

  const getAutocompletePredictions = (newTextAreaValue, autocompleteString) => {
    if (autocomplete && newTextAreaValue) {
      autocomplete.getPlacePredictions(
        { input: autocompleteString, types: ["establishment", "geocode"] },
        (predictions, status) => {
          if (status === "OK") {
            const filteredPredictions = predictions.filter(
              (prediction) =>
                !curentLocations.some((location) =>
                  location.includes(prediction.description)
                )
            );
            setPredictions(filteredPredictions);
          } else {
            setPredictions([]);
          }
        }
      );
    } else {
      setPredictions([]);
    }
  };

  useEffect(() => {
    let str = "";
    console.log(curentLocations);
    curentLocations.forEach((location) => {
      str = str + location;
    });
    setOldTextAreaValue(str);
    setTextAreaValue(str);
  }, [curentLocations]);

  const handlePredictionClick = (prediction) => {
    geocodeByPlaceId(prediction.place_id, geocoder).then((res) => {
      const checkResult = checkGeocodedAddres(res);
      if (checkResult === true) {
        console.log(res);
        setRouteStops((prevItems) => [...prevItems, res]);
        setCurentLocations((prevItems) => [
          ...prevItems,
          prediction.description + "; ",
        ]);
      } else {
        showSnacbar(checkResult, "error");
      }
    });
    setPredictions([]);
  };

  useEffect(() => {
    console.log(routeStops);
    onRouteStopsChange(routeStops);
  }, [routeStops]);

  useEffect(() => {
    if (stops) {
      setLocationsAndStopsForUpdate();
    } else {
      setCurentLocations([]);
      setRouteStops([]);
      setPredictions([]);
    }
  }, [reesetChange]);

  return (
    <>
      <div style={{ position: "relative" }}>
        <textarea
          name="googleTextAreaAutoComlete"
          placeholder={t("add-route.e.g., BAS; Autokomanda; Novi Sad; Ljubljana Bus Station;")}
          onChange={handleChange}
          value={textAreaValue}
        />
        {predictions.length > 0 && (
          <ul className="text-area-with-auto-complete-ul">
            {predictions.map((prediction) => (
              <li
                className="text-area-with-auto-complete-li"
                key={prediction.place_id}
                onClick={() => handlePredictionClick(prediction)}
              >
                {prediction.description}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default AutocompleteTextarea;
