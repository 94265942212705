export const getRouteLength = (routes) => {
    var length  =0;
    const legs = routes.legs;

    legs.forEach((leg) => {
      length += leg.distance.value;
    });
    console.log("distanceeee", length / 1000);

    return length / 1000;
  };