import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateSubagentMutation } from '../../../features/subagents/SubagentSlice';
import { Box, Button, TextField } from '@mui/material';
import './SubagentEditForm.css';


export default function EditSubagent({
    onSuccess,
    getSubagentsAfterEdit,
    subagentId,
    selectedSubagent
}) {
    const [subagentName, setSubagentName] = useState(selectedSubagent);
    const [errors, setErrors] = useState({});
    const [t, i18n] = useTranslation();
    const [updateSubagent, { isSuccess, isLoading, isError, error}] =
        useUpdateSubagentMutation();

    const handleInputChange = (event) => {
        setSubagentName(event.target.value);
    }
    const validate = () => {
        const errors = {};
        if(!subagentName.trim()){
            errors.subagentName = t('subagent.requiredSubagent');
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    }
    const handleSubmit =  async (event) => 
    {
        event.preventDefault();
        if (!validate()) return;
        try {
            const newSubagent = { name: subagentName };
            await updateSubagent({body: newSubagent, id: subagentId}).unwrap();
            if (onSuccess) {
                onSuccess();
            }
            if (getSubagentsAfterEdit) {
                getSubagentsAfterEdit();
            }
            
        } catch (err) {
            console.error('Failed to create subagent: ', err);
        }
        setSubagentName('');
    }
    return (
        <Box component="form" onSubmit={handleSubmit}>
            <TextField
                id="subagent-name"
                label={t('subagent.name')}
                variant="outlined"
                value={subagentName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={!!errors.subagentName}
                helperText={errors.subagentName}
            />
            <Button type="submit" className='edit-button'>
                {isLoading
                    ? t('subagent.creating')
                    : t('subagent.editSubagent')}
            </Button>
            {isError && (
                <p>
                    {t('subagent.errorUpdating')}: {error.toString()}
                </p>
            )}
        </Box>
    );
}
