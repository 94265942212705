export const CheckUserAuth = () => {
    const role = localStorage.getItem("roles");
    if (role === "Admin" || role==="Operater") {
      return true;
    } else {
      return false;
    }
  };
  export const AdminCheck = () => {
    const role = localStorage.getItem("roles");
    if (role === "Admin") {
      return true;
    } else {
      return false;
    }
  };