import React from 'react'
import RegisterForm from '../components/Register/RegisterForm'
import SubagentTable from '../components/Subagents/SubagentTable/SubagentTable'

const SubagentPage = () => {
  return (
  <SubagentTable/>
  )
}

export default SubagentPage