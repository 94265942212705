import * as React from "react";
import CustomDialog from "../../Dialog/CustomDialog";
import RegisterForm from "../../Register/RegisterForm";
import SendRegistrationInvitationForm from "./InviteUserForm";
import AddUserForm from "./AddUserForm";

export default function InviteUser(props) {
  const { onClose, open,handleAddedUser } = props;

  const handleClose = () => {
    onClose();
  };


  return (
    <>
      <CustomDialog
        onOpen={open}
        onClose={handleClose}
        contentTitle="INVITE USER"
      >
        <SendRegistrationInvitationForm onClose={onClose}></SendRegistrationInvitationForm>
      </CustomDialog>
    </>
  );
}
