import React, { useState, useEffect } from 'react';
import './CustomButton.css';
const WhiteButton = (props) => {
  const { children, onClick } = props;

  return (
    <>
      <button className="white-custom-button" onClick={onClick}>
        {children}
      </button>
    </>
  );
};

export default WhiteButton;
