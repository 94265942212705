import { apiSlice } from "../../app/api/apiSlice";

export const paymentMethodApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentMethods : builder.query({
        query: () => 'PaymentMethod/GetPaymentMethods',
    }),
    getPaymentMethodById: builder.query({
        query: (id) => `PaymentMethod/GetPaymentMethod/${id}`,
    }),
    disablePaymentMethod: builder.mutation({
        query: (id) => ({
            url: `PaymentMethod/DisablePaymentMethod/${id}`,
            method: 'PUT'
        }),
    }),
    createPaymentMethod: builder.mutation({
        query: (body) => ({
            url: 'PaymentMethod/CreatePaymentMethod',
            method: 'POST',
            body,
        }),
    }),
    updatePaymentMethod: builder.mutation({
        query: ({ body, id }) => ({
            url: `PaymentMethod/UpdatePaymentMethod/${id}`,
            method: 'PUT',
            body
        })
    }),

    getPaymentMethodsPerPage: builder.query ({
        query: (body) => {
            const {currentPage, pageSize } = body;
            const queryParams = new URLSearchParams();  
            queryParams.append('currentPage', currentPage);
            queryParams.append('pageSize', pageSize);
            return {
                url: `PaymentMethod/GetPerPage?${queryParams.toString()}`,
                method: 'GET'
            }
        }
    })
  }),
});

export const {
    useLazyGetPaymentMethodsQuery,
    useGetPaymentMethodsQuery,
    useGetPaymentMethodByIdQuery,
    useDisablePaymentMethodMutation,
    useCreatePaymentMethodMutation,
    useUpdatePaymentMethodMutation,
    useLazyGetPaymentMethodsPerPageQuery
} = paymentMethodApiSlice;