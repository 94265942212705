import * as React from "react";
import CustomDialog from "../../Dialog/CustomDialog";
import { useTranslation } from 'react-i18next';
import ResetPasswordForm from "./ResetPasswordForm";

export default function ResetPasswordDialog(props) {
  const { t } = useTranslation();
  const { onClose, open, id } = props;

  // const handleClose = () => {
  //   onClose();
  // };

  return (
    <>
      <CustomDialog
        onOpen={open}
        onClose={onClose}
        contentTitle={t('users.resetPassword').toLocaleUpperCase()}
      >
       <ResetPasswordForm id={id} onClose={onClose}/>
      </CustomDialog>
    </>
  );
}
