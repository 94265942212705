import React, { useState, useEffect } from 'react';
import './CustomButton.css';
import FilledButton from './FilledButton';
import { useSendSMSMutation } from '../../features/sms/smsSlice';
import { useSnackbar } from '../SneckBarProvider';

import { useTranslation } from 'react-i18next';
import { useSignalRConection } from '../../store/context/SignalRContext';
import { CircleNotifications } from '@mui/icons-material';
import CustomDialog from '../Dialog/CustomDialog';
import SMSStatuses from '../Sms/SmsStatuses';
import { faL } from '@fortawesome/free-solid-svg-icons';
import ComfirmationDialog from '../Dialog/ConfirmationDialog';
import { addConnection } from '../../services/SignalRService';

const SMSButton = (props) => {
  const userId = localStorage.getItem('id');

  const { onClick, reservations } = props;
  const connection = useSignalRConection();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [openComfDialog, setOpenComfDialog] = useState();

  const { t } = useTranslation();
  const [sendSMS] = useSendSMSMutation();
  const showSnacbar = useSnackbar();

  console.log(reservations);

  useEffect(() => {
    if (connection) {
      console.log('on');

      connection.on('ConnectionAdded', (response) => {
        console.log(response);
      });
      connection.on('BroadcastSMSResponses', (smsResponses) => {
        console.log(`Received data from the server`, smsResponses);
        setOpen(true);
        const isNull=isSomeDataNull(smsResponses)
        if(!isNull){
          setData(smsResponses);
        }
        else{
          setData();
        }
      });
    }
    
    return () => {
      if (connection) {
        console.log('off');
        connection.off('ConnectionAdded');
        connection.off('BroadcastSMSResponses');
      }
    };
  }, [connection]);

  const isSomeDataNull=(smsResponses)=>{
    const hasNull = smsResponses.some((response) => response === null);
    if (hasNull) {
      showSnacbar('Error with your device,or you dont have sim card!', 'error');
    }
    return hasNull;
  }

  const handleSendSMSClick = async () => {
    if (reservations?.length > 0) {
      setOpenComfDialog(false);
      await addConnection(userId, connection);
      await handleSendSMS();
    }
  };
  const handleSendSMS = async () => {
    try {
      console.log(reservations);
      const response = await sendSMS(reservations);
      console.log(response);
      if (!response.error) {
        showSnacbar(response?.data?.message, 'info');
      } else {
        showSnacbar(response?.error.data?.Message, 'error');
      }

      //   showSnacbar(response,'success')
    } catch (error) {
      console.error('Error:', error);
      showSnacbar(error?.data?.Message, 'error');
    }
  };
  const handleOpenComfDialog = () => {
    setOpenComfDialog(true);
  };
  const sx = {
    minWidth: '160px',
  };


  return (
    <>
      <FilledButton
        style={sx}
        isValid={reservations.length > 0}
        onClick={handleOpenComfDialog}
      >
        <CircleNotifications /> {t('reservations.notify')}
      </FilledButton>
      {open && data && (
        <CustomDialog
          onOpen={open}
          onClose={() => {
            setOpen(false);
          }}
          customMargin="0px"
        >
          <SMSStatuses responses={data} />
        </CustomDialog>
      )}
      {openComfDialog && (
        <ComfirmationDialog
          open={openComfDialog}
          onClose={() => setOpenComfDialog(false)}
          dialogContent={t('sendSMSs')}
          onNo={() => setOpenComfDialog(false)}
          onYes={handleSendSMSClick}
        />
      )}
    </>
  );
};

export default SMSButton;
