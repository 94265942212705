import { createContext, useContext, useEffect, useState } from 'react';
import { BASE_API_URL, groupName } from '../../config';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { addConnection } from '../../services/SignalRService';

const SignalRContext = createContext(null);

export const SignalRProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);
  const getBaseUrl = () => {
    const removePart = '/api/'; // Part to remove
    return BASE_API_URL.includes(removePart)
      ? BASE_API_URL.replace(removePart, '')
      : BASE_API_URL;
  };
  useEffect(() => {
    const startConnection = async () => {
      try {
        const conn = new HubConnectionBuilder()
          .withUrl(`${getBaseUrl()}/SMSHub`)
          .configureLogging(LogLevel.Information)
          .build();

        console.log('Connection building...');

        conn.onclose(() => {
          console.log('Connection closed. Attempting to reconnect...');
        });

        conn.onreconnected(() => {
          console.log('Connection reestablished.');
          // Optionally re-register event listeners here
        });

        conn.onreconnecting(() => {
          console.log('Reconnecting...');
        });

        // conn.on('ConnectionAdded', (response) => {
        //   console.log(response);
        // });

        await conn.start();
        console.log('Connection started');
        // console.log(userId);
        // await addConnection(userId,conn);

        setConnection(conn);

        return conn;
      } catch (e) {
        console.error(e);
      }
    };

    startConnection();

    return () => {
      if (connection) {
        connection.off('ConnectionAdded');
        connection.off('SendSMSResponses');
        connection.stop();
      }
    };
  }, []);

  return (
    <SignalRContext.Provider value={connection}>
      {children}
    </SignalRContext.Provider>
  );
};

export const useSignalRConection = () => {
  return useContext(SignalRContext);
};
