import React from "react";
import "./Error404.css";

const Error404Component = () => {
  return (
    <div className="eror404-main-container">
      <div className="eror404-content-container">
        <div className="eror404-photo-container"></div>
        <div></div>
        <div className="error404-header-button-conteiner">
          <h2>Oppsss ... Ova stranica ne postoji</h2>
          {/* <CustomButton>Vrati se</CustomButton> */}
        </div>
      </div>
    </div>
  );
};

export default Error404Component;
