import React from 'react';
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

import "react-phone-input-2/lib/material.css";
import { useTranslation } from "react-i18next";

const PhoneTextField = (props) => {
  const { name, value, onPhoneChange } = props;
  const { t } = useTranslation();
  
  // console.log(PhoneInput
  // );
  return (
    <div>
      <label className="custom-label">{t("main.phone")}</label>
      <PhoneInput
        enableSearch={true}
        country={"rs"}
        name={name}
        value={value}
        className="phone-s"
        // specialLabel={t("main.phone")}
        onChange={onPhoneChange}
        inputStyle={{
          width: "100%",
          // borderColor: "#D0D5DD",
          color: "#667085",
        }}
      />
      </div>

  );
};

export default PhoneTextField;
