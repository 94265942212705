import React, { useEffect, useState } from 'react';
import RouteTable from '../RouteTableComponet/RouteTable';
import RouteFillters from '../FilterRouteComponent/RouteFilterComponet';
import Pagionation from '@mui/material/Pagination';
import './RoutesComponent.css';
import { useLazyGetFillteredRoutesPerPageQuery } from '../../../features/routes/routesSlice';
import CustomButton from '../../Button/Button';
import DialogAddRouteAutoComplete from '../AddRouteComponent/DialogAddRouteAutoComplete';
import { CircularProgress, Box,Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { enableble } from '../../../config';
import FilledButton from '../../Button/FilledButton';
import { ReactComponent as PlusIcon } from '../../../Icons/PlusIcon.svg';
import UsePagination from '../../Pagginator/Paginator';
import { newTextFieldStyle } from '../../../customStyles/customStyles';
import { SignalCellularNull } from '@mui/icons-material';

export default function RoutesComponent() {
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const [dialog, setDialogToBeOppened] = useState(null);
  const [resetState, setResetPaginator] = useState(false);

  const [formData, setFormData] = React.useState({
    startCityName: '',
    endCityName: '',
    startCountryName: '',
    endCountryName: '',
    routeTitle: '',
    isEnabled: enableble[1],
    lowerPrice: 0,
    upperPrice: 0,
    pageNumber: 1,
    pageSize: 10,
  });
  const heandleOpenDialog = (value) => {
    setDialogToBeOppened(value);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogToBeOppened(null);
    setOpen(false);
  };
  const [getFilteredRoutes, { data: routes, isLoading }] =
    useLazyGetFillteredRoutesPerPageQuery(formData);

  const onUpdateRoute = () => {
    getFilteredRoutes(formData);
  };

  const onAddNewRoute = () => {
    setFormData((prevData) => ({
      ...prevData,
      pageNumber: 1,
    }));
    setResetPaginator(!resetState);
    handleCloseDialog();
  };
  const heandleAdd = () => {
    heandleOpenDialog('addRoute');
  };

  const handlePageChange = (value) => {
    console.log(value);
    setFormData({
      ...formData,
      pageNumber: value,
    });
  };

  const filterRoutes = (filterData) => {
    console.log(filterData);
    setFormData((prevData) => ({
      ...prevData,
      pageNumber: 1,
      ...filterData,
    }));
    setResetPaginator(!resetState);
  };

  useEffect(() => {
    getFilteredRoutes(formData);
  }, [formData]);

  const resetFilters = () => {
    setFormData({
      startCityName: '',
      endCityName: '',
      startCountryName: '',
      endCountryName: '',
      routeTitle: '',
      isEnabled: enableble[1],
      lowerPrice: 0,
      upperPrice: 0,
      pageNumber: 1,
      pageSize: 9,
    });
    setResetPaginator(!resetState);
  };

  useEffect(() => {
    console.log(routes);
  }, [routes]);

  return (
    <>
       {isLoading ?  
      (
       <Box
           display="flex"
           justifyContent="center"
           alignItems="center"
           height="90vh"
           flexDirection="column"
       >
           <CircularProgress />
           <Typography variant="h6" marginTop={2}>
            {t('main.loading')}...
           </Typography>
       </Box>
   ) : (
      <div className="show-routes-main-container">
        <div className="routes-component-content-container">
          <div className="show-routes-button-header-container">
            <span className="page-header-container">
              {t('route-list.routes')}
            </span>
            <FilledButton isValid={true} onClick={heandleAdd} type="submit">
              <PlusIcon></PlusIcon>
              {t('route-list.newRoute')}
            </FilledButton>
          </div>
          <div className="show-routes-filters-container">
            <RouteFillters
              formData={formData}
              enableble={enableble}
              filterRoutes={filterRoutes}
              resetFilters={resetFilters}
              // onReceiveFilteredRoutes={hendleFilterRoutes}
              // onButtonClick={filterRoutes}
            />
          </div>
          <div className="show-routes-table-container">
            {routes && (
              <>
                <RouteTable
                  heandleEdit={onUpdateRoute}
                  routes={routes.routes}
                />
                {routes.routes.length === 0 && (
                  <span className="routes-component-noroutesfound-message">
                    {t('route-list.routeNotFoutMessage')}
                  </span>
                )}
                <UsePagination
                  onPageChange={handlePageChange}
                  totalPages={routes.totalPages}
                  page={formData.pageNumber}
                  reset={resetState}
                ></UsePagination>
              </>
            )}
          </div>

          {dialog === 'addRoute' && open && (
            // <AddRouteComponnet onAddNewRoute={onRoutesChange} onClose={handleCloseDialog} open={open} />
            <DialogAddRouteAutoComplete
              onAddNewRoute={onAddNewRoute}
              // onRouteUpdate={onRouteUpdate}
              onClose={handleCloseDialog}
              open={open}
            />
          )}
        </div>
        {/* <div className="show-routes-component-paginator-container">
          {routes && (
            <Pagionation
              count={routes.totalPages}
              page={routes.page}
              onChange={handleChangePage}
              // color="secondary"
            />
          )}
        </div> */}
      </div>
      )}
      {/* {routes && (
        // <DisplayRouteComponent origin={routes.routes[2].startLocation} destination={routes.routes[2].endLocation} routeStops={routes.routes[2].routeStops.slice(2)}></DisplayRouteComponent>
      )} */}
    </>
  );
}
