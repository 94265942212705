import React, { useEffect, useState } from 'react';

import SMSStatus from './SmsStauts';
import './SMS.css';
import { text } from '@fortawesome/fontawesome-svg-core';
const smsData={
  reservationId:"423423",
  phoneNumber:"+381640051517",
  dateTime:"27. 5. 2004.",
  text:'Postovani cao Postovani cao Postovani caoc Postovani cao Postovani cao Postovani cao Postovani cao Postovani cao '
}
//TODO SHOULD CHANGE FILE NAME
const SMSStatuses = ({responses}) => {

  return (
    <>
      <div className="sms-statuses-main-container">
        {responses && responses.length > 0 ? (
          responses.map((smsData, index) => (
            <SMSStatus key={index} smsData={smsData} />
          ))
        ) : (
          <p>No SMS statuses available.</p>
        )}
        {/* <SMSStatus smsData={smsData} /> */}
               {/* {responses && responses.length > 0 ? (
          responses.map((smsData, index) => (
            <SMSStatus key={index} smsData={smsData} />
          ))
        ) : (
          <p>No SMS statuses available.</p>
        )} */}
      </div>
    </>
  );
};

export default SMSStatuses;
