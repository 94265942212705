import { useState, useEffect } from 'react';

// import './ConfirmationDialog.css';
import { useTranslation } from 'react-i18next';
import CustomButton from '../Button/Button';
import { Typography } from '@mui/material';
import { ImportantDevices } from '@mui/icons-material';
import GoogleMapComponent from './GoogleMap';
import CustomDialog from '../Dialog/CustomDialog';

export default function GoogleMapDialog(props) {
  const { onClose, open, location,onRightClick, onNo, onYes } = props;
  const { t } = useTranslation();
  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);

  const handleClose = () => {
    onClose();
  };
  const handleRightClick = (event) => {
    onRightClick(event)
  };
  const handleOnNoClick = () => {
    // onNo();
  };

  useEffect(() => {
    console.log(location)
    // setMarkerPosition({ lat: location.lat, lng: location.long });
  }, [location]);

  return (
    <>
      <CustomDialog
        onOpen={open}
        onClose={handleClose}
        customMargin="0px"
      >
        {open && location  &&(
          <GoogleMapComponent 
            setMap={setMap}
            showMarker={true}
            autoCompleteMarker={{ lat: location.lat, lng: location.long }}
            onRightClick={handleRightClick}
            // disableActions={true}
        
          />
        )}
      </CustomDialog>
    </>
  );
}
// onLeftClick,
// setMap,
// onRightClick,
// showMarker,
// autoCompleteMarker,
// markerReset,
// editMarker,
// disableActions,