import React,{ useState } from "react";
import { useResetPasswordMutation } from "../../../features/auth/authApiSlice";
import { useSnackbar } from "../../SneckBarProvider";
import PasswordTextField from "../../CustomTextFields/PasswordTextField";
import ButtonValidate from "../../Button/ButtonValidate";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useTranslation } from "react-i18next";
import ComfirmationDialog from "../../Dialog/ConfirmationDialog";

export default function ResetPasswordForm(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const showSnackbar = useSnackbar();
  const { id,onClose } = props;
  const [changePassword] = useResetPasswordMutation();

  const [formData, setFormData] = useState({
    id: id,
    newPassword: "",
    confirmPassword: "",
  });
  const [fieldValidities, setFieldValidities] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const handleChangePassword = async () => {
    try {
      const result = await changePassword(formData).unwrap();
      showSnackbar(result.message, "success");
      onClose();
    } catch (err) {
      showSnackbar(err.data.Message, "error");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFieldValidityChange = (fieldName, isValid) => {
    setFieldValidities((prevValidities) => ({
      ...prevValidities,
      [fieldName]: isValid,
    }));
    console.log(fieldValidities);
  };
  const handleOpenDialg=()=>{
    setOpen(true);
  }
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleYesClick=()=>{
    handleChangePassword();
    setOpen(false);
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "300px",
        }}
      >
        <PasswordTextField
          name="newPassword"
          label="New password"
          onPasswordChange={handleChange}
          onValidityChange={handleFieldValidityChange}
          password={formData.newPassword}
          // comperePasswords={formData.password}
        />
        <PasswordTextField
          name="confirmPassword"
          label="Confirm password"
          onPasswordChange={handleChange}
          onValidityChange={handleFieldValidityChange}
          password={formData.confirmPassword}
          comperePasswords={formData.newPassword}
        />
        {/* </div> */}
        <ButtonValidate
          onClick={handleOpenDialg}
          fieldValidities={fieldValidities}
        >
          <SaveOutlinedIcon /> {t("main.save")}
        </ButtonValidate>
        <ComfirmationDialog
          // sx={{ zIndex: "1000000" }}
          // style={{zIndex:1500}}
          open={open}
          onClose={handleCloseDialog}
          dialogContent={"Are you sure you want to change your password?"}
          onNo={handleCloseDialog}
          onYes={handleYesClick} />
      </div>
    </>
  );
}
