import React, { useState } from 'react'
import GoogleMapComponent from '../../GoogleMap/GoogleMap'
import { DisplayRouteComponent } from '../DisplayRouteComponent/DisplayRoute';

const PassengersLocation = () => {
    const [map, setMap] = useState(null);

    const onLeftClick = () => {
        console.log(map);
    };
    const handleRightClick = (cords) => {
        console.log(cords);
    };

    return (
        <DisplayRouteComponent
            
        >

        </DisplayRouteComponent>
    )
}

export default PassengersLocation