import React from "react";
import SMSStatuses from "../components/Sms/SmsStatuses";


//TODO SHOULD CHANGE FILE NAME
const SMSStatusPage = () => {

  return (
    <>
      <SMSStatuses />
    </>
  );
};

export default SMSStatusPage;
