import React, { useState, useEffect } from 'react';
import './UserProfile.css';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useParams } from 'react-router-dom';
import { viewUserById } from '../../../services/UserService';
import { Transition } from '../../../animations/transition';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import EditUserDialog from '../UpdateUser/EditUserDialog.jsx';
import ChangePasswordDialog from '../ChangePasswordComponent/ChangePasswordDialog.jsx';
import { useSnackbar } from '../../SneckBarProvider';
import PersonIcon from '@mui/icons-material/Person';
import { Height } from '@mui/icons-material';
import { ReactComponent as Profile } from '../../../Icons/user-01.svg';
import { useTranslation } from 'react-i18next';
import ChangePasswordForm from '../ChangePasswordComponent/ChangePasswordForm.jsx';
import EditUserForm from '../UpdateUser/EditUserForm.js';

// import {profileBg} from "../../../../public/profileBg.jpg"

export default function UserProfile(props) {
  const { userId } = useParams();
  const [user, setUser] = useState();
  const [open, setOpen] = useState(false);
  const [openChangePasword, setOpenChangePassword] = useState(false);
  const [openForm, setForm] = useState('none');
  const { t } = useTranslation();
  const role = localStorage.getItem('roles');
  const id = localStorage.getItem('id');
  const CheckUserAuth = () => {
    if (id == userId) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    ViewUserById(userId);
  }, [userId]);

  async function ViewUserById(id) {
    try {
      const user = (await viewUserById(id)).data;
      console.log('ovdeee', user);
      setUser(user);
      // setOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  function hendleCloseDilaog() {
    setOpen(false);
  }
  function editUser() {
    setOpen(true);
  }
  async function hendleUpdate() {
    await ViewUserById(userId);
    // hendleCloseDilaog();
  }
  const heandleOnChangePasswordClick = () => {
    setOpenChangePassword(true);
  };
  const heandleCloseChangePasswordDialog = () => {
    setOpenChangePassword(false);
  };
  console.log(userId);
  const handleOpenForm = (formName) => {
    console.log(formName);
    if (openForm == formName) {
      setForm('none');
    } else {
      setForm(formName);
    }
  };
  return (
    <>
      {user && (
        <div className="user-profile-page-container">
          <div className="user-profile-main-container">
            <div className="user-profile-top-cotainer"></div>
            <div className="user-profile-mask-cotainer">
              {/* <div style={{height:"100%",width:"100%",position:"relative"}}> */}
              <div className="user-profile-inittials-container">
                <span>
                {(user.firstName && user.lastName) ? `${user.firstName[0]}${user.lastName[0]}` : user.userName[0]}

                </span>
              </div>

              {/* </div> */}
            </div>
            <div className="user-profile-blank-container"></div>
            <div className="user-profile-content-container">
              <div className="user-profile-name-mail-container">
                <span className="user-profile-name-container">
                  {user.firstName} {user.lastName}
                </span>
                <span className="user-profile-email-container">
                  {user.userName}
                </span>
              </div>
              <div className="user-profile-role-phone-actions-container">
                <span
                  className="user-profile-span-container"
                  style={{
                    gap: '4px',
                  }}
                >
                  <Profile /> {user.role}
                </span>

                <span className="user-profile-span-container">
                  Tel: {user.phoneNumber}
                </span>
                {CheckUserAuth() && (
                  <span
                    className="cursor-pointer user-profile-span-container"
                    style={{
                      borderBottom:
                        openForm === 'changePassword'
                          ? '3px solid #883c40'
                          : '',
                    }}
                    onClick={() => handleOpenForm('changePassword')}
                  >
                    {t('main.changePassword')}
                  </span>
                )}
                <span
                  className="cursor-pointer user-profile-span-container"
                  style={{
                    borderBottom:
                      openForm === 'changePersonalInfo'
                        ? '3px solid #883c40'
                        : '',
                  }}
                  onClick={() => handleOpenForm('changePersonalInfo')}
                >
                  {t('main.changePersonalInfo')}
                </span>
              </div>
            </div>
            {openForm !== 'none' && (
              <div className="user-profile-forms-container">
                <div className="user-profile-form-header-container">
                  <span className="user-profie-form-header">
                    {openForm === 'changePassword'
                      ? t('main.changePassword')
                      : t('main.changePersonalInfo')}
                  </span>
                  {openForm === 'changePassword' ? (
                    <ChangePasswordForm id={userId} />
                  ) : (
                    <EditUserForm
                      id={user.id}
                      firstName={user.firstName}
                      lastName={user.lastName}
                      phoneNumber={user.phoneNumber}
                      email={user.email}
                      userName={user.userName}
                      role={user.role}
                      onUpdate={hendleUpdate}
                    />
                  )}
                </div>
              </div>
            )}
            {/* <div className={`user-profil-profil-container-${CheckUserAuth()}`}>
            <div className="user-prifli-avatar-container">
              <span className="user-profil-username">{user.userName}</span>
              <div className="user-profil-avatar">
                <h1 className="user-profil-header">
                  {user.firstName[0]}
                  {user.lastName[0]}
                </h1>
              </div>
            </div>
            <div className={`user-profil-user-info-${CheckUserAuth()}`}>
              <div className="user-profil-data">
                <span className="user-profil-row">
                  <AccountCircleIcon />
                  &nbsp; &nbsp;
                  <span>
                    {user.firstName} {user.lastName}
                  </span>
                </span>
                <span className="user-profil-row">
                  <MailOutlineIcon />
                  &nbsp; &nbsp;
                  <span>{user.email}</span>
                </span>
                <span className="user-profil-row">
                  <LocalPhoneIcon />
                  &nbsp; &nbsp;
                  <span>{user.phoneNumber}</span>
                </span>
                <span className="user-profil-row">
                  <PersonIcon />
                  &nbsp; &nbsp;
                  <span>{role}</span>
                </span>
              </div>
              {CheckUserAuth() && (
                <div className="user-profil-data-password">
                  <span className="user-profil-row">
                    <span className="user-profil-name">Password:</span>
                    &nbsp; &nbsp;
                    <span
                      onClick={heandleOnChangePasswordClick}
                      className="user-profile-password"
                    >
                      Change Password
                    </span>
                  </span>
                </div>
              )}
            </div>
            {CheckUserAuth() && (
              <button onClick={editUser} className="user-profile-button">
                EDIT PROFILE
              </button>
            )} */}
            {/* </div> */}
            {/* <EditUserDialog
            open={open}
            onUpdate={hendleUpdate}
            onClose={hendleCloseDilaog}
            formData={user}
          />
          <ChangePasswordDialog
            id={userId}
            onClose={heandleCloseChangePasswordDialog}
            open={openChangePasword}
          ></ChangePasswordDialog> */}
          </div>
        </div>
      )}
    </>
  );
}
