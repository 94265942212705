import React, { useEffect, useState } from "react";

import "../Route/AddRouteComponent/AddRouteByAutocomplete.css";
import { useTranslation } from "react-i18next";
import { roles } from "../../config";
import { Select, InputLabel, FormControl, MenuItem } from "@mui/material";
import { formControlStyles } from "../../customStyles/customStyles";

const RoleFormControl = (props) => {
  const { t } = useTranslation();

  const { onChange, value,customStyle } = props;

  return (
    <>
      <FormControl
        sx={formControlStyles}
        style={customStyle}
      >
        <InputLabel color="secondary" id="roleLabel">
          {t("users.filterRole")}
        </InputLabel>
        <Select
          color="secondary"
          name="role"
          labelId="roleLabel"
          id="role"
          label={t("users.filterRole")}
          value={value}
          onChange={onChange}
        >
          {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default RoleFormControl;
