import React from "react";
import RouteComponent from "../components/Route/RouteComponent/RouteComponent";
import { useParams } from 'react-router-dom';
import BookingComponent from "../components/Booking/ShowBooking/BookingComponent";

//TODO SHOULD CHANGE FILE NAME
const RoutePage = () => {
  const { routeId,reservationId, bookingId } = useParams();
  return (
    <>
      <BookingComponent routeId={routeId} reservationId={reservationId} bookingId={bookingId}></BookingComponent>   
    </>
  );
};

export default RoutePage;
