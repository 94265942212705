import React, { useEffect, useState } from "react";

import "./CustomTextFields.css";

const CustomTextField = (props) => {
  const { onChange, value, name, label, placeholder, type, sx,disabled } = props;

  //   const handlePasswordChange = (e) => {
  //     // const { name, value } = e.target;
  //     // console.log(password)
  //     if (e.target.value !== "") {
  //       getPasswordValidation(e.target.value);
  //     } else {
  //       resetField();
  //     }
  //     // setPassword(e.target.value)
  //     onPasswordChange(e);
  //   };
  //   const togglePasswordVisibility = () => {
  //     setShowPassword(!showPassword);
  //   };
  return (
    <>
    {/* style={{display:"flex", flexDirection:"column", flex:"1"}} */}
      <div className="custom-input-main-container" style={{minWidth:"150px"}}>
        <label className="custom-label">{label}</label>
        <input
          className="filter-component-text-field"
          style={{ ...sx }}
          name={name}
          label={label}
          placeholder={placeholder}
          type={type}
          // sx={newTextFieldStyle}
          disabled={disabled}
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default CustomTextField;
