//TODO SHOULD CHANGE FILE NAME
import CheckIcon from '@mui/icons-material/Check';
import { tr } from 'date-fns/locale';
import React, { useState } from 'react';
import SMSButton from '../Button/SMSButton';

const SMSStatus = ({ smsData }) => {
  const [expand,setExpand]=useState(false);
  const convertNewlinesToBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const containerStyle = {
    // height: expand ? 'min-content' : '130px',
    // transition: 'height 0.3s ease', 
  };

  const checkStauts=()=>{
    return smsData.status=="Sent" || smsData.status== "Delivered" ? true : false
  }

  const handleShowHideClick=()=>{
    setExpand(!expand);
  }
  return (
    <>
      <div style={containerStyle} className="sms-status-container" onClick={handleShowHideClick}>
                {/* <div className='sms-status-data-container'>
        <span className="status-number-date-container">Reservation :</span>
        <span style={{ color: 'blue' }}>#{smsData.reservationId}</span>

        </div>
          <div className="sms-status-data-container">
            <span className="status-number-date-container">Putnik :</span>
            <span>Dusan Spasic</span>
          </div>
          <div className="sms-status-data-container">
            <span className="status-number-date-container">Phone number :</span>
            <span>{smsData.phoneNumber}</span>
          </div> */}
        <div className="sms-status-reservaationid-container">
          <div className="sms-status-reservation-text-container">
            <span className="status-number-date-container">Reservation</span>
            <span className="status-number-date-container">:</span>
            <span style={{ color: 'blue' }}>#{smsData.reservationId}</span>
          </div>
          <div className={`sms-status-status ${checkStauts() ? 'sms-status-status-sent' : 'sms-status-status-not-sent'}`}>
             {checkStauts() == true ? smsData.status :'failed'}
          </div>
        </div>
        <div className="sms-status-content-container status-number-date-container">
          <div className="sms-status-number">{smsData.phoneNumber}</div>
          <span>{smsData.dateTime}</span>
        </div>
        {/* <div>
          <SMSButton reservations={smsData}  />
        </div> */}
        {/* <div className="sms-status-content-container">
          <div className="sms-status-number">
            {convertNewlinesToBreaks(smsData.text)}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default SMSStatus;
