import React, { useState, useEffect } from "react";
import "./CustomButton.css";
const ButtonValidate = (props) => {
  const { children, fieldValidities,onClick } = props;
  const [isFormValid, setIsFormValid] = useState(false);

  const handleFormValidation = () => {
    // Check if all fields are valid
    const isValid = Object.values(fieldValidities).every((valid) => valid);
    console.log(isValid);
    setIsFormValid(isValid);
  };
  useEffect(() => {
    console.log(fieldValidities);
    handleFormValidation();
  }, [fieldValidities]);

  return (
    <>
      <button
        style={{ height: "50px", borderRadius: "5px" }}
        className="arac-save-button"
        disabled={!isFormValid}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
};

export default ButtonValidate;
