import { apiSlice } from "../../app/api/apiSlice";

export const customLocationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        AddCustomLocation: builder.mutation({
            query: (body) => ({
                url: 'CustomLocation/AddCustomLocation',
                method: 'POST',
                body
            })
        }),
        GetCustomLocationById: builder.query({
            query: (id) => ({
                url: 'CustomLocation/GetById?id=' + id,
                method: 'GET'
            })
        }),
        GetCustomLocationPredictions: builder.query({
            query: (title) => ({
                url: 'CustomLocation/GetCustomLocationPredictions?title=' + title,
                method: 'GET'
            })
        }),
        GetAllCustomLocations: builder.query({
            query: () => ({
                url: 'CustomLocation/GetAllCustomLocations',
                method: 'GET'
            })
        }),
        UpdateCustomLocation: builder.mutation({
            query: (body) => ({
                url: 'CustomLocation/UpdateCustomLocation',
                method: 'PUT',
                body
            })
        }),
        DeleteCustomLocation: builder.mutation({
            query: (id) => ({
                url: 'CustomLocation/DeleteCustomLocation?id=' + id,
                method: 'DELETE'
            })
        }),
        GetCustomLocationsPerPage: builder.query({
            query: (pars) => ({
                url: `CustomLocation/GetCustomLocationsPerPage`,
                method: 'GET',
                params: { ...pars },
            })
        }),
    })
})

export const { useAddCustomLocationMutation, useGetCustomLocationByIdQuery, useGetAllCustomLocationsQuery,
    useUpdateCustomLocationMutation, useDeleteCustomLocationMutation,useLazyGetCustomLocationPredictionsQuery,useLazyGetCustomLocationsPerPageQuery
 } = customLocationsApiSlice