import { getBookingById } from "../../../services/BookingService";
import React, { useState, useEffect } from "react";
import "./BookingComponent.css";
import { useGetBookingByIdQuery, useGetAllBookingUsersQuery, useGetPassengersForBookingQuery } from "../../../features/bookings/bookingsApiSlice";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useDisableBookingMutation, useEnableBookingMutation, useGetBookingsQuery } from '../../../features/bookings/bookingsApiSlice';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Typography } from '@mui/material';
import { Transition } from '../../../animations/transition';
import AddBookingForm from '../AddBooking/AddBookingForm';
import EditIcon from "@mui/icons-material/Edit";
import NoLuggageIcon from '@mui/icons-material/NoLuggage';
import UpdateBookingForm from '../UpdateBooking/UpdateBookingForm';
import CustomDialog from '../../Dialog/CustomDialog';
import InfoIcon from '@mui/icons-material/Info';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate } from 'react-router-dom';
import PersonOffIcon from "@mui/icons-material/PersonOff";
import RouteComponent from "../../Route/RouteComponent/RouteComponent";
import AllReservationsForBookingComponent from "../../Reservations/ReservationsComponent/AllReservationsForBookingComponent";
import AddReservationFormComponent from "../../Reservations/AddReservation/AddReservationFormComponent";

export default function BookingComponent({ routeId,reservationId,bookingId }) {

  //   const [users, setUsers] = useState([]);

  /*const {
    data: users,
    error,
    isLoading,
    refetch
  } = useGetPassengersForBookingQuery(bookingId);*/

  

  //columns
 /*
  const columns = [
    { id: "email", label: "Email" },
    { id: "firstName", label: "First Name" },
    {
      id: "lastName",
      label: "Last Name",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "phoneNumber",
      label: "Phone Number",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
*/

  const onEditBooking = (id) => {
    console.log(id);
    //refetchGetBookingByIdQuery();
  };
  const [addOpen, setAddOpen] = useState(false);

  const handleAddOpen = () => {
    setAddOpen(true);
  }

  const handleAddClose = async () => {
    setAddOpen(false);
  }

  return (
    <div style={{ margin: '65px' }}>
      <Card sx={{ padding: '20px' }}>
        <strong style={{margin:'2%',marginTop: '20px'}}>Passengers</strong>

        <AddReservationFormComponent  />

        <AllReservationsForBookingComponent reservationId={reservationId} bookingId={bookingId} />
      </Card>

      <Card sx={{ padding: '20px', marginTop: '20px' }}>
        <strong style={{ marginTop: '20px' }}>Route Information</strong>
        <RouteComponent routeId={routeId} />
      </Card>
    </div>
  )
}
