import React, { useState } from "react";
import {
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import "../../Register/RegisterForm.css";
import { useInviteUserMutation } from "../../../features/auth/authApiSlice";
import { useSnackbar } from "../../SneckBarProvider";
import CustomButton from "../../Button/Button";
import { roles } from "../../../config";
import { textFieldStyles } from "../../../customStyles/customStyles";
import { formControlStyles } from "../../../customStyles/customStyles";
import ComfirmationDialog from "../../Dialog/ConfirmationDialog";
import RoleFormControl from "../../CustoFormControl/RoleFormControl";
import { useTranslation } from "react-i18next";
import CustomDropDown from "../../CustomTextFields/CustomDropDown";
import CustomTextField from "../../CustomTextFields/CustomTextField";

const SendRegistrationInvitationForm = (props) => {
  const { t } = useTranslation();
  const {onClose}=props
  const [open, setOpen] = useState(false);
  const [inviteUser] = useInviteUserMutation();

  const showSnackbar = useSnackbar();

  const [formData, setFormData] = useState({
    email: "",
    role: roles[0],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData);
  };

  const handleInviteUser = async () => {
    try {
      const response = await inviteUser(formData).unwrap();
      showSnackbar(response?.message, "success");
      //   onAddUser();
      onClose();
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };

  const handleOpenDialg = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleYesClick = () => {
    handleInviteUser();
    setOpen(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <div>
        <label className="custom-label">Email</label>
      <TextField
        sx={textFieldStyles}
        required
        fullWidth
        name="email"
        placeholder="Email"
        type="email"
        id="email"
        value={formData.email}
        onChange={handleChange}
      />
      {/* <CustomTextField sx>

      </CustomTextField> */}
      </div>
      <div>

      <CustomDropDown
            name="role"
            id="role"
            value={formData.role}
            onChange={handleChange}
            label={t('users.filterRole')}
            options={roles}
          />
      </div>
      {/* <RoleFormControl value={formData.role} onChange={handleChange} /> */}
      <CustomButton
        onClick={handleOpenDialg}
        //   varient="filled"
        type="add"
      >
        {t(`users.invite`)}
      </CustomButton>
      {open && (
        <ComfirmationDialog
          open={open}
          onClose={handleCloseDialog}
          dialogContent={"Are you sure you want to invite new user?"}
          onNo={handleCloseDialog}
          onYes={handleYesClick}
        />
      )}
    </div>
  );
};

export default SendRegistrationInvitationForm;
