import React, { useEffect, useState } from 'react'
import { Button, DialogActions, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateReservationDialog from '../UpdateReservationDialog/UpdateReservationDialog';
import { useCancelReservationMutation, useDisableReservationMutation, useGetPassengersForBookingQuery, useGetReservationsQuery } from '../../../features/reservation/reservationSlice';
import EditIcon from "@mui/icons-material/Edit";
import NoLuggageIcon from '@mui/icons-material/NoLuggage';
import CustomDialog from '../../Dialog/CustomDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import './AllReservationsForBookingComponent.css';
import { useSnackbar } from '../../SneckBarProvider';


const AllReservationsForBookingComponent = ({ reservationId ,bookingId }) => {

    console.log(reservationId);
    const [initialFilters, setInitialFilters] = useState({
        reservationId:reservationId==null || reservationId == 0 || reservationId == undefined ? undefined :reservationId,
        bookingId: bookingId == null || bookingId == 0 || bookingId == undefined ? undefined: bookingId,
        dateFrom: null,
        dateTo: null,
        code: '',
    });

    const {
        data: users,
        error,
        isLoading,
        refetch
    } = useGetReservationsQuery(initialFilters); ///*bookingId: bookingId,*/

    //const showSnackbar = useSnackbar();
    const showSnacbar = useSnackbar();

    const [cancelOpen, setCancelOpen] = useState(false);

    const [selectedPassengerReservationId, setSelectedPassengerReservationId] = useState(0);
    const [selectedReservationId, setSelectedReservationId] = useState(0);
    const [selectedBookingId, setSelectedBookingId] = useState(0);

    const [cancelReservation] = useCancelReservationMutation();

    const handleReservationEdit = (reservation) => {
        console.log("edit")
    }

    const handleCancelOpen = (bookingId, passengerId, reservationId) => {
        setCancelOpen(true);

        console.log("bookingId :" + bookingId)
        console.log("passengerId :" + passengerId)
        console.log("res id:" + reservationId)

        setSelectedBookingId(bookingId);
        setSelectedPassengerReservationId(passengerId);
        setSelectedReservationId(reservationId);

    }

    const handleCancelClose = () => {
        setCancelOpen(false);
        //refetch();
    }

    const handleCancelReservation = async (/*bookingId,passengerId,reservationId*/) => {
        try {
            //var parsedReservationId = parseInt(selectedReservationId);
            //console.log(parsedReservationId);

            //const res = await cancelReservation({ bookingId:bookingId,passengerId:passengerId,reservationId:reservationId  })//(selectedUserReservationId, parsedReservationId);
            const res = await cancelReservation({ bookingId: selectedBookingId, passengerId: selectedPassengerReservationId, reservationId: selectedReservationId }).unwrap();

            handleCancelClose();

            refetch();

            showSnacbar(res?.message, "success");

            console.log(res)
        } catch (error) {
            console.log(error);
        }
    }

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error?.message || "An error occurred"}</div>;

    return (
        <div className='all-reservations-global-container'>
            {
                // isLoading ? (
                //     <h2>Loading...</h2>
                // ) : 
                (
                    <>
                        {/* <AddReservationFormComponent /> */}
                        {/* <span><Button size="small" variant="outlined" onClick={handleReservationDelete}></Button></span> */}

                        {/* <UpdateReservationDialog /> */}
                        <CustomDialog
                            onOpen={cancelOpen}
                            onClose={() => handleCancelClose()}
                            contentTitle=''
                            content='Are you sure you want to cancel this reservation'
                        >
                            <DialogActions>
                                <Button onClick={() => handleCancelClose()}>No</Button>
                                <Button onClick={() => handleCancelReservation()}>Yes</Button>
                            </DialogActions>
                        </CustomDialog>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{ backgroundColor: 'ButtonShadow' }}>
                                    <TableRow key='first'>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Surname</TableCell>
                                        <TableCell>Note</TableCell>
                                        <TableCell>Phone Number</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.reservations.map((reservation, resIndex) => (
                                        <React.Fragment key={resIndex}>
                                            {reservation.passengers.length > 0 ? (
                                                reservation.passengers.map((passenger, passIndex) => (
                                                    <TableRow key={passIndex}>
                                                        <TableCell>{passenger.firstName}</TableCell>
                                                        <TableCell>{passenger.lastName}</TableCell>
                                                        <TableCell>{passenger.note}</TableCell>
                                                        <TableCell>{passenger.phoneNumber}</TableCell>
                                                        {passenger.reservationStatus ?
                                                            (<TableCell className='action-cell'><Link
                                                                onClick={() => handleCancelOpen(reservation.bookingId, passenger.id, reservation.id)}
                                                                href="#"
                                                                color='gray'
                                                                underline="none">
                                                                Delete</Link></TableCell>
                                                            )
                                                            :
                                                            (
                                                                <TableCell><Typography>cancelled</Typography></TableCell>
                                                            )
                                                        }
                                                        <TableCell className='action-cell'>
                                                            <Link
                                                                onClick={() => handleReservationEdit()}
                                                                href="#"
                                                                color='gray'
                                                                underline="none">
                                                                Edit
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )
            }
        </div >
    )
}

export default AllReservationsForBookingComponent