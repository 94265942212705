import React from 'react';

export default function ExpandIcon() {
  return (
    <div >
      <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-hfutr2-MuiSvgIcon-root-MuiSelect-icon"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="ArrowDropDownIcon"
        style={{fill:'currentcolor'}}
      >
        <path d="M7 10l5 5 5-5z"></path>
      </svg>
    </div>
  );
}
