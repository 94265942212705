import React, { useEffect, useState } from "react";

import { newTextFieldStyle } from "../../customStyles/customStyles";
import "./CustomTextFields.css";
import { useTranslation } from "react-i18next";
const CustomDropDown = (props) => {
  const { onChange, value, name, label, sx, id, options } = props;
  const {t}=useTranslation();
  //   const handlePasswordChange = (e) => {
  //     // const { name, value } = e.target;
  //     // console.log(password)
  //     if (e.target.value !== "") {
  //       getPasswordValidation(e.target.value);
  //     } else {
  //       resetField();
  //     }
  //     // setPassword(e.target.value)
  //     onPasswordChange(e);
  //   };
  //   const togglePasswordVisibility = () => {
  //     setShowPassword(!showPassword);
  //   };
  return (
    <>
      <div style={{minWidth:"150px"}}>
        <label className="custom-label">{label}</label>
        <select
          // labelId="routeStateLabel"
          // className="filter-component-text-field"
          name={name}
          // labelId="isEnabledLabel"
          id={id}
          value={value}
          onChange={onChange}
        //   style={{ ...sx }}
          style={{ ...newTextFieldStyle }}

        >
          {options.map((option) => (
            <option
              key={option}
              value={option}
            >
              {/* {option} */}
              
              {t(`routeStatus.${option}`)}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default CustomDropDown;
