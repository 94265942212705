import { apiSlice } from '../../app/api/apiSlice';

export const reservationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReservations: builder.query({
      query: (filters) => {
        const {
          date,
          bookingId,
          routeId,
          direction,
          isReturnTicket,
          nightLine,
          createdFrom,
          createdTo,
          passengerName,
          reservationId,
          reservationType,
          pageSize,
          pageNumber,
        } = filters;
        const queryParams = new URLSearchParams();

        // Add parameters only if they are present in the filters object
        if (date) queryParams.append('date', date);
        if (bookingId) queryParams.append('bookingId', bookingId);
        if (routeId) queryParams.append('routeId', routeId);
        if (direction) queryParams.append('direction', direction);
        if (isReturnTicket)
          queryParams.append('isReturnTicket', isReturnTicket);
        if (nightLine) queryParams.append('nightLine', nightLine);
        if (createdFrom) queryParams.append('createdFrom', createdFrom);
        if (createdTo) queryParams.append('createdTo', createdTo);
        if (passengerName) queryParams.append('passengerName', passengerName);
        if (reservationId) queryParams.append('reservationId', reservationId);

        if (reservationType)
          queryParams.append('reservationType', reservationType);
        else queryParams.append('reservationType', 'all'); // Default bookingType
        if (pageSize) queryParams.append('pageSize', pageSize);
        else queryParams.append('pageSize', 10); // Default pageSize
        if (pageNumber) queryParams.append('pageNumber', pageNumber);
        else queryParams.append('pageNumber', 1); // Default pageNumber

        return {
          url: `Reservation/GetReservationsPerPage?${queryParams.toString()}`,
          method: 'GET',
        };
      },
    }),
    getReservationById: builder.query({
      query: ({ id }) => {
        return {
          url: 'Reservation/GetReservationById?id=' + id,
          method: 'GET',
        };
      },
    }),
    getSpecificPassengerReservationById: builder.query({
      query: ({ reservationId, passengerId }) => ({
        url: `Reservation/GetSpecificPassengerReservationById?reservationId=${reservationId}&passengerId=${passengerId}`,
        method: 'GET',
      }),
    }),
    getAllReservations: builder.query({
      query: () => {
        return {
          url: 'Reservation/GetAllReservations',
          method: 'GET',
        };
      },
    }),
    getReservationsRequests: builder.query({
      query: () => 'Reservation/GetAllReservationRequests',
    }),
    getRoutesStopsByRouteId: builder.query({
      query: (routeId) => ({
        url: 'RouteStop/GetRouteStopsForDropDown?routeId=' + routeId,
        method: 'GET',
      }),
    }),
    acceptRequestForReservation: builder.mutation({
      query: () => 'Reservation/',
    }),
    createReservation: builder.mutation({
      query: (body) => ({
        url: 'Reservation/AddReservation',
        method: 'POST',
        body,
      }),
    }),
    cancelReservation: builder.mutation({
      query: ({ bookingId, passengerId, reservationId }) => ({
        url: `Reservation/CancelReservation?bookingId=${bookingId}&passengerId=${passengerId}&reservationId=${reservationId}`,
        method: 'PUT',
      }),
    }),
    changeReservationStatus: builder.mutation({
      query: ({ reservationTicketType, passengerId, status }) => ({
        url: `Reservation/ChangeReservationStatus?reservationTicketType=${reservationTicketType}&passengerId=${passengerId}&status=${status}`,
        method: 'PUT',
      }),
    }),
    disableReservation: builder.mutation({
      query: ({ id }) => ({
        url: `Reservation/DisableReservation?id=${id}`,
        method: 'PUT',
      }),
    }),
    addPassenger: builder.mutation({
      query: (body) => ({
        url: 'Passenger/AddPassenger',
        method: 'POST',
        body,
      }),
    }),
    getAllPassengers: builder.mutation({
      query: () => 'Passenger/GetAllPassengers',
    }),
    getPassengersByReservation: builder.query({
      query: (reservationId) => ({
        url: 'Passenger/GetByReservation?reservationId=' + reservationId,
        method: 'GET',
      }),
    }),
    addReservationWithPassengers: builder.mutation({
      query: (body) => ({
        url: 'Reservation/AddReservationWithPassengers',
        method: 'POST',
        body,
      }),
    }),
    getBookingMatchId: builder.mutation({
      query: (body) => {
        return {
          url: 'Booking/GetFindOrCreateMatch',
          method: 'POST',
          body,
        };
      },
    }),
    updateReservation: builder.mutation({
      query: (body) => {
        return {
          url: 'Reservation/UpdateReservationWithPassengers',
          method: 'PUT',
          body,
        };
      },
    }),
    updatePassenger: builder.mutation({
      query: (body) =>
        //(
        {
          return {
            url: 'Passenger/UpdatePassenger',
            method: 'PUT',
            body,
          };
        },
      //)
    }),
    updateReservationAndBooking: builder.mutation({
      query: (body) => {
        return {
          url: 'Reservation/UpdateReservationAndBooking',
          method: 'PUT',
          body,
        };
      },
    }),

    getChangesHistory: builder.query({
      query: (id) => {
        return {
          url: `AuditLogs/${id}`,
        };
      },
    }),
    getUnapprovedReservations: builder.query({
      query: () => {
        return {
          url: 'Reservation/GetUnapprovedReservations',
          method: 'GET',
        };
      },
    }),
    approveReservation: builder.mutation({
      query: (id) => {
        return {
          url: `Reservation/ApproveReservation/${id}`,
          method: 'PUT',
        };
      },
    }),
    getReservationsBySubagent: builder.query({
      query: (id) => {
        return {
          url: `Reservation/GetReservationsBySubagent/${id}`,
          method: 'GET',
        };
      },
    }),
    updateReservationWithoutPassengers: builder.mutation({
      query: (body) => {
        return {
          url: `Reservation/UpdateReservationWithoutPassenger`,
          method: 'PUT',
          body,
        };
      },
    }),
    updateDepartureTime: builder.mutation({
      query: (body) => {
        return {
          url: 'Reservation/UpdateDepartureTime',
          method: 'PUT',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetReservationsQuery,
  useGetAllReservationsQuery,
  useGetReservationsRequestsQuery,
  useLazyGetRoutesStopsByRouteIdQuery,
  useGetReservationByIdQuery,
  useGetSpecificPassengerReservationByIdQuery,
  useCreateReservationMutation,
  useCancelReservationMutation,
  useChangeReservationStatusMutation,
  useDisableReservationMutation,
  useAddPassengerMutation,
  useGetAllPassengersMutation,
  useGetPassengersByReservationQuery,
  useAddReservationWithPassengersMutation,
  useGetBookingMatchIdMutation,
  useUpdateReservationMutation,
  useUpdatePassengerMutation,
  useUpdateReservationAndBookingMutation,
  useLazyGetChangesHistoryQuery,
  useGetChangesHistoryQuery,
  useGetUnapprovedReservationsQuery,
  useApproveReservationMutation,
  useGetReservationsBySubagentQuery,
  useUpdateReservationWithoutPassengersMutation,
  useUpdateDepartureTimeMutation
} = reservationsApiSlice;
