import React, { useReducer, useState } from 'react';
import {
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import './CreateBookingComponent.css';
import { useTranslation } from 'react-i18next';
import ComfirmationDialog from '../../Dialog/ConfirmationDialog';
import { ToastContainer, toast } from 'react-toastify';
import { useSnackbar } from '../../SneckBarProvider';
import { noAction } from '../../../utils/helperActions/helperActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as PlusIcon } from '../../../Icons/PlusIcon.svg';
import { ReactComponent as TrashIcon } from '../../../Icons/trash-01.svg';
import { ReactComponent as EditIcon } from '../../../Icons/editIcon.svg';

// import TrashIcon from '../../../utils/icons/TrashIcon';
// import EditIcon from '../../../utils/icons/EditIcon';
import {
  newTextFieldStyle,
  textAreaStyle,
} from '../../../customStyles/customStyles';
import PassangersAutocomplete from './PassangersAutocomplete';
import FilledButton from '../../Button/FilledButton';
import FiltersContainer from '../../Route/FilterRouteComponent/FilterContainer';
import { EventBusyTwoTone } from '@mui/icons-material';
import PhoneTextField from '../../CustomTextFields/PhoneTextField';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

export default function PassengersComponent({
  passengersState,
  setPassengersState,
  handleDeletePassenger,
  handleEditPassenger,
  handleAddPassenger,
  disabled,
  isSecondPhoneVisible,
  setIsSecondPhoneVisible
}) {
  //const [isSecondNumberFieldVisible, setSecondNumberFieldVisible] = useState(false);

  const { t } = useTranslation();
  const columns = [
    { id: 'name', label: t('main.fullName') },
    { id: 'note', label: t('main.note') },
    { id: 'phoneNumber', label: t('main.phone') },
  ];

  const initialPassengerState = {
    name: '',
    note: '',
    phoneNumber: '',
  };
  const showSnackbar = useSnackbar();

  function newPassengerReducer(state, action) {
    setOnChange(true);
    switch (action.type) {
      case 'name':
        if (action.payload.trim() == '' || action.payload == '')
          setNameValid(false);
        else setNameValid(true);
        console.log("NAMEE", action.payload)
        return {
          ...state,
          name: action.payload,
        };
      case 'note':
        return {
          ...state,
          note: action.payload,
        };
      case 'phoneNumbers':
        const phoneNumberPattern = /^(\.?\+?\d{1,15})(\/\.?\+?\d{1,15})?$/;

        if (action.payload.length > 34) {
          setPhoneNumberErrorMessage(t('booking.maxDigitsPhoneNumber'));
          setPhoneNumberValid(false);
        } else if (
          !phoneNumberPattern.test(action.payload) ||
          action.payload.trim() == ''
        ) {
          if (action.payload == '') setPhoneNumberValid(true);
          else {
            setPhoneNumberErrorMessage(t('booking.onlyDigitsPhoneNumber'));
            setPhoneNumberValid(false);
          }
        } else {
          setPhoneNumberValid(true);
        }

        return {
          ...state,
          phoneNumber: action.payload,
        };
      case 'reset':
        setNameValid(false);
        setOnChange(false);
        return initialPassengerState;
      case 'update':
        setOnChange(true);
        setNameValid(true);
        return action.payload;
    }
  }

  const [nameValid, setNameValid] = useState(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');
  const [onChange, setOnChange] = useState(false);
  const [editFormState, setEditFormState] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [editIndex, setEditIndex] = useState('');
  const [deleteIndex, setDeleteIndex] = useState('');
  const [reset, setReset] = useState(true);
  const [expandedFrame, setExpandedFrame] = useState(true);
  const [newPassengerState, newPassengerUpdate] = useReducer(
    newPassengerReducer,
    initialPassengerState
  );


  const handleAddPhoneFieldIconClick = () => {
    setIsSecondPhoneVisible(true);
    //setSecondNumberFieldVisible(true);
  };
  const handleRemovePhoneFieldIconClick = () => {
    //setSecondNumberFieldVisible(false);
    setIsSecondPhoneVisible(false);
    const justFirstNumValue = newPassengerState.phoneNumber.split('/')[0] || '';

    newPassengerUpdate({
      type: 'phoneNumbers',
      payload: justFirstNumValue
    });
  }

  const handleOpenDeleteDialog = (index) => {
    setDeleteIndex(index);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeletePassenger(deleteIndex);
    setOpenDeleteDialog(false);
  };

  const handleOpenEditDialog = () => {
    //dodavanje validacije
    if (!nameValid) return;
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };
  const handleKeyDownForPhone = (event) => {
    if (event.key === 'Enter' && nameValid) {
      if (!editFormState) {
        handleAddConfirmation();
        console.log('pepepepepep');
      } else {
        handleEditConfirmation();
      }
    }
  };
  const handleEditConfirmation = () => {
    if (!onChange) {
      setNameValid(false);
      setPhoneNumberValid(false);
      return;
    }
    if (nameValid && phoneNumberValid) {
      handleEditPassenger(editIndex, newPassengerState, true);
      setEditIndex('');
      setOpenEditDialog(false);
      newPassengerUpdate({ type: 'reset' });
      setReset(!reset);
      setEditFormState(false);
    }
  };

  const handleOpenAddDialog = () => {
    if (!onChange) {
      setNameValid(false);
      return;
    }
    if (!nameValid) return;
    if (!phoneNumberValid) return;
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddConfirmation = () => {
    if (!onChange) {
      setNameValid(false);
      return;
    }
    if (nameValid && phoneNumberValid) {
      handleAddPassengerSubmit();
      setOpenAddDialog(false);
    }
  };

  const handleAddPassengerSubmit = () => {
    // let newPassengersArray = passengersState.concat(newPassengerState);
    // setPassengersState(newPassengersArray);
    handleAddPassenger(newPassengerState);
    newPassengerUpdate({ type: 'reset' });
    setReset(!reset);

    showSnackbar('Successfully added user!', 'success');
    setOnChange(false);
  };

  const handleEditPassengerSubmit = () => {
    handleAddPassengerSubmit();
    setEditFormState(false);
  };

  const handleEditPassengerClick = (index) => {
    // if(editIndex==''){
    console.log(index);
    console.log(editIndex);
    console.log(passengersState);
    setEditFormState(true);
    setEditIndex(index);
    newPassengerUpdate({ type: 'update', payload: passengersState[index] });
    setReset(!reset);

    handleEditPassenger(index);
    // }
  };
  const handleChangeAutocomplete = (newVal) => {
    newPassengerUpdate({
      type: 'name',
      payload: newVal.name,
    });
    newPassengerUpdate({
      type: 'phoneNumbers',
      payload: newVal.phoneNumber,
    });
  };

  const handleChange = (value) => [
    newPassengerUpdate({
      type: 'name',
      payload: value,
    }),
  ];

  // const handleKeyDown = (event) => {
  //   const key = event.key;
  //   if (!/[\d\+\\./]/.test(key)&& key !== 'Enter' && key !== 'Backspace' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'Delete' && key !== 'Tab') {
  //     event.preventDefault();
  //   }
  // };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && nameValid) {
      if (!editFormState) {
        handleAddConfirmation();
        console.log('pepepepepep');
      } else {
        handleEditConfirmation();
      }
    }
  };

  const handlePhoneKeyDown = (event) => {
    const key = event.key;
    if (key === "Enter") {
      handleKeyDown(event)
    }
    else if (!/[\d\+\\./]/.test(key) && key !== 'Backspace' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'Delete' && key !== 'Tab') {
      event.preventDefault();
    }
  }

  const handlePaste = (event) => {
    event.preventDefault();
  };

  const handleOnPhoneChange = (value, flag) => {
    if (value && !value.startsWith('+')) {
      value = `+${value}`;
    }

    console.log(flag + ":" + value);

    if (flag === 'firstNum') {
      newPassengerUpdate({
        type: 'phoneNumbers',
        payload: value,
      });
    } else if (flag === 'secondNum') {

      const firstNum = newPassengerState.phoneNumber.split('/')[0];
      const combinedNumber = `${firstNum}/${value}`;

      newPassengerUpdate({
        type: 'phoneNumbers',
        payload: combinedNumber,
      });
    }
  }

  const firstNum = newPassengerState.phoneNumber.split('/')[0] || '';
  let secondNum = newPassengerState.phoneNumber.split('/')[1] || '';

  return (
    <FiltersContainer
      containerName={t('booking.passengers')}
      hiddenButtons={true}
      opened={true}
    >
      <div className="passangers-component-main-container">
        <div className="route-card">
          <div className="passangers-fields-container">
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}
            >
              <label className="custom-label">{t('main.fullName')}</label>
              <PassangersAutocomplete
                onPredictionSelect={handleChangeAutocomplete}
                name="auto"
                placeholder={t('main.enterName')}
                value={newPassengerState.name}
                handleInputChange={handleChange}
                error={!nameValid}
                helperText={!nameValid ? t('main.fieldRequired') : ''}
                disabled={disabled}
                reset={reset}
                noOptionsText={t('main.noOptions')}
                onEnterClcik={handleKeyDown}
              ></PassangersAutocomplete>
            </div>

            <div className='phone-num-text-field'>
              <div className='first-number-field'>
                <PhoneTextField
                  // addSearch={true}
                  name="phoneNumber"
                  value={firstNum}
                  onPhoneChange={(event) => handleOnPhoneChange(event, 'firstNum')}>
                </PhoneTextField>
              </div>

              <div className={`second-number-field ${isSecondPhoneVisible ? 'visible' : 'hidden'}`}>
                <PhoneTextField
                  // addSearch={true}
                  name="phoneNumber"
                  value={secondNum}
                  onPhoneChange={(event) => handleOnPhoneChange(event, 'secondNum')}
                />
              </div>

              {
                isSecondPhoneVisible === true ?
                  <div className='remove-number-button'>
                    <Button
                      sx={{ color: '#475467', fontSize: '12PX', fontWeight: "bold" }}
                      variant="text"
                      onClick={handleRemovePhoneFieldIconClick}>
                      <TrashIcon />
                      {t('booking.removeNumber')}
                    </Button>
                  </div>
                  :
                  <div className='add-number-button'>
                    <Button
                      variant="outlined"
                      sx={{ borderColor: '#883C40', borderWidth: '2px', color: '#883C40', fontWeight: "bold" }}
                      onClick={handleAddPhoneFieldIconClick}>
                      <AddOutlinedIcon sx={{ color: '#883C40', width: '20px', height: '20px' }} />
                      {t('booking.addNewNumber')}
                    </Button>
                  </div>
              }
            </div>

          </div>
          <div className="text-area-passangers-container">
            <label className="custom-label">{t('main.note')}</label>
            <textarea
              className={`TextField-multiline  ${isSecondPhoneVisible ? 'min-height-applied' : ''}`}
              value={newPassengerState.note}
              placeholder={t('main.enterNote')}
              onChange={(event) =>
                newPassengerUpdate({
                  type: 'note',
                  payload: event.target.value,
                })
              }
              style={newTextFieldStyle}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="passenger-button">
          {!editFormState ? (
            <>
              <FilledButton
                variant="contained"
                className="add-passenger-button"
                onClick={handleAddConfirmation}
                isValid={nameValid && phoneNumberValid}

              >
                <PlusIcon />
                &nbsp; {t('booking.addPassenger')}
              </FilledButton>
              {/* {openAddDialog && (
                <ComfirmationDialog
                  open={openAddDialog}
                  onClose={handleCloseAddDialog}
                  dialogContent={t('booking.passengerAddConfirmation')}
                  onNo={handleCloseAddDialog}
                  onYes={handleAddConfirmation}
                />
              )} */}
            </>
          ) : (
            <>
              <FilledButton
                variant="contained"
                className="add-passenger-button"
                onClick={handleEditConfirmation}
                disabled={disabled}
                isValid={nameValid && phoneNumberValid}
              >
                &nbsp; {t('booking.editPassenger')}
              </FilledButton>
              {/* {openEditDialog && (
                <ComfirmationDialog
                  open={openEditDialog}
                  onClose={handleCloseEditDialog}
                  dialogContent={t('booking.passengerConfirmation')}
                  onNo={handleCloseEditDialog}
                  onYes={handleEditConfirmation}
                />
              )} */}
            </>
          )}
        </div>

        <TableContainer className="table-cointainer">
          <Table className="passenger-table">
            <TableHead>
              <TableRow className="table-head">
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{ backgroundColor: '#F9FAFB' }}
                  >
                    <strong>{column.label}</strong>
                  </TableCell>
                ))}
                <TableCell sx={{ backgroundColor: '#F9FAFB' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {passengersState ? (
                passengersState.map(
                  (passenger, index) =>
                    (passenger != undefined) && (
                      <TableRow key={index} className="table-body">
                        <TableCell>{passenger.name}</TableCell>
                        <TableCell>{passenger.note}</TableCell>
                        <TableCell>{passenger.phoneNumber}</TableCell>
                        <TableCell>
                          <div className="table-buttons">
                            <Button
                              className="icon"
                              onClick={
                                !disabled
                                  ? () => handleOpenDeleteDialog(index)
                                  : noAction
                              }
                              disabled={disabled}
                            >
                              <TrashIcon />
                            </Button>
                            <Button
                              className="icon"
                              onClick={
                                !disabled
                                  ? () => handleEditPassengerClick(index)
                                  : noAction
                              }
                              disabled={disabled}
                            >
                              <EditIcon />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {openDeleteDialog && (
          <ComfirmationDialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            dialogContent={t('booking.passengerDeleteConfirmation')}
            onNo={handleCloseDeleteDialog}
            onYes={() => handleDeleteConfirmation()}
          />
        )}
      </div>
    </FiltersContainer >
  );
}
