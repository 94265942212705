import { apiSlice } from "../../app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => "User/GetUsers",
    }),
    getUserById: builder.query({
      query: (id) => "User/GetUserById?id=" + id,
    }),
    editUser: builder.mutation({
      query: (body) => ({
        url: "User/EditUser",
        method: "PATCH",
        body,
      }),
    }),
    enableUser: builder.mutation({
      query: (id) => ({
        url: `User/EnableUser?id=${id}`,
        method: "PUT",
      }),
    }),
    disableUser: builder.mutation({
      query: (id) => ({
        url: `User/DisableUser?id=${id}`,
        method: "PUT",
      }),
    }),
    getUsersPerPage: builder.query({
      query: (body) => ({
        url: "User/GetUsersPerPage",
        method: "GET",
        params: { ...body },
      }),
    }),
    addSubagentId: builder.mutation({
      query: (body) => ({
        url: 'User/AddSubagentId',
        method: 'PUT',
        body, 
        
      }),
    }),
    
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useEditUserMutation,
  useGetUsersPerPageQuery,
  useLazyGetUsersPerPageQuery,
  useEnableUserMutation,
  useDisableUserMutation,
  useAddSubagentIdMutation,
} = usersApiSlice;
