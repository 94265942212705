import React, { useState } from 'react';
import './ShowAllUsersComponent.css';
import { useTranslation } from 'react-i18next';
import CustomDropDown from '../../CustomTextFields/CustomDropDown';
import FiltersContainer from '../../Route/FilterRouteComponent/FilterContainer';
import { enableble } from '../../../config';
import { rolesAll } from '../../../config';

export default function UsersFilter(props) {
  const { onFilterClick, onResetClick } = props;
  const { t } = useTranslation();
  const [filterData, setFilterData] = useState({
    isEnabled: enableble[1],
    role: rolesAll[0],
  });

  const handleInputChange = (e) => {
    console.log(e.target.name);
    const { name, value } = e.target;
    setFilterData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleResetFilters = () => {
    setFilterData({
      isEnabled: enableble[1],
      role: rolesAll[0],
    });
    onResetClick();
  };

  return (
    <>
      <FiltersContainer
        onResetClick={handleResetFilters}
        onFilterClcik={() => onFilterClick(filterData)}
        opened={true}
      >
        <div className="route-filters-three-fields-container">
          <CustomDropDown
            name="isEnabled"
            id="isEnabled"
            value={filterData.isEnabled}
            onChange={handleInputChange}
            label={t('route-filters.routeState')}
            options={enableble}
          />
          <CustomDropDown
            name="role"
            id="role"
            value={filterData.role}
            onChange={handleInputChange}
            label={t('users.filterRole')}
            options={rolesAll}
          />
        </div>
      </FiltersContainer>
    </>
  );
}
