// src/contexts/ValidationContext.js

import React, { createContext, useContext } from 'react';
import { validatePassword,validateEmail,validateRouteTitle, validateName, validateUsername, validatePrice,validetateLocation } from '../../utils/validators/validation';

const ValidationContext = createContext();

export const ValidationProvider = ({ children }) => {
  const validatePasswordField = (password,comperePasswords) => {
    return validatePassword(password,comperePasswords);
  };

  const validateEmailField = (email) => {
    return validateEmail(email);
  };

  const validateRouteTitleField = (routeTitle) => {
    return validateRouteTitle(routeTitle);
  };
  const validateNameField = (name) => {
    return validateName(name);
  };
  const validateUserNameField=(userName)=>{
    return validateUsername(userName);
  }
  const validatePriceField=(price)=>{
    return validatePrice(price);
  }
  const validateAutocompleteField=(location)=>{
    return validetateLocation(location)
  }

  return (
    <ValidationContext.Provider value={{ validatePasswordField, validateEmailField,validateRouteTitleField,validateNameField,validateUserNameField,validatePriceField,validateAutocompleteField }}>
      {children}
    </ValidationContext.Provider>
  );
};

export const useValidation = () => useContext(ValidationContext);
