import * as React from "react";
import CustomDialog from "../../Dialog/CustomDialog";
import { DisplayRouteComponent } from "./DisplayRoute";
import { Padding } from "@mui/icons-material";

export default function DisplayRouteDialog(props) {
  //   const fileName = "add-route-auto-complete";
  const { onClose, open, routeStops, origin, destination } = props;
  const [routeLocations, setLocations] = React.useState(null);

  console.log(routeStops)
  console.log(routeStops);

  return (
    <>
      <CustomDialog onOpen={open} onClose={onClose} customMargin="0px">
        {/* <div style={{padding:"10px"}} > */}
            {/* <p>Route: {origin.city}-{destination.city}</p> */}
        <DisplayRouteComponent
          onClose={onClose}
          routeStops={routeStops}
          origin={origin}
          destination={destination}
        />
        {/* </div> */}
      </CustomDialog>
    </>
  );
}
