import { useEffect, useState } from "react";
import { DisplayRouteComponent } from "../../../Route/DisplayRouteComponent/DisplayRoute";
import { geocodeAddress } from "../../../../utils/googleMapsHelper/geocode";
import { useSnackbar } from "../../../SneckBarProvider";
import ComfirmationDialog from "../../../Dialog/ConfirmationDialog";
import { useAddRouteStopMutation } from "../../../../features/routestops/routeStopSlice";
import CustomDialog from "../../../Dialog/CustomDialog";
import "./AddRouteStop.css";
import { NewRouteStops } from "./NewRouteStops";
import { textFieldStyles } from "../../../../customStyles/customStyles";
import { useTranslation } from "react-i18next";
import { useAddRouteStopsMutation } from "../../../../features/routestops/routeStopSlice";
import InputAutoComplete from "../../../GoogleMap/InputAutocomplete";
import { newTextFieldStyle } from "../../../../customStyles/customStyles";
const createNewRouteStop = (data, lat, long) => {
  return {
    location: {
      lat: lat,
      long: long,
      country: data.country,
      city: data.city,
      street: data.street,
      number: data.number,
      isEnabled: false,
    },
  };
};
const addNewRouteStop = (location, routeId) => {
  return {
    lat: location.lat,
    long: location.long,
    country: location.country,
    city: location.city,
    street: location.street,
    number: location.number,
    isEnabled: location.isEnabled,
    routeId: routeId,
  };
};

export const AddRouteStop = (props) => {
  const { t } = useTranslation();

  const {
    open,
    routeStops,
    origin,
    destination,
    onClose,
    routeId,
    onAddRouteStop,
    routeTitle,
  } = props;
  const [addRouteStop] = useAddRouteStopMutation();
  const [addRouteStops] = useAddRouteStopsMutation();

  // console.log("stopoviiiiewiiiiii",routeStops)
  const [displayRouteStops, setDisplayRouteStop] = useState(routeStops);
  const [geocoder, setGeocoder] = useState(null);
  const [mapChecked, setMapLoaded] = useState(null);
  const showSnackbar = useSnackbar();
  const [newRouteStop, setNewRouteStop] = useState(null);
  // console.log(routeStops, origin, destination);
  const [newStops, setNewStops] = useState([]);
  const [openConfirmationDialog, setOpen] = useState(false);
  const [newStopArrId, setNewStopArrId] = useState(null);
  const [resetChange, setResetChange] = useState(false);

  // console.log(routeStops, origin, destination);
  useEffect(() => {
    if (mapChecked) {
      setGeocoder(new window.google.maps.Geocoder());
    }
  }, [mapChecked]);

  useEffect(() => {
    const concatenatedArray = [...routeStops, ...newStops];
    setDisplayRouteStop(concatenatedArray);
  }, [routeStops]);

  const onLeftClick = () => {
    // console.log(map);
  };

  const resetData = () => {
    setDisplayRouteStop(routeStops);
    setNewRouteStop(null);
    setNewStops([]);
  };

  const handleClose = () => {
    resetData();
    onClose();
  };

  const handleRightClick = (event) => {
    // if (displayRouteStops < 23) {
    geocodeAddress(event.latLng, geocoder)
      .then((data) => {
        var routeStop = createNewRouteStop(
          data,
          event.latLng.lat(),
          event.latLng.lng()
        );
        handleSetNewRouteStop(routeStop);
      })
      .catch((error) => {
        console.error(error);
      });
    // } else {
    //   showSnackbar(
    //     "You have reached the maximum number of stops allowed in a single route!",
    //     "error"
    //   );
    // }
  };
  const isMapLoaded = (check) => {
    setMapLoaded(check);
  };
  const handleCloseCofirmationDialog = () => {
    setOpen(false);
  };
  const handleAutoComleteRouteStop = (location) => {
    // if (location != null) {
    //   if (displayRouteStops < 23) {
    // console.log(location);
    // console.log("tretrewtrewtrew")
    console.log(location);

    console.log(location);
    if (location) {
      var rs = {
        location: location,
      };
      console.log(rs);

      handleSetNewRouteStop(rs);
    }
    setResetChange(!resetChange);
    console.log(resetChange);
    //   } else {
    //     showSnackbar(
    //       "You have reached the maximum number of stops allowed in a single route!",
    //       "error"
    //     );
    //   }
    // }
  };

  const handleSetNewRouteStop = (routeStop) => {
    if (routeStop.location.street && routeStop.location.city) {
      // console.log(displayRouteStops);
      setNewRouteStop(routeStop);
      setNewStops((prevStops) => [...prevStops, routeStop]);
      setDisplayRouteStop((prevStops) => [...prevStops, routeStop]);
    } else {
      showSnackbar("Invalid location data!", "error");
    }
  };

  const handleAddRouteStop = async (routeStop) => {
    try {
      const response = await addRouteStop(routeStop).unwrap();
      showSnackbar(response?.message, "success");
      // props.heandleUpdate(selectedRoute);

      setOpen(false);
      setNewRouteStop(null);
      setNewStopArrId(null);
      removeItem(setNewStops, newStopArrId);
      onAddRouteStop(routeId);
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };

  const handleAddRouteStops = async (stops) => {
    try {
      // console.log(newStops)
      const response = await addRouteStops(stops).unwrap();
      showSnackbar(response?.message, "success");
      // props.heandleUpdate(selectedRoute);
      // setOpen(false);
      console.log(routeId);
      setNewRouteStop(null);
      setNewStopArrId(null);
      setNewStops([]);
      onAddRouteStop(routeId);
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };

  const handleRejectRouteStop = (newStopId) => {
    removeItem(setNewStops, newStopId);
    removeItem(setDisplayRouteStop, newStopId + routeStops.length);
  };
  const handleAcceptRouteStop = (stop, stopId) => {
    setNewRouteStop(stop);
    setNewStopArrId(stopId);
    setOpen(true);
  };
  const removeItem = (setFunction, index) => {
    setFunction((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const handleUpdateSeveralStops = () => {
    const updatedLocations = newStops.map((item) => {
      return {
        ...item.location, // Spread existing properties of the location
        routeId: routeId, // Add or override the routeId property
      };
    });
    // console.log(locations);
    handleAddRouteStops(updatedLocations);
  };
  const handleUpdateRoute = () => {
    handleAddRouteStop(addNewRouteStop(newRouteStop.location, routeId));
  };
  const handleRejectAllStops = () => {
    removeItems();
    setNewStops([]);
  };

  const removeItems = () => {
    setDisplayRouteStop((prevItems) => {
      const newItems = [...prevItems];
      newItems.splice(routeStops.length, newStops.length);
      return newItems;
    });
  };

  const handleRemoveLastStop = () => {
    setNewRouteStop(null);

    removeItem(setNewStops, setNewStops.length - 1);
    removeItem(setDisplayRouteStop, setDisplayRouteStop.length - 1);
  };
  return (
    <>
      {displayRouteStops && (
        <CustomDialog onOpen={open} onClose={handleClose} customMargin="0px">
          <div className="add-route-stops-main-container">
            <NewRouteStops
              newStops={newStops}
              routeStops={displayRouteStops}
              handleRejectRouteStop={handleRejectRouteStop}
              handleAcceptRouteStop={handleAcceptRouteStop}
              handleRejectAllStops={handleRejectAllStops}
              handleAddRouteStops={handleUpdateSeveralStops}
            ></NewRouteStops>

            <div style={{maxWidth:"100%"}}>
              <div className="add-route-stop-autocomplete">
                <div>
                  <InputAutoComplete
                    // labelText="New route stop"
                    // customStyle={textFieldStyles}
                    placeholder={t("route-stops.enterNewRouteStop")}
                    onLocationChange={handleAutoComleteRouteStop}
                    reesetChange={resetChange}
                    customStyle={newTextFieldStyle}
                  />
                </div>
              </div>
              <DisplayRouteComponent
                onLeftClick={onLeftClick}
                routeStops={displayRouteStops}
                origin={origin}
                destination={destination}
                onRightClick={handleRightClick}
                checkMap={isMapLoaded}
                newRouteStop={newRouteStop}
                handleRemoveLastStop={handleRemoveLastStop}
              ></DisplayRouteComponent>
              <p
                className="add-route-stop-on-click-info"
                // style={{
                //   padding: "0px",
                //   margin: "0px",
                //   color: "rgb(197, 197, 197)",
                // }}
              >
                {t("route-stops.addRouteStopInfo")}
              </p>
            </div>
          </div>
        </CustomDialog>
      )}

      {newRouteStop && (
        <ComfirmationDialog
          style={{ zIndex: 1500 }}
          open={openConfirmationDialog}
          onClose={handleCloseCofirmationDialog}
          dialogContent={`Do you want to add new stop?`}
          onNo={handleCloseCofirmationDialog}
          onYes={handleUpdateRoute}
        />
      )}
    </>
  );
};
